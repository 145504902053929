import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useCloneOneFilterQuery = (
    isLoggedIn: boolean,
    filterId: number,
    fetchFlag: boolean
) => {
    const [cloneOneProjectRefetchInterval, setCloneOneProjectRefetchInterval] =
        useState(1000);
    return useQuery(
        ["cloneOneFilter", filterId],
        () => API.fetchOneFilterClone(filterId),
        {
            refetchInterval: cloneOneProjectRefetchInterval,
            enabled: isLoggedIn && filterId !== -1 && fetchFlag,
            onSuccess: (data) => {
                if (data) {
                    setCloneOneProjectRefetchInterval(0);
                }
            },
        }
    );
};

export default useCloneOneFilterQuery;
