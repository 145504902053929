import React from "react";
import {Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {styled} from "@mui/system";

const StyledFormLabel = styled(FormControlLabel)(({theme}) => (
    {

        [`&.label`]: {
            color: "black",
        }
    }
))


type ConfigFormControl = {
    error: boolean;
};

type Props = {
    name: string;
    label: string;
    radios: any[];
    legend: string;
};
const RadioWrapper = ({
                          name,
                          label,
                          radios,
                          legend,
                          ...otherProps
                      }: Props) => {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(name, (event.target as HTMLInputElement).value);
    };

    const configRadio = {
        ...field,
        onChange: handleChange,
    };

    const configFormControl: ConfigFormControl = {error: false};
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
    }
    return (
        <FormControl {...configFormControl}>
            <FormLabel component="legend">{legend}</FormLabel>
            <RadioGroup aria-label={label} {...configRadio}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                    }}
                >
                    {radios.map((radio, radioIdx) => (
                        <StyledFormLabel
                            key={radioIdx}
                            value={radio.value}
                            control={<Radio/>}
                            label={radio.label}
                        />
                    ))}
                </Box>
            </RadioGroup>
        </FormControl>
    );
};

export default RadioWrapper;
