import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Grid, Paper} from "@mui/material";
import {useDropzone,} from "react-dropzone";
import Dropper from "@/screens/PlateRecognition/components/Dropper";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import usePlateMutation from "@/hooks/api/PlateRecognition/usePlateMutation";
import usePlateQuery from "@/hooks/api/PlateRecognition/usePlateQuery";
import {MRT_ColumnDef} from "material-react-table";
import TableWrapper from "@/components/TableWrapper";

const classes = {
    boxWrapper: {
        mt: '50px',
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        width: "100%",
        height: "100%",

    },
    pageContent: {
        margin: 2,
        padding: 2,
        display: "flex",
        width: "80%",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",

    },
    uploadArea: {
        display: "flex",
        justifyContent: "right",
        width: "100%"
    },
    imageBox:
        {
            border: "solid 2px",
            width: "100px",
            height: "100px",


        }
}

export type licensePlateDataType = { 'file': File, 'thumbnail': string }

export type columnDataType = {
    file: Blob,
    fileName?: string,
    progress?: number,
    uid: string,
    croppedImage?: string,
    plateNumber?: string
    thumbnail: string,
}
export type ImagesDataType = columnDataType[]
export type uploadProgressType = { [key: string]: number }

//create your forceUpdate hook
function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
}

export default function PlateRecognition() {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const [uploadProgress, setUploadProgress] = useState<uploadProgressType>({} as uploadProgressType);

    const [imagesData, setImagesData] = useState<ImagesDataType>([]);
    const [itemRefetchInterval, setItemRefetchInterval] = useState(1000);
    const {mutateAsync: plateMutateAsync} = usePlateMutation(uploadProgress, setUploadProgress);

    const [resultId, setResultId] = useState<string>("");
    const {data: plateQueryData, status:plateQueryStatus} = usePlateQuery(isLoggedIn, resultId, itemRefetchInterval);
    const {acceptedFiles, isDragActive, getRootProps, getInputProps} = useDropzone({
        multiple: true,
        accept: ["image/*"],
        maxSize: 10000 * 1024, //10MB
        onDropAccepted: (files: File[]) => {

        },
    });

    const [isUploadingBusy, setIsUploadingBusy] = useState(false);

    function filterOutArrayOfDictItem(array: any[], key: any, value: any) {
        // Function used to remove item that match the following criteria
        // array_item[key] === value
        return array.filter((obj) => obj[key] !== value)
    }


    useEffect(() => {
        if (uploadProgress) {
            // alert(JSON.stringify(uploadProgress))
            setImagesData(prevState => prevState.map(row => ({
                ...row,
                progress: uploadProgress[row.fileName as keyof uploadProgressType]
            })))


        }
    }, [uploadProgress]);

    useEffect(() => {
        if (plateQueryData?.plate_numbers.length > 2) {
            // alert(JSON.stringify(plateQueryData?.plate_numbers))
            setImagesData((prev) => prev.map((obj) => obj.uid !== plateQueryData.uid ? obj :
                {
                    ...obj,
                    plateNumber: JSON.parse(plateQueryData.plate_numbers.slice(1, -1).replaceAll("'", '"')),
                    croppedImage: plateQueryData.plate_image
                }))
            setIsUploadingBusy(false)
            setResultId("")
            setItemRefetchInterval(0)

        }
    }, [imagesData, plateQueryData, plateQueryStatus]);

    useEffect(() => {
        if (acceptedFiles) {
            if (!isUploadingBusy) {
                acceptedFiles.forEach((file, index) => {
                    const reader = new FileReader()
                    reader.onabort = () => console.log('file reading was aborted')
                    reader.onerror = () => console.log('file reading has failed')
                    reader.onload = () => {
                        // Do whatever you want with the file contents
                        const binaryStr = reader?.result?.toString()
                        if (binaryStr) {
                            setImagesData((prevDataList) => [...filterOutArrayOfDictItem(prevDataList, 'fileName', file.name),
                                {'file': file, 'fileName': file.name, 'thumbnail': binaryStr}])
                        }

                    }
                    reader.readAsDataURL(file)
                    setIsUploadingBusy(true)
                    uploadFormData(file)
                })
            } else {
                alert("Please wait until the previous upload is completed.")
            }


        }
    }, [acceptedFiles]);

    const uploadFormData = (file: File) => {

        const formData = new FormData()
        formData.append("image", file, file.name)
        formData.append("use_plate_detector", "true")

        plateMutateAsync({fileName: file.name, plateData: formData}, {
            onSuccess: data => {
                if (data) {
                    setImagesData((prev) => prev.map((obj) => obj.fileName !== file.name ? obj : {...obj, uid: data}))
                    setResultId(data)
                    setItemRefetchInterval(1000)

                }

            }
        });

    }

    const columns: MRT_ColumnDef<columnDataType>[] = [
        {
            accessorKey: 'progress',
            header: '',
            muiTableBodyCellProps: {align: 'center'},
            muiTableHeadCellProps: {align: 'left'},
            size: 20,
            Cell: ({cell, row}) => (
                <>
                    <Box>
                        <CircularProgress variant="determinate" value={cell.getValue<number>() ?? 1}/>

                    </Box>
                </>)
        },

        {
            accessorKey: 'fileName',
            header: 'File Name',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
        },
        {

            accessorKey: 'thumbnail',
            header: 'Original Image',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            Cell: ({cell, row}) => (
                <>
                    <Box>
                        <img style={classes.imageBox} src={cell.getValue<string>()}/>
                    </Box>
                </>)

        },
        {

            accessorKey: 'croppedImage', //simple recommended way to define a column
            header: 'Plate Image',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            Cell: ({cell, row}) => (
                <>
                    <Box>
                        <img style={classes.imageBox} src={cell.getValue<string>()}/>
                    </Box>
                </>)
        },
        {

            accessorKey: 'plateNumber', //simple recommended way to define a column
            header: 'Plate Number',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},

        },


    ]
    return (
        <>
            <Box sx={classes.boxWrapper}>
                <Paper sx={classes.pageContent}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Dropper
                                getRootProps={getRootProps}
                                getInputProps={getInputProps}
                                isDragActive={isDragActive}/>
                        </Grid>

                        <Grid item xs={12}>

                            <TableWrapper columns={columns} data={imagesData} enableRowActions={false}
                                          enableTopToolbar={false}/>
                        </Grid>


                    </Grid>

                </Paper>
            </Box>


        </>
    )
        ;
};

