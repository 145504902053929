import {Grid} from "@mui/material";
import useExportExcelSheet from "@/hooks/global/useExportExcelSheet";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import OutputImageCard from "@/screens/Outputs/components/OutputImageCard";
import {OUTPUT_MODAL_NUMBER} from "../Outputs";
import {convertStringToExportTextLink} from "@/utils/convertStringToExportTextLink";

import {getTextFromSelectedDoc} from "@/utils/getTextFromSelectedDoc";


const classes = {
    OutputsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    },
}
type ExtractedInfoType = { [x: string]: string | any };
type Props = {
    matches: boolean;
    setChosedOutputType: (chosedOutputType: number) => void;
    handleOpen: () => void;
};
const ChooseOutput = ({matches, setChosedOutputType, handleOpen}: Props) => {

    const handleExportExcelSheet = useExportExcelSheet();

    const {docsState} = useSelector((state: AppState) => state);

    return (
        <>
            <Grid container spacing={2} sx={classes.OutputsContainer}>
                <Grid item>
                    <OutputImageCard
                        image={"/assests/icons/word.png"}
                        handleDownloadLink={docsState.selectedDoc?.word_file}
                        label={"Word"}
                        handleDisplay={() => {
                            handleOpen();
                            setChosedOutputType(OUTPUT_MODAL_NUMBER.WordOutputModal);
                        }}
                        isMobile={!matches}
                    />
                </Grid>
                <Grid item>
                    <OutputImageCard
                        image={"/assests/icons/excel.png"}
                        label={"Excel"}
                        handleDownloadLink={docsState.selectedDoc?.sheet_file}
                        handleDisplay={() => {
                            handleOpen();
                            setChosedOutputType(OUTPUT_MODAL_NUMBER.excelOutputModal);
                        }}
                        isMobile={!matches}
                    />
                </Grid>

                <Grid item>
                    <OutputImageCard
                        image={"/assests/icons/text2.jpg"}
                        label={"Text"}
                        handleDownloadLink={convertStringToExportTextLink(
                            getTextFromSelectedDoc(docsState.selectedDoc?.raw_json)
                        )}
                        isText={true}
                        handleDisplay={() => {
                            handleOpen();
                            setChosedOutputType(OUTPUT_MODAL_NUMBER.TextOutputModal);
                        }}
                        isMobile={!matches}
                    />
                </Grid>
                <Grid item>
                    <OutputImageCard
                        image={"/assests/icons/table2.png"}
                        label={"Extracted info"}
                        handleDownloadButton={() =>
                            handleExportExcelSheet([
                                (docsState.selectedDoc?.extracted_info as ExtractedInfoType)
                                    .Results ?? {},
                            ])
                        }
                        handleDisplay={() => {
                            handleOpen();
                            setChosedOutputType(OUTPUT_MODAL_NUMBER.TableOutputModal);
                        }}
                        isMobile={!matches}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ChooseOutput;

//result.docx:
// "https://drive.google.com/uc?id=1gzqxHcCxMAi--CqdqnCnOMmVlKwRrXjh"
