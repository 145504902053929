import { Grid, Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import TooltipWrapper from "@/components/TooltipWrapper";
import SnackBarWrapper from "@/components/SnackBarWrapper";
import ModalWrapper from "@/components/ModalWrapper";
import ChipWrapper from "@/components/ChipWrapper";

import CheckboxWrapper from "@/components/formComponents/CheckboxWrapper";
import ButtonWrapper from "@/components/formComponents/ButtonWrapper";
import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";

import useUpdateFilterMutation from "@/hooks/api/Filters/useUpdateFilterMutation";

import { CreateFilterRequest } from "@/srcTypes/apiTypes";

import { useSelector } from "react-redux";
import { AppState } from "@/redux/store";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Required"),
  project: Yup.number(),
  enabled: Yup.boolean().required("Required"),
});

const createFormData = ({
  name,
  project,
  enabled,
  split_join_character,
}: CreateFilterRequest) => {
  let formData = new FormData();
  formData.append("project", project.toString());
  formData.append("name", name);
  formData.append("split_join_character", split_join_character);
  formData.append("enabled", enabled.toString());

  return formData;
};

type Props = {
  openUpdateFilterModal: boolean;
  setOpenUpdateFilterModal: (openUpdateFilterModal: boolean) => void;
};

export default function UpdateFilter({
  openUpdateFilterModal,
  setOpenUpdateFilterModal,
}: Props) {
  const { filtersState } = useSelector((state: AppState) => state);
  const { updatedFilter } = filtersState;

  const handleCloseUpdateFilterModal = () => {
    setOpenUpdateFilterModal(false);
  };

  const {
    status: updateFilterMutationStatus,
    mutateAsync: updateFilterMutateAsync,
  } = useUpdateFilterMutation(updatedFilter?.id ?? 0);

  const handleSubmit = async (formData: FormData) => {
    try {
      await updateFilterMutateAsync(formData);
      handleCloseUpdateFilterModal();
    } catch (error) {
      console.log(error, "Error!");
    }
  };

  const INITIAL_FORM_STATE = updatedFilter
    ? {
        ...updatedFilter,
      }
    : {
        name: "",
        project: -1,
        enabled: false,
        split_join_character: "",
      };

  return (
    <>
      <ModalWrapper
        open={openUpdateFilterModal}
        handleClose={handleCloseUpdateFilterModal}
        disableClose={false}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            handleSubmit(createFormData({ ...values }));
          }}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Box
                  display="flex"
                  justifyContent="center"
                  width="inherit"
                  sx={{ mb: "10px" }}
                >
                  <ChipWrapper label="Update Filter" />
                </Box>
                <Grid item xs={12}>
                  <TextfieldWrapper name="name" label="Filter Name" />
                </Grid>

                <Grid item xs={12}>
                  <TextfieldWrapper
                    name="split_join_character"
                    label="Split Join Character"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TooltipWrapper message="check it if you want to enable this filter">
                    <CheckboxWrapper name="enabled" legend="" label="Enabled" />
                  </TooltipWrapper>
                </Grid>

                <Grid item xs={12}>
                  <ButtonWrapper status={updateFilterMutationStatus}>
                    Update Filter
                  </ButtonWrapper>

                  <SnackBarWrapper
                    status={updateFilterMutationStatus}
                    message={
                      updateFilterMutationStatus === "success"
                        ? "Filter updated successfully"
                        : "Failed to update the filter"
                    }
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ModalWrapper>
    </>
  );
}
