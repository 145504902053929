import {useMutation} from "react-query";
import {API} from "@/utils/api";

const usePdfMutation = (uploadProgress: number,
                        setUploadProgress: (progress: number) => void) => {

    return useMutation((data: FormData) => {
            return API.pdfUploader(data,
                {
                    onUploadProgress: (ev) => {
                        setUploadProgress(Math.ceil((ev.loaded / ev.total) * 100))
                    },
                })
        }
        ,
        {}
    )
        ;
};

export default usePdfMutation;