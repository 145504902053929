import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useLivenessQuery = (isLoggedIn: boolean, recordUID: string, itemRefetchInterval: number) => {

    return useQuery(["Screen", recordUID], () => API.fetchLivenessResult(recordUID), {
        refetchInterval: itemRefetchInterval,
        retry: 1,
        enabled: isLoggedIn && !!recordUID,

        onSuccess: (data) => {
            if (data) {
                // setItemRefetchInterval(0);
            }
        },
        onError: (error: Error) => {

        },
    });
};

export default useLivenessQuery;
