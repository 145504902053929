import React, {ChangeEvent, useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import {Box, Button, CircularProgress, LinearProgress, MenuItem, Paper, TextField, Typography} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import useProjectsQuery from "@/hooks/api/Projects/useProjectsQuery";
import usePdfMutation from "@/hooks/api/PdfUploader/usePdfMutation";
import useMergePdfMutation from "@/hooks/api/PdfUploader/useMergePdfMutation";
import {RestartAlt} from "@mui/icons-material";


const classes = {
    boxWrapper: {
        marginTop: 7,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
    },

    pageContent: {
        margin: 2,
        padding: 3,
        display: "flex",
        width: "100%",
        maxWidth: "500px",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",

    },
    imageCard: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        padding: 1,
        height: "300px",
        width: "100%",
        borderRadius: "15px",
        backgroundColor: "white",

    },
    dropZone: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        border: "2px dashed gray",
        borderRadius: "4px",
        padding: 1,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",

    },

}
type selectedProjectType = { id: number | null, name: string | undefined | null }

function PdfUploader() {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const [pdfFile, setPdfFile] = useState<File | null>();
    const [uidList, setUidList] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedProject, setSelectedProject] = useState<selectedProjectType>({id: null, name: null});
    const [convertingProgress, setConvertingProgress] = useState(0);
    const {mutateAsync: uploadPDFAsync} = usePdfMutation(uploadProgress, setUploadProgress);
    const {mutateAsync: pdfMergeAsync} = useMergePdfMutation();
    const [nCompleted, setNCompleted] = useState(0);
    const {data: projectsData} = useProjectsQuery(isLoggedIn);
    const [excelURL, setExcelURL] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: false,
        accept: ["application/pdf"],
        maxSize: 10000 * 1024, //10MB
        onDropAccepted: (files: File[]) => {

        },
    });
    useEffect(() => {
        if (acceptedFiles.length) {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
            }
            setPdfFile(acceptedFiles[0])
            reader.readAsDataURL(acceptedFiles[0])

        }
    }, [acceptedFiles]);

    useEffect(() => {
        setConvertingProgress(uidList.length ? Math.ceil(100 * nCompleted / uidList.length) : 0)
    }, [uidList.length, nCompleted]);


    function handleSelectProjectChange(e: ChangeEvent<HTMLInputElement>) {
        setSelectedProject({
            id: Number(e.target.value),
            name: projectsData?.find(o => o.id === Number(e.target.value))?.name
        })
    }

    function resetState() {
        setIsFetching(false)
        setPdfFile(null)
        setExcelURL("")
        setNCompleted(0)
        setUidList([])
        setConvertingProgress(0)

    }

    async function sendMergeRequest() {
        const jsonData = {"tasks_uuids": uidList, "partial_merge": true};
        try {
            const data = await pdfMergeAsync(jsonData);
            if (data) {
                let n = Object.values(data['tasks_status']).filter(value => value === 'completed').length;
                if (n > 0 && data['excel_file']) {
                    setExcelURL(data['excel_file']);
                }
                setNCompleted(n);
                if (data['is_all_merged']) {
                    setIsFetching(false)
                }
            }
        } catch (error) {

        }

    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (isFetching && uidList.length > 0) {
                sendMergeRequest()
            } else {
                clearInterval(interval)
            }
        }, 3000)

        return () => clearInterval(interval)
    }, [uidList]);


    function uploadFormData(file: File) {

        const formData = new FormData()
        formData.append("pdf_file", file, file.name)
        formData.append("project", String(selectedProject?.id))
        formData.append("pdf_pages", "[]")
        uploadPDFAsync(formData, {
            onSuccess: data => {
                if (data) {
                    setUidList(data['ocr_task_details_uids'])
                }

            }
        });

    }


    function handleUploadButton() {
        if (!selectedProject?.id) {
            alert("Please select target project.")
        } else if (!pdfFile) {
            alert("Please Select Document Images")
        } else {
            setIsFetching(true)
            uploadFormData(pdfFile)
        }
    }

    return (
        <Box sx={classes.boxWrapper}>
            <Paper sx={classes.pageContent}>
                <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <TextField
                        select={true}
                        label="Select Project"
                        name="select-project"
                        fullWidth={true}
                        variant="outlined"
                        value={selectedProject?.id || ''}
                        onChange={handleSelectProjectChange}
                        sx={{marginBottom: 2}}
                    >
                        {projectsData ?
                            projectsData?.map((project, pIdx) => {
                                return <MenuItem
                                    key={pIdx}
                                    value={project.id}>{project.name}</MenuItem>
                            })
                            :
                            <MenuItem>None</MenuItem>
                        }
                    </TextField>
                    <Paper sx={classes.imageCard}>

                        <Box sx={{...classes.dropZone}}
                             {...getRootProps({className: "dropzone"})}>
                            <input {...getInputProps()} />
                            <Box sx={{
                                display: "flex",
                                alignItems: "center", flexDirection: "column"
                            }}>
                                <UploadIcon fontSize="large"/>
                                <Typography>
                                    {!pdfFile ? "Click or drag PDF to upload" : pdfFile.name}
                                </Typography>
                            </Box>
                        </Box>

                    </Paper>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 2,
                        width: "100%",
                    }}>
                        <LinearProgress style={{width: "100%",}}
                                        variant="determinate"
                                        value={convertingProgress}/>
                        <Typography sx={{ml: 1}}>{convertingProgress}%</Typography>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 2,
                        flexDirection: "row",
                        width: "100%",
                        gap: 2,
                    }}>

                        <Button disabled={isFetching || !selectedProject.id || !pdfFile || convertingProgress > 0}
                                variant="contained" onClick={handleUploadButton}>
                            {convertingProgress > 0 && convertingProgress < 100 ?
                                <CircularProgress color="primary" size={25}/>
                                :
                                "Upload"
                            }
                        </Button>
                        <a href={excelURL} download={pdfFile?.name.replace(".pdf", ".xlsx")} target='_blank'>
                            <Button variant="contained" disabled={!excelURL}>Download Current Excel</Button>
                        </a>
                        <Button onClick={resetState}><RestartAlt/></Button>
                    </Box>

                </Box>
            </Paper>
        </Box>
    );
}

export default PdfUploader;