import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";

import {Box, CircularProgress, Grid, LinearProgress, Typography,} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import ConvertingStage from "./ConvertingStage";

const classes = {
    root: {
        width: "100%",
        height: "100%",
        color: "secondary",
        display: "flex",
        flexDirection: "column",
    },
    uploadingImagesContainer: {
        // backgroundColor: '#a30bef',
        overflowY: "scroll",
        height: "400px",
    },
    itemSecondaryActionFloatingWindow: {
        height: "100%",
        width: "35%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    itemSecondaryActionChild: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    linearLoader: {
        height: "10px",
        width: "70px",
    },
}

export default function UploadingImagesList() {


    const {uploadingImages} = useSelector((state: AppState) => state);

    return (
        <>
            <List sx={classes.root}>
                {/* -------> UploadingItems Header <------- */}
                <ListItem key={100000} role={undefined} dense divider>
                    <ListItemText id={"uploadHeader"} primary="Image Name:"/>
                    <ListItemSecondaryAction
                        sx={classes.itemSecondaryActionFloatingWindow}
                    >
                        <Box sx={classes.itemSecondaryActionChild}>
                            <Typography variant="body2">Uploading</Typography>
                        </Box>
                        <Box sx={classes.itemSecondaryActionChild}>
                            <Typography variant="body2">Converting</Typography>
                        </Box>
                    </ListItemSecondaryAction>
                </ListItem>
                <Box sx={classes.uploadingImagesContainer}>
                    {uploadingImages.map((uploadingImage, uploadingImageIndex) => {
                        const labelId = `checkbox-list-label-${uploadingImage.id}`;
                        return (
                            <ListItem style={{backgroundColor: uploadingImageIndex % 2 == 0 ? '#f6f6f6' : 'white'}}
                                      key={uploadingImage.id} dense divider>
                                <Grid container>
                                    <Grid item xs={8}>

                                        <ListItemText

                                            style={{marginRight: '20px', wordWrap: 'break-word', overflow: 'hidden'}}
                                            id={labelId}
                                            primary={`${uploadingImage.name ?? ""}`}
                                            primaryTypographyProps={{
                                                variant: "h5",
                                                // uploadingImageIndex >= fetchedDataLength ? "h6" : "h5",
                                            }}
                                        />


                                    </Grid>
                                    <Grid item xs={4}>

                                        <ListItemSecondaryAction
                                            sx={classes.itemSecondaryActionFloatingWindow}
                                        >
                                            <Box sx={classes.itemSecondaryActionChild}>
                                                {uploadingImage?.status === "idle" ? (
                                                    <CircularProgress size={25}/>
                                                ) : uploadingImage?.status === "uploading" ? (
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={uploadingImage?.progress}
                                                        sx={classes.linearLoader}
                                                    />
                                                ) : uploadingImage?.status === "success" ? (
                                                    <CheckCircleIcon fontSize="medium"/>
                                                ) : (
                                                    ""
                                                )}
                                            </Box>

                                            <Box sx={classes.itemSecondaryActionChild}>
                                                {/* In order to check the status of an image on the converting stage,
                   a request should be sent with its resultId(the id  we obtained after uploading the image successfully)
                   so we need to render a child component that handles this request for every iamge
                  until it's converted successfully, then we can remove it from the uploadingImagesList */}
                                                <ConvertingStage
                                                    uploadingImageId={uploadingImage.id}
                                                    resultId={uploadingImage?.resultId}
                                                    projectId={uploadingImage?.projectId}
                                                />
                                            </Box>
                                        </ListItemSecondaryAction>
                                    </Grid>
                                </Grid>
                            </ListItem>

                        );
                    })}
                </Box>
            </List>
        </>
    );
}
