import {TextField} from "@mui/material";
import {useField, useFormikContext} from "formik";
import {useEffect} from "react";

type Props = {
    name: string;
    label: string;
    type?: string;
    handleChange?: (
        evt: React.ChangeEvent<{ value: string }>
    ) => void | undefined;
    disabled?: boolean;
    otherProps?: { [x: string]: any };
};
const TextfieldWrapper = ({
                              name,
                              label,
                              type = "",

                              handleChange = undefined,
                              disabled = false,
                              ...otherProps
                          }: Props) => {
    const [field, mata] = useField(name);
    const {setFieldValue} = useFormikContext();

    const formikOnChangeCallback = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue(name, evt.target.value);
    };

    useEffect(() => {
        console.log("f:", field)
    }, []);

    const configTextfield = {

        ...field,
        ...otherProps,
        fullWidth: true,
        error: false,
        helperText: "",
        name: name,
        label: label,
        type: type,


    };

    if (mata && mata.touched && mata.error) {
        configTextfield.error = true;
        configTextfield.helperText = mata.error;
    }

    //fix onChange Bug later but currently render it separately.

    return (
        <TextField
            {...configTextfield}
            disabled={disabled}
            variant="outlined"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                //when overriding the onChange function for custom callback functionality, formik will
                //demand for the developer to explicitly update the field using setFieldValue from formik hooks
                formikOnChangeCallback(evt);
                if (handleChange !== undefined) handleChange(evt);
            }}
        />
    );
};

export default TextfieldWrapper;
