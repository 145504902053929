import {useEffect, useState} from "react";

import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ModalWrapper from "@/components/ModalWrapper";

import {getDocName} from "@/utils/docHelpers";

import {FetchTasksResponse} from "@/srcTypes/apiTypes";

import {useFormikContext} from "formik";
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";


export type ImageDataType = {
    img: string;
    title: string;
    rawJson?: any;
    documentText: string;
    RTL: boolean;
};

type Props = {
    openImageListModal: boolean;
    setOpenImageListModal: (openImageListModal: boolean) => void;
    setSelectedImageData: (selectedImageData: ImageDataType) => void;
    setPreviewOutputText: (previewOutputText: string) => void;
    docsData: FetchTasksResponse | undefined;
    setSubmitFlag: ({
                        submitType,
                        previewType,
                    }: {
        submitType: string;
        previewType: string;
    }) => void;
};

export default function ImageListWrapper({
                                             openImageListModal,
                                             setOpenImageListModal,
                                             setSelectedImageData,
                                             setPreviewOutputText,
                                             docsData,
                                             setSubmitFlag,
                                         }: Props) {
    const theme = useTheme()
    const classes = {
        imageListContainer: {
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-around",
            overflow: "hidden",
            border: "solid 2px black",
            backgroundColor: theme.palette.background.paper,
        },
        imageList: {
            width: "65vw",
            height: "60vh",
        },
        icon: {
            color: "rgba(255, 255, 255, 0.54)",
        },
        imageComponent: {
            cursor: "pointer",
            margin: "5px",
            height: '100%',
        },
        imgStyle: {
            width: "100%",
            height: "100%",
        },
        emptyImageListContainer: {
            margin: "40px 25px",
        },
        searchContainer: {
            margin: "20px 10px",
            width: "45%",
        },
    }

    const {submitForm} = useFormikContext();

    const handleCloseImageListModal = () => {
        setOpenImageListModal(false);
        setFilteredImageListData(getImageListDataFromDocData(docsData));
    };

    const getImageListDataFromDocData = (
        docsData: FetchTasksResponse | undefined
    ) =>
        docsData?.map((doc) => ({
            img: doc.image ?? "",
            title: getDocName(doc.image ?? ""),
            rawJson: doc.raw_json ?? [],
            documentText: doc.document_text,
            RTL: doc.RTL
        })) ?? [];

    const [imageListData, setImageListData] = useState(
        getImageListDataFromDocData(docsData)
    );
    const [filteredImageListData, setFilteredImageListData] = useState(
        getImageListDataFromDocData(docsData)
    );

    useEffect(() => {
        setImageListData(getImageListDataFromDocData(docsData));
        setFilteredImageListData(getImageListDataFromDocData(docsData));
    }, [docsData]);

    return (
        <ModalWrapper
            open={openImageListModal}
            handleClose={handleCloseImageListModal}
            disableClose={false}
        >
            <Box sx={classes.imageListContainer}>
                <Box sx={classes.searchContainer}>
                    <TextField
                        inputProps={{style: {textAlign: "start"}}}
                        placeholder="Search for an image!"
                        fullWidth
                        onChange={(evt: React.ChangeEvent<{ value: string }>) => {
                            setFilteredImageListData(
                                imageListData.filter((imageData) =>
                                    imageData.title
                                        .toLowerCase()
                                        .includes(evt.target.value.toLocaleLowerCase())
                                )
                            );
                        }}
                    />
                </Box>
                <ImageList rowHeight={180} sx={classes.imageList}>
                    {filteredImageListData.map(
                        (imageData) =>
                            imageData.rawJson.length > 0 && (
                                <ImageListItem  key={imageData.img}>
                                    <Link
                                        sx={classes.imageComponent}
                                        onClick={() => {
                                            setSelectedImageData(imageData);
                                            handleCloseImageListModal();

                                            setSubmitFlag({
                                                submitType: "preview",
                                                previewType: "input",
                                            });
                                            submitForm();
                                        }}
                                    >
                                        <img
                                            src={imageData.img}
                                            alt={imageData.title}

                                            style={classes.imgStyle}
                                        />
                                        <ImageListItemBar sx={{margin: "5px 5px 0px 5px"}} title={imageData.title}/>
                                    </Link>
                                </ImageListItem>
                            )
                    )}
                </ImageList>
            </Box>
        </ModalWrapper>
    );
}
