import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useCloneProjectTreeMutation = (projectId: string | undefined) => {
    const queryClient = useQueryClient();
    return useMutation((data: any) => API.createFilterTree(projectId, data, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("Filters");
        },
    });
};

export default useCloneProjectTreeMutation;
