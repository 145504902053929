import {CreateStageResponse} from "@/srcTypes/apiTypes";

//This hook is mainly used when fetching a stage JSON  from the backend and use it for
//populating the form to update the stage data and submit it again
const useStageJsonToStageForm = () => {
    const INITIAL_FORM_STATE = {
        sequence_number: 0,
        prev_valid: "",
        data_source: "raw_data",
        description: "",
        startby_criteria: "stage_input",
        startby_criteria_value: "",
        include_criteria_value: false,
        return_n_matches: 1,
        stage_matching_threshold: 90,
        match_upto_criteria: "current_matching",
        match_upto_value: "",
        include_match_upto_value: false,
        stage_match_upto_threshold: 90,
        apply_function: "",
        func_kwargs: "{}",
        successor_link: "and",
        search_for: "",
        replace_by: "",
        threshold: 0.8,
        n: "",
        str_list: "",
        whitelist: false,
        pattern: "",
        return_type: "",
        n_matches: "1",
        group_no: "0",
        join_chr: "",
        start_idx: "",
        end_idx: "",
        list: "",
        slice_cols: false,
        row_idx: "",
        col_idx: "",
        matching_threshold: 0.9,
        unit: "",
        min_score: "",
        return_value: "",
        slice_rows: false,
        validation_value: "",
        aggressive: false,
        func_list: `{ "func_list": [] }`,
        is_func_list: false,
        apply_function_order: -1,
    };

    const convertStageJsonToStageForm = (
        stageJson: CreateStageResponse | null,
        isDuplicate: boolean,
        newSeqNo: number
    ) => {
        if (stageJson !== null)
            return {
                ...stageJson,
                match_upto_value: stageJson?.match_upto_value?.value,
                startby_criteria_value: stageJson?.startby_criteria_value?.value,
                validation_value: stageJson?.validation_value?.value,
                stage_matching_threshold: (stageJson?.matching_threshold ?? 0.5) * 100,
                stage_match_upto_threshold: (stageJson?.match_upto_threshold ?? 0.5) * 100,
                func_kwargs: stageJson.apply_function === "function_list" ? "{}" : JSON.stringify(stageJson?.func_kwargs ?? {}),
                ...stageJson?.func_kwargs,
                matching_threshold: (stageJson?.func_kwargs?.matching_threshold ?? 0.5) * 100,
                sequence_number: isDuplicate ? newSeqNo : stageJson.sequence_number,
                is_func_list: stageJson.apply_function === "function_list",
                func_list: stageJson.apply_function === "function_list" ? JSON.stringify(stageJson?.func_kwargs ?? {}) : "{}",
                apply_function_order: -1,
            };
        else
            return {
                ...INITIAL_FORM_STATE,
                sequence_number: newSeqNo,
                description: `stage number: ${newSeqNo}`,
            };
    };

    return [convertStageJsonToStageForm];
};

export default useStageJsonToStageForm;
