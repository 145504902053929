import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from '@mui/icons-material/Menu';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import userCredentialsSlice from "@/redux/reducers/userCredentials";
import navBarSlice from "@/redux/reducers/navBarState";
import Login from "@/layouts/Login";

import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {Menu, MenuItem} from "@mui/material";


export default function AppBarWrapper() {
    const {t} = useTranslation();
    const {appBarState} = useSelector((state: AppState) => state)
    const {appBarHeight} = appBarState
    const theme = useTheme();
    const classes = {
        root: {
            flexGrow: 1,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            height: `${appBarHeight}px`,
        },
        menuButton: {
            marginRight: 2,
        },
        Toolbar: {
            root: {
                padding: "0px",
            },
        },
        title: {
            flexGrow: 1,
            color: "secondary",
            [theme.breakpoints.down("sm")]: {
                // fontSize: "15px",
            },
        },
        UserAvatar: {
            color: "white",
        },
        logo: {
            maxWidth: "40px",
            maxHeight: "40px",
            marginRight: "10px",

        },
        Username: {
            marginRight: "10px",
            cursor: "pointer",
            [theme.breakpoints.down("md")]: {
                display: 'none'
            },
        },
        LogoutButton: {
            padding: "3px 7px",
            root: {
                [theme.breakpoints.down("sm")]: {
                    // fontSize: "0.7rem",
                },
            },
        },
        LoginButton: {
            padding: "3px 7px",
            root: {
                [theme.breakpoints.down("sm")]: {
                    // fontSize: "0.7rem",
                },
            },
        },
    }
    const {userCredentials, isMatchingBreakingPoint, navBarState} = useSelector((state: AppState) => state);
    const {isLoggedIn, username} = userCredentials;
    const {drawerWidth} = navBarState

    const dispatch: AppDispatch = useDispatch();
    const {setUserCredentials} = userCredentialsSlice.actions;

    const {setDrawerWidth} = navBarSlice.actions

    const [openLoginModal, setOpenLoginModal] = useState(false);

    function handleDrawerToggle() {
        if (drawerWidth !== "100%") {
            dispatch(setDrawerWidth("100%"))

        } else {
            dispatch(setDrawerWidth("0%"))
        }

    }

    useEffect(() => {
        if (isMatchingBreakingPoint) {
            dispatch(setDrawerWidth("250px"))
        } else {
            dispatch(setDrawerWidth("0px"))

        }
    }, [isMatchingBreakingPoint]);
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsProfileMenuOpen(true)
    };
    return (
        <>
            <AppBar position="fixed" sx={classes.appBar}>
                <Toolbar sx={classes.Toolbar}>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {md: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <img
                        alt="logo"
                        src="/assests/icons/doxter.png"
                        style={classes.logo}
                    />
                    <Typography variant="h6" sx={classes.title}>
                        {t("app_name")}
                    </Typography>

                    <>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            sx={classes.UserAvatar}
                            onClick={handleProfileMenuOpen}
                        >
                            <AccountCircle sx={{transform: 'scale(1.5)'}}/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            keepMounted
                            open={isProfileMenuOpen}
                            onClose={() => setIsProfileMenuOpen(false)}
                        >
                            <MenuItem onClick={() => {
                                localStorage.removeItem("authToken");
                                localStorage.removeItem("username");
                                dispatch(
                                    setUserCredentials({isLoggedIn: false, username: ""})
                                );
                                setOpenLoginModal(true);
                                setIsProfileMenuOpen(false)
                            }}>
                                <Typography>Logout!</Typography>
                            </MenuItem>


                        </Menu>
                        <Typography onClick={handleProfileMenuOpen} sx={classes.Username} variant="body1">
                            {username}
                        </Typography>

                    </>

                </Toolbar>
            </AppBar>

            <Login setOpen={setOpenLoginModal} open={openLoginModal}/>
        </>
    );
}
