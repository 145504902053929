import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useDocClsQuery = (isLoggedIn: boolean, recordUID: string, itemRefetchInterval: number) => {

    return useQuery(["Screen", recordUID], () => API.fetchDocumentClassifierResult(recordUID), {
        refetchInterval: itemRefetchInterval,
        retry: 2,
        enabled: isLoggedIn && !!recordUID,

        onSuccess: (data) => {
            // if (data.plate_numbers.length) {
            //     // setItemRefetchInterval(0);
            // }
        },
        onError: (error: Error) => {

        },
    });
};

export default useDocClsQuery;
