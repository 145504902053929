import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useCloneFilterTreeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((data: any) => API.createProjectTree(data, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("Projects");
        },
    });
};

export default useCloneFilterTreeMutation;
