import {useMutation} from "react-query";
import {API} from "@/utils/api";

const useFaceMutation = (uploadProgress: number,
                         setUploadProgress: (uploadProgress: number) => void) => {

    return useMutation((imageData: FormData) => {
            return API.faceMatching(imageData,
                {
                    onUploadProgress: (ev) => {
                        setUploadProgress(Math.ceil((ev.loaded / ev.total) * 100))
                    },

                })
        }
        ,
        {

        }
    )
        ;
};

export default useFaceMutation;
