import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Paper, Typography} from "@mui/material";
import {useDropzone} from "react-dropzone";
import UploadIcon from "@mui/icons-material/Upload"
import useFaceMutation from "@/hooks/api/FaceMatching/useFaceMutation";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import useFaceQuery from "@/hooks/api/FaceMatching/useFaceQuery";


const classes = {
    boxWrapper: {
        mt: '50px',
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
    },

    pageContent: {
        margin: 2,
        padding: 2,
        display: "flex",
        width: "100%",
        maxWidth: "800px",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",

    },
    imageCard: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        margin: 1,
        padding: 1,
        height: "300px",
        width: "300px",
        borderRadius: "15px",
        backgroundColor: "white",

    },
    dropZone: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        border: "2px dashed gray",
        borderRadius: "4px",
        padding: 1,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",

    },

}
export type imageDataType = { file?: File, thumbnail?: String }
const FaceMatching = () => {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const [faceImage, setFaceImage] = useState<imageDataType>();
    const [idImage, setIdImage] = useState<imageDataType>();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [recordUID, setRecordUID] = useState("");
    const [itemRefetchInterval, setItemRefetchInterval] = useState(1000);
    const [matchingResult, setMatchingResult] = useState<number | null>(null);
    const {acceptedFiles: acceptedFilesFace, getRootProps: propsFace, getInputProps: iPropsFace} = useDropzone({
        multiple: false,
        accept: ["image/*"],
        maxSize: 10000 * 1024, //10MB
        onDropAccepted: (files: File[]) => {

        },
    });
    const {acceptedFiles: acceptedFilesID, getRootProps: propsID, getInputProps: iPropsID} = useDropzone({
        multiple: false,
        accept: ["image/*"],
        maxSize: 10000 * 1024, //10MB
        onDropAccepted: (files: File[]) => {

        },
    });
    const {mutateAsync: uploadImagesAsync} = useFaceMutation(uploadProgress, setUploadProgress);
    const {data: matchingQueryData} = useFaceQuery(isLoggedIn, recordUID, itemRefetchInterval);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (acceptedFilesFace.length) {

            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader?.result?.toString()
                if (binaryStr) {
                    setFaceImage(prevState => ({...prevState, thumbnail: binaryStr}))
                }

            }
            setFaceImage(prevState => ({...prevState, file: acceptedFilesFace[0]}))
            reader.readAsDataURL(acceptedFilesFace[0])

        }
    }, [acceptedFilesFace]);
    useEffect(() => {
        if (acceptedFilesID.length) {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader?.result?.toString()
                if (binaryStr) {
                    setIdImage(prevState => ({...prevState, thumbnail: binaryStr}))
                }

            }
            setIdImage(prevState => ({...prevState, file: acceptedFilesID[0]}))
            reader.readAsDataURL(acceptedFilesID[0])

        }
    }, [acceptedFilesID]);
    useEffect(() => {

        if (matchingQueryData && matchingQueryData?.result !== null) {
            setMatchingResult(matchingQueryData?.result)
            setIsFetching(false)
            setItemRefetchInterval(0)
        }
    }, [matchingQueryData]);


    function uploadFormData(faceFile: File, idFile: File) {

        const formData = new FormData()
        formData.append("id_image", faceFile, faceFile.name)
        formData.append("face_image", idFile, idFile.name)
        setIsFetching(true)
        uploadImagesAsync(formData, {
            onSuccess: data => {
                if (data) {
                    setRecordUID(data['uid'])
                    setItemRefetchInterval(1000)
                }

            }
        });

    }

    function handleUploadButton() {
        if (faceImage?.file && idImage?.file) {
            uploadFormData(faceImage.file, idImage.file)
        } else {
            alert("Please Select Face and ID images")
        }
    }


    return (
        <>
            <Box sx={classes.boxWrapper}>
                <Paper sx={classes.pageContent}>
                    <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "space-around",
                            width: "100%",
                        }}>
                            <Paper sx={classes.imageCard}>
                                <Typography sx={{marginBottom: 1}} variant="h6">
                                    Face Image
                                </Typography>
                                <Box sx={{...classes.dropZone, backgroundImage: `url("${faceImage?.thumbnail}")`}}
                                     {...propsFace({className: "dropzone"})}>
                                    <input {...iPropsFace()} />
                                    <Box sx={{
                                        display: faceImage?.thumbnail ? "none" : "flex",
                                        alignItems: "center", flexDirection: "column"
                                    }}>
                                        <UploadIcon fontSize="large"/>
                                        <Typography>
                                            Click or drag Image to upload
                                        </Typography>
                                    </Box>
                                </Box>

                            </Paper>
                            <Paper sx={classes.imageCard}>
                                <Typography sx={{marginBottom: 1}} variant="h6">
                                    ID Image
                                </Typography>
                                <Box sx={{...classes.dropZone, backgroundImage: `url("${idImage?.thumbnail}")`}}
                                     {...propsID({className: "dropzone"})}>
                                    <input {...iPropsID()} />
                                    <Box sx={{
                                        display: idImage?.thumbnail ? "none" : "flex",
                                        alignItems: "center", flexDirection: "column"
                                    }}>
                                        <UploadIcon fontSize="large"/>
                                        <Typography>
                                            Click or drag Image to upload
                                        </Typography>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: 2,
                            flexDirection: "column",
                            width: "100%",
                        }}>
                            <Button disabled={isFetching} variant="contained" onClick={handleUploadButton}>
                                {isFetching ?
                                    <CircularProgress color="primary" size={25}/>
                                    :
                                    "Verify"
                                }
                            </Button>
                            {matchingResult !== null &&
                                <Box sx={{display: 'flex', mt: 2, justifyContent: "center"}}>
                                    {matchingResult === 1 ?
                                        <Typography variant="h6" sx={{color: 'green'}}>Verified</Typography>
                                        :
                                        <Typography variant="h6" sx={{color: 'red'}}>unverified</Typography>
                                    }

                                </Box>
                            }


                        </Box>

                    </Box>
                </Paper>
            </Box>

        </>
    );
}

export default FaceMatching;