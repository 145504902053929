import {useContext, useState} from "react";

import {Outlet} from "react-router-dom";

import {Box, Button, CircularProgress, Divider, Paper,} from "@mui/material";
import {useTranslation} from "react-i18next";
import MainContext from "@/contexts/MainContext";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import useProjectsQuery from "@/hooks/api/Projects/useProjectsQuery";

import ProjectsList from "./components/ProjectsList";
import CreateProjectModal from "./components/CreateProject/CreateProjectModal";
import ProjectsInfoBar from "./components/ProjectsInfoBar";
import ProjectContextMenu from "./components/ProjectContextMenu";

import useParentRoutingConstructor from "@/hooks/projects/useParentRoutingConstructor";

const classes = {
    projectsContainer: {
        pointeEvents: "none",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 8,
        flexGrow: 1,
    },

    projectsHeader: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%"
    },
    projectListBarContainer: {
        position: "relative",
        width: "100%",
    },
    pageContent: {
        padding: 4,
        width: "50%",
        height: "30vh",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        flexDirection: "column",
    },
    boxWrapper: {
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        height: "80vh",

        width: "100%",
    },
    landingPageButton: {
        height: "50%",
        fontSize: "25px",
    },
}

const RenderLoadingProjects = () => {

    return (
        <Box sx={classes.boxWrapper}>
            <CircularProgress size={100}/>
        </Box>
    );
};
type RenderEmptyProjectListType = {
    setOpenCreateProjectModal: (openCreateProjectModal: boolean) => void;
};
const RenderEmptyProjectList = ({
                                    setOpenCreateProjectModal,
                                }: RenderEmptyProjectListType) => {
    const {t} = useTranslation();

    return (
        <ProjectContextMenu>
            <Box sx={classes.boxWrapper}>
                <Paper sx={classes.pageContent}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        sx={classes.landingPageButton}
                        onClick={() => setOpenCreateProjectModal(true)}
                    >
                        {t("new_project")}
                    </Button>
                </Paper>
            </Box>
        </ProjectContextMenu>
    );
};

const Projects = () => {
    const {t} = useTranslation();


    const {isInfoBarOpen, setIsInfoBarOpen} = useContext(MainContext);

    const {userCredentials, projectsState, navBarState} = useSelector(
        (state: AppState) => state
    );
    const {isLoggedIn} = userCredentials;
    const {selectedProject} = projectsState;
    const {navProjectId} = navBarState;

    const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);

    const {data: projectsData, status: projectsQueryStatus} = useProjectsQuery(isLoggedIn);


    //This custom hook is used only on the Parent Screen of the app
    //for setting the global state: navBarState to the current URL accessed by the user
    //to use this state to conditionally render the current component by using react-router V6 Outlet component
    useParentRoutingConstructor();

    return (
        <>
            {navProjectId ? (
                <>
                    {/*if the condition is true, then user has selected one project:   */}
                    {/*Outlet is react-router component for rendering children (nested routing)*/}
                    <Outlet/>
                </>
            ) : (
                <>
                    <Box sx={classes.projectsContainer}>
                        {projectsQueryStatus === "loading" ? (
                            <RenderLoadingProjects/>
                        ) : projectsData?.length === 0 ? (
                            <RenderEmptyProjectList
                                setOpenCreateProjectModal={setOpenCreateProjectModal}
                            />
                        ) : (
                            <Box>
                                <Box sx={classes.projectsHeader}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setOpenCreateProjectModal(true)}
                                        disabled={false}

                                    >
                                        {t("new_project")}
                                    </Button>
                                </Box>
                                <Box sx={classes.projectListBarContainer}>
                                    <Divider/>
                                    <ProjectsList
                                        setOpenInfoBar={setIsInfoBarOpen}
                                        setOpenCreateProjectModal={setOpenCreateProjectModal}
                                        projectsData={projectsData ?? []}
                                    />
                                </Box>

                                <ProjectsInfoBar
                                    openInfoBar={isInfoBarOpen}
                                    setOpenInfoBar={setIsInfoBarOpen}
                                    selectedProject={
                                        selectedProject as {
                                            [x: string]: string | any;
                                        }
                                    }
                                />
                            </Box>
                        )}
                    </Box>

                    <CreateProjectModal
                        openCreateProjectModal={openCreateProjectModal}
                        setOpenCreateProjectModal={setOpenCreateProjectModal}
                        isLoggedIn={isLoggedIn}
                    />
                </>
            )}
        </>
    );
};

export default Projects;
