import {useNavigate} from "react-router-dom";
import {MRT_ColumnDef, MRT_Row, MRT_TableInstance} from "material-react-table";

import useDeleteProjectMutation from "@/hooks/api/Projects/useDeleteProjectMutation";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import projectsStateSlice from "@/redux/reducers/projectsState";
import navBarStateSlice from "@/redux/reducers/navBarState";

import {CreateProjectResponse} from "@/srcTypes/apiTypes";

import ProjectContextMenu from "./ProjectContextMenu";
import React, {useRef} from "react";
import {Box, IconButton, Typography} from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed"
import DeleteIcon from "@mui/icons-material/Delete"
import TableWrapper from "@/components/TableWrapper";

type Props = {
    projectsData: CreateProjectResponse[];
    setOpenInfoBar: (openInfoBar: boolean) => void;
    setOpenCreateProjectModal: (openCreateProjectModal: boolean) => void;
};


export default function ProjectsList({
                                         projectsData,
                                         setOpenInfoBar,
                                         setOpenCreateProjectModal,
                                     }: Props) {

    const navigate = useNavigate();
    const {projectsState} = useSelector((state: AppState) => state);

    const dispatch: AppDispatch = useDispatch();
    const {setSelectedProject, setUpdatedProject} = projectsStateSlice.actions;
    const {setNavProjectId} = navBarStateSlice.actions;

    const {mutateAsync: deleteProjectMutateAsync} = useDeleteProjectMutation();
    type TableDataType = {
        id: number,
        name: string
    }

    function overlayContextMenu(id: number, cell_value: string) {
        return <div onClick={() => {
            const project = projectsData?.find(
                (project) => project.id === id
            );
            if (project) {
                dispatch(setSelectedProject(project));
                //setNavProjectId: used for nested routing in react-router-dom
                dispatch(setNavProjectId(project?.id ?? undefined));
                setOpenInfoBar(false);

                navigate(`${project.id}/documents`);
            }
        }}>
            <ProjectContextMenu projectId={id}>
                <Typography>{cell_value}</Typography>
            </ProjectContextMenu>
        </div>
    }

    function actionMenu(row: MRT_Row<TableDataType>) {
        return (
            <Box sx={{display: 'flex', justifyContent: "right"}}>
                {
                    // {
                    //   icon: "edit",
                    //   tooltip: "Edit Project",
                    //   onClick: (event: any, rowData: any) => {
                    //     const project = projectsData?.find(
                    //       (project) => project.id === rowData.id
                    //     );
                    //     if (project) {
                    //       dispatch(setUpdatedProject(project));
                    //       setOpenCreateProjectModal(true);
                    //     }
                    //   },
                    // },
                }
                <IconButton onClick={() => {
                    const project = projectsData?.find(
                        (project) => project.id === row.original.id
                    );
                    if (project) {
                        dispatch(setSelectedProject(project));
                        setOpenInfoBar(true);
                    }
                }}><FeedIcon/>
                </IconButton>

                <IconButton onClick={() => {
                    let result = window.confirm(`Are you sure you want to delete "${row.original.name}" project`)
                    if (result) {
                        deleteProjectMutateAsync(row.original.id)
                    }
                }}><DeleteIcon/>
                </IconButton>
            </Box>
        )
    }

    const tableData = projectsData?.map((project) => ({id: project.id, name: project.name}))

    const columns: MRT_ColumnDef<TableDataType>[] = [
        {
            accessorKey: 'id', //simple recommended way to define a column
            header: 'ID',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
            Cell: ({cell, row}) => overlayContextMenu(row.original.id ?? -1, cell.getValue<string>())
        },
        {

            accessorKey: 'name', //simple recommended way to define a column
            header: 'Name',
            muiTableBodyCellProps: {align: 'left', sx: {'&': {width: '100% '}}},
            muiTableHeadCellProps: {align: 'left',sx: {'&': {width: '100% '}}},

            Cell: ({cell, row}) => overlayContextMenu(row.original.id ?? -1, cell.getValue<string>())
        },

    ]
    const tableInstanceRef = useRef<MRT_TableInstance<TableDataType>>(null);

    return (
        <>
            <div style={{maxWidth: "100%"}}>
                <TableWrapper columns={columns}
                              data={tableData}
                              actionMenu={actionMenu}
                              enableRowActions={true}
                              tableInstanceRef={tableInstanceRef}

                />
            </div>
        </>
    )
}
