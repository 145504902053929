type KeyValueStringType = {
    [x: string]: string;
};

const stageFunctionsObj: KeyValueStringType = {
    "": "---------------",
    custom_fns: "*General Functions",
    return_value: "Return [VALUE]",
    remove_spaces: "Remove All Whitespaces",
    strip: "Strip Spaces",
    char_number: "Extract Character by Position",
    word_number: "Extract Word by Position",
    line_number: "Extract Line by Position",
    column_number: "Extract Column by Position",

    words_count: "Count Number of Words",
    char_count: "Count Number of Characters",
    extract_number: "Extract Numeric Values",
    extract_email: "Extract Email Address",
    search_replace: "Search and Replace",
    search_replace_fuzzy: "Fuzzy Search and Replace",
    filter: "White/Black List Specific Characters",
    regex: "Regex Pattern",
    split: "Split and Extract Range",
    nearest_match: "Find Nearest Match",
    table_fns: "*Table Functions",
    table_number: "Select Table",
    table_row: "Select Table's Row",
    table_col: "Select Table's Column",
    table_cell: "Select Table's Cell",
    table_delete_row: "Delete Table's Row",
    table_delete_col: "Delete Table's Column",
    table_rows_count: "Table Rows Count",
    table_cols_count: "Table Columns Count",
    table_sum_rows: "Table Rows Sum",
    table_sum_cols: "Table Columns Sum",
    table_contain_keyword: "Table Contains Keyword",
    table_find_keyword: "Table Find Keyword",
    table_find_regex: "Table Find Regex",

    ai_fns: "*AI Functions",
    receipt_extraction: "Extract Receipt Information",
};

export default stageFunctionsObj;
