import {useEffect, useState} from "react";
import {useMutation} from "react-query";
import {Box} from "@mui/material";
// @ts-ignore
import {v4 as uuidv4} from "uuid";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import uploadingImagesSlice from "@/redux/reducers/uploadingImages";

import AccordionWrapper from "@/components/AccordionWrapper";
import {API} from "@/utils/api";

import {FormType, UploadableFile} from "@/srcTypes/InputFormTypes";
import {UploadingImagesType} from "@/srcTypes/reduxTypes";

import UploadingImagesList from "./UploadingImagesList";

import useOneProjectQuery from "@/hooks/api/Projects/useOneProjectQuery";
import {useTheme} from "@mui/material/styles";

type stylePropsType = {
    uploadingImages: UploadingImagesType[];
    isExpanded: boolean;
};


const createFormData = ({
                            document_layout,
                            docDetector,
                            tableDetector,
                            rotEstimator,
                            scrDetector,
                            docClassifier,
                            files,
                            imageURL,
                            project,
                        }: FormType) => {
    let formData = new FormData();
    formData.append("image", (files as UploadableFile)?.file);
    formData.append("image_url", imageURL);
    formData.append("run_document_detector", docDetector.toString());
    formData.append("run_table_detector", tableDetector.toString());
    formData.append("run_rotation_estimator", rotEstimator.toString());
    formData.append("run_screen_detector", scrDetector.toString());
    formData.append("run_document_classifier", docClassifier.toString());
    formData.append("rtl", document_layout.toLocaleLowerCase() === "rtl" ? "true" : "false");
    formData.append("project", project.toString());
    return formData;
};

const FloatingWindowUploader = ({
                                    uploadedImageFiles,
                                    uploadedImageProjectId,
                                }: {
    uploadedImageFiles: UploadableFile[] | undefined;
    uploadedImageProjectId: number;
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const {userCredentials, uploadingImages} = useSelector(
        (state: AppState) => state
    );
    const {isLoggedIn} = userCredentials;


    const theme = useTheme()
    const classes = {
        boxWrapper: {
            width: '60%',
            maxWidth: '600px',
            height: isExpanded ? '50px' : "500px",
            transition: 'all 400ms ease',
            backgroundColor: theme.palette.background.paper,

            zIndex: (uploadingImages?.length ?? 0) === 0 ? 700 : 2000,
            left: "0",
            bottom: isExpanded ? "10px" : "0%",
            // maxHeight: "35vh",
            display: (uploadingImages?.length ?? 0) === 0 ? "none" : "flex",
            position: "fixed",
            alignContent: "center",
            justifyContent: "space-around",
        },

        CardContainedButton: {
            padding: "9px",
            margin: "0px 2px",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.light,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: "white",
            },
        },
    }
    const dispatch: AppDispatch = useDispatch();
    const {addUploaingImage, updateUploadingImage} =
        uploadingImagesSlice.actions;

    const {data: projectData, status: projectQueryStatus} = useOneProjectQuery(
        isLoggedIn,
        uploadedImageProjectId ?? -1
    );

    //Sending a post request to the server with the image in the payload
    //and other form options
    const uploadImageMutation = useMutation((formData: FormData) => {
        const id = formData.get("id");
        formData.delete("id");
        return API.uploadImage(formData, {
            onUploadProgress: (ev) =>
                dispatch(
                    updateUploadingImage({
                        id: id,
                        status: "uploading",
                        progress: Math.round((ev.loaded / ev.total) * 100),
                        resultId: "NON",
                    })
                ),
        });
    });
    const handleUploadingImage = async (id: string, formData: FormData) => {

        try {
            let res = await uploadImageMutation.mutateAsync(formData);

            dispatch(
                updateUploadingImage({
                    id: id,
                    status: "success",
                    resultId: res.uid.toString(),
                })
            );
        } catch (error) {
            console.log(error, "Error!");
            dispatch(
                updateUploadingImage({id: id, status: "error", resultId: "NON"})
            );
        }
    };

    //For every iamge uploaded, a post request will be sent separately to the server.
    useEffect(() => {
        if (projectQueryStatus === "success")
            uploadedImageFiles?.forEach(
                (imageFile: UploadableFile, index: number) => {
                    const id = uuidv4();
                    handleUploadingImage(
                        id,
                        createFormData({
                            document_layout: projectData?.document_layout ?? "rtl",
                            docDetector: projectData?.use_document_detector ?? true,
                            tableDetector: projectData?.use_table_detector ?? false,
                            rotEstimator: projectData?.use_rotation_estimator ?? false,
                            scrDetector: projectData?.use_screen_detector ?? false,
                            docClassifier: projectData?.use_document_classifier ?? false,
                            files: imageFile,
                            imageURL: "",
                            project: uploadedImageProjectId?.toString() ?? "",
                        })
                    );
                    dispatch(
                        addUploaingImage({
                            id: id,
                            name: imageFile.file.name.split(".")[0],
                            status: "idle",
                            resultId: "NON",
                            projectId: uploadedImageProjectId ?? -1,
                        })
                    );
                }
            );
    }, [uploadedImageFiles, projectQueryStatus]);

    return (
        <Box sx={classes.boxWrapper}>
            {/* {isExpanded ? ( */}
            <AccordionWrapper

                setIsExpanded={setIsExpanded}
                title={`Uploading ${uploadingImages.length} items`}
            >
                <UploadingImagesList/>
            </AccordionWrapper>
            {/* ) : (
        <Tooltip title={`uploading status`}>
          <IconButton
            sx={classes.CardContainedButton}
            onClick={() => setIsExpanded(true)}
          >
            <CloudUploadIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      )} */}
        </Box>
    );
};

export default FloatingWindowUploader;
