import {useContext, useState} from "react";

import {Box, Button, CircularProgress, Divider, Paper,} from "@mui/material";
import {useTranslation} from "react-i18next";
import MainContext from "@/contexts/MainContext";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import {Outlet, useParams} from "react-router-dom";

import useFiltersQuery from "@/hooks/api/Filters/useFiltersQuery";

import FiltersList from "./components/FiltersList";
import CreateFilter from "./components/CreateFilter";
import FiltersInfoBar from "./components/FiltersInfoBar";

const classes = {
    filtersContainer: {
        pointeEvents: "none",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 8,
        flexGrow: 1,
    },

    filtersHeader: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    filterListBarContainer: {
        position: "relative",
        width: "100%",
    },
    pageContent: {
        padding: 8,
        width: "50%",
        height: "30vh",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        flexDirection: "column",
    },
    boxWrapper: {
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        height: "80vh",

        width: "100%",
    },
    landingPageButton: {
        height: "50%",
        width: "100%",
        fontSize: "25px",
    },
}

const Filters = () => {
    const {t} = useTranslation();


    //decoupling children components from the parent
    const {projectId} = useParams();

    const {isInfoBarOpen, setIsInfoBarOpen} = useContext(MainContext);

    const {navBarState, userCredentials, filtersState} = useSelector(
        (state: AppState) => state
    );
    const {navFilterId} = navBarState;
    const {selectedFilter} = filtersState;
    const {isLoggedIn} = userCredentials;

    const [openCreateFilterModal, setOpenCreateFilterModal] = useState(false);

    const {data: filtersData, status: filtersQueryStatus} = useFiltersQuery(
        isLoggedIn,
        Number(projectId) ?? -1
    );

    return (
        <>
            {navFilterId ? (
                <>
                    {/*Outlet is react-router component for rendering children (nested routing)*/}
                    <Outlet/>
                </>
            ) : (
                <>
                    <Box sx={classes.filtersContainer}>
                        {/*Check if there is no Filters to display filter's landing page */}
                        {filtersQueryStatus === "loading" ? (
                            <Box sx={classes.boxWrapper}>
                                <CircularProgress size={100}/>
                            </Box>
                        ) : filtersData?.length === 0 &&
                        filtersQueryStatus === "success" ? (
                            <Box sx={classes.boxWrapper}>
                                <Paper sx={classes.pageContent}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        fullWidth
                                        sx={classes.landingPageButton}
                                        onClick={() => setOpenCreateFilterModal(true)}
                                    >
                                        {t("new_filter")}
                                    </Button>
                                </Paper>
                            </Box>
                        ) : (
                            <Box>
                                <Box sx={classes.filtersHeader}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setOpenCreateFilterModal(true)}
                                        disabled={false}
                                        sx={{width: {xs: "100%", md:"200px"}, minWidth: {"md": "100px"}}}
                                    >
                                        {t("new_filter")}
                                    </Button>
                                </Box>
                                <Box sx={classes.filterListBarContainer}>
                                    <Divider/>
                                    <FiltersList
                                        setOpenInfoBar={setIsInfoBarOpen}
                                        filtersData={filtersData ?? []}
                                    />
                                </Box>

                                <FiltersInfoBar
                                    openInfoBar={isInfoBarOpen}
                                    setOpenInfoBar={setIsInfoBarOpen}
                                    selectedFilter={selectedFilter}
                                />
                            </Box>
                        )}
                    </Box>

                    <CreateFilter
                        openCreateFilterModal={openCreateFilterModal}
                        setOpenCreateFilterModal={setOpenCreateFilterModal}
                    />
                </>
            )}
        </>
    );
};

export default Filters;
