import React, {useCallback} from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import infoBarStateSlice from "@/redux/reducers/infoBarState";
import {Box, useTheme} from "@mui/material";

type styleProps = {
    drawerWidth: number;
};


type Props = {
    openInfoBar: boolean;
    handleInfoBarClose: () => void;
    customDrawerWidth?: number;
    children: React.ReactNode;
};
export default function InfoBar({
                                    openInfoBar,
                                    handleInfoBarClose,
                                    children,
                                }: Props) {

    const dispatch: AppDispatch = useDispatch();
    const {infoBarState} = useSelector((state: AppState) => state);
    const {infoDrawerWidth} = infoBarState

    const {setInfoDrawerWidth} = infoBarStateSlice.actions;

    const theme = useTheme()

    const classes = {
        root: {
            display: "flex",
        },

        drawer: {
            width: infoDrawerWidth,
            flexShrink: 0,
            marginTop: "55px",
        },
        drawerHeader: {
            display: "flex",
            padding: theme.spacing(0, 1),
            justifyContent: "space-between",
        },

        toolbar: theme.mixins.toolbar,
        dragger: {
            width: "5px",
            cursor: "ew-resize",
            padding: "4px 0 0",
            borderTop: "1px solid #ddd",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            zIndex: 100,
            backgroundColor: "#f4f7f9",
        },
    }

    const minDrawerWidth = 200;
    const maxDrawerWidth = 1000;

    const handleMouseDown = (e: any) => {
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback((e: any) => {
        const newWidth = document.body.offsetWidth - e.clientX;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            console.log(newWidth)
            dispatch(setInfoDrawerWidth(`${newWidth}px`));
        }
    }, []);

    return (
        <Box sx={classes.root}>
            <Drawer
                sx={classes.drawer}
                open={openInfoBar}
                anchor="right"
                variant="persistent"
                PaperProps={{style: {width: infoDrawerWidth}}}
            >
                <Box sx={classes.toolbar}/>
                <Box
                    onMouseDown={(e) => handleMouseDown(e)}
                    sx={classes.dragger}
                />

                <Box sx={classes.drawerHeader}>
                    <IconButton onClick={handleInfoBarClose}>
                        <CancelIcon/>
                    </IconButton>
                </Box>

                {children}
            </Drawer>
        </Box>
    );
}
