import React, { useEffect } from "react";
import {
  Box,
  Divider,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "@/redux/store";
import filtersStateSlice from "@/redux/reducers/filtersState";

import { useParams } from "react-router-dom";
import useOneProjectQuery from "@/hooks/api/Projects/useOneProjectQuery";

import InfoBar from "@/components/InfoBar";
import ChipWrapper from "@/components/ChipWrapper";

import { CreateFilterResponse } from "@/srcTypes/apiTypes";

type KeyValueStringType = {
  [x: string]: string | any;
};
type props = {
  openInfoBar: boolean;
  setOpenInfoBar: (openInfoBar: boolean) => void;
  selectedFilter: CreateFilterResponse | null;
};
export default function FiltersInfoBar({
  openInfoBar,
  setOpenInfoBar,
  selectedFilter,
}: props) {
  //decoupling children components from the parent
  const { projectId } = useParams();

  const { userCredentials } = useSelector((state: AppState) => state);
  const { isLoggedIn } = userCredentials;

  const dispatch: AppDispatch = useDispatch();
  const { resetSelectedFilter } = filtersStateSlice.actions;

  const { data: selectedProjectData } = useOneProjectQuery(
    isLoggedIn,
    Number(projectId) ?? -1
  );

  useEffect(() => {
    //close infoBar on no state
    if (selectedFilter === null) setOpenInfoBar(false);
  }, [selectedFilter, setOpenInfoBar]);

  return (
    <InfoBar
      openInfoBar={openInfoBar}
      handleInfoBarClose={() => {
        setOpenInfoBar(false);
        dispatch(resetSelectedFilter());
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        sx={{ width: "100%", mb: "10px" }}
      >
        <ChipWrapper label={selectedFilter?.name ?? ""} />
      </Box>

      <Divider />
      <TableContainer>
        <Table aria-label="Filter's table">
          <TableBody>
            {selectedFilter !== null &&
            (Object.keys(selectedFilter).length ?? 0) > 0 ? (
              Object.keys(selectedFilter).map((key: string) =>
                key === "project" ? (
                  <TableRow>
                    <TableCell>{key.replaceAll("_", " ")}</TableCell>
                    <TableCell>{selectedProjectData?.name ?? ""}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>{key.replaceAll("_", " ")}</TableCell>
                    <TableCell>
                      {(selectedFilter as KeyValueStringType)[key].toString()}
                    </TableCell>
                  </TableRow>
                )
              )
            ) : (
              <TableRow>
                <TableCell>No Details available</TableCell>
                <TableCell>No Details available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </InfoBar>
  );
}
