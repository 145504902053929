import {Container, Draggable} from "react-smooth-dnd";
import List from "@mui/material/List";

import {arrayMoveImmutable} from "array-move";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemIcon from "@mui/material/ListItemIcon";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {Divider} from "@mui/material";

type Props = {
    items: any;
    setItems: (items: any) => void;
};
const SortableList = ({items, setItems}: Props) => {
    const onDrop = ({
                        removedIndex,
                        addedIndex,
                    }: {
        removedIndex: number | null;
        addedIndex: number | null;
    }) => {
        if (removedIndex !== null && addedIndex !== null)
            setItems((items: any) =>
                arrayMoveImmutable(items, removedIndex, addedIndex)
            );
    };

    return (
        <List>
            <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}
                       render={(containerRef) => {
                           return (
                               <div ref={containerRef}>
                                   {
                                       items.map(({id, name}: { id: number; name: string }) => (
                                               <Draggable key={id}
                                                          render={() => {
                                                              return (
                                                                  <>
                                                                      <ListItem>
                                                                          <ListItemText primary={name}/>
                                                                          <ListItemSecondaryAction>
                                                                              <ListItemIcon className="drag-handle">
                                                                                  <DragHandleIcon/>
                                                                              </ListItemIcon>
                                                                          </ListItemSecondaryAction>
                                                                      </ListItem>
                                                                      <Divider/>

                                                                  </>
                                                              )
                                                          }}
                                               />
                                           )
                                       )
                                   }
                               </div>
                           )
                       }}
            />

        </List>
    );
};

export default SortableList;
