import React from "react";
import {Box, Button, IconButton, Typography} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import CropRotateIcon from "@mui/icons-material/CropRotate";


const classes = {
    resetButton: {
        marginRight: "50px",
    },
    controllersContainer: {
        display: "flex",
        justifyContent: "space-around",
    },
}

type Props = {
    scale: number;
    setScale: (scale: number) => void;
    rotate: number;
    setRotate: (rotate: number) => void;
    imgSrc: string;
};
export default function CroppingControllers({
                                                scale,
                                                setScale,
                                                rotate,
                                                setRotate,
                                                imgSrc,
                                            }: Props) {


    return (
        <Box sx={classes.controllersContainer}>
            <div>
                {imgSrc !== "" && (
                    <>
                        <Typography
                            id="discrete-slider-small-steps"
                            gutterBottom
                            display="inline"
                        >
                            Scale:
                        </Typography>
                        <IconButton onClick={() => setScale(scale + 0.3)} color="primary">
                            <ZoomInIcon fontSize="large"/>
                        </IconButton>
                        <IconButton onClick={() => setScale(scale - 0.3)} color="primary">
                            <ZoomOutIcon fontSize="large"/>
                        </IconButton>
                    </>
                )}
            </div>
            <div>
                {imgSrc !== "" && (
                    <>
                        <Typography
                            id="discrete-slider-small-steps"
                            gutterBottom
                            display="inline"
                        >
                            Rotate:
                        </Typography>
                        <IconButton onClick={() => setRotate(rotate - 5)} color="primary">
                            <UndoIcon fontSize="large"/>
                        </IconButton>
                        <IconButton onClick={() => setRotate(rotate + 5)} color="primary">
                            <RedoIcon fontSize="large"/>
                        </IconButton>
                    </>
                )}
            </div>
            <div>
                <Typography
                    id="discrete-slider-small-steps"
                    gutterBottom
                    display="inline"
                >
                    Rotate 90:
                </Typography>
                <IconButton
                    onClick={() => setRotate((rotate + 90) % 360)}
                    color="primary"
                >
                    <CropRotateIcon fontSize="large"/>
                </IconButton>
            </div>
            <Button
                sx={classes.resetButton}
                color="primary"
                variant="contained"
                onClick={() => {
                    setRotate(0);
                    setScale(1);
                }}
            >
                Reset All
            </Button>
        </Box>
    );
}
