import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const usePlateQuery = (isLoggedIn: boolean, plateId: string, itemRefetchInterval:number) => {

    return useQuery(["Plate", plateId], () => API.fetchPlateResult(plateId), {
        refetchInterval: itemRefetchInterval,
        retry: 1,
        enabled: isLoggedIn && !!plateId,

        onSuccess: (data) => {
            if (data.plate_numbers.length) {
                // setItemRefetchInterval(0);
            }
        },
        onError: (error: Error) => {

        },
    });
};

export default usePlateQuery;
