import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {infoBarStateType} from "@/srcTypes/reduxTypes";

const infoBarState = createSlice({
    name: "infoBarState",
    initialState: {
        infoDrawerWidth: "400px",
    } as infoBarStateType,
    reducers: {

        setInfoDrawerWidth: (state, action: PayloadAction<string>) => {
            return {...state, infoDrawerWidth: action.payload};
        },
    },
});

export default infoBarState;
