import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/system";
import {tableCellClasses} from "@mui/material";
import {JSONTree} from 'react-json-tree';

const StyledTableCell = styled(TableCell)(({theme}) => (
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        }
    }
))


const StyledTableRow = styled(TableRow)(({theme}) => (
    {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        }
    }
))


const classes = {
    table: {
        width: "100%",
    },
    container: {
        maxHeight: "100%",
    },
}

function renderJSON(jsonString: string) {
    const theme = {
        scheme: 'monokai',
        author: 'wimer hazenberg (http://www.monokai.nl)',
        base00: '#272822',
        base01: '#383830',
        base02: '#49483e',
        base03: '#75715e',
        base04: '#a59f85',
        base05: '#f8f8f2',
        base06: '#f5f4f1',
        base07: '#f9f8f5',
        base08: '#f92672',
        base09: '#fd971f',
        base0A: '#f4bf75',
        base0B: '#a6e22e',
        base0C: '#a1efe4',
        base0D: '#66d9ef',
        base0E: '#ae81ff',
        base0F: '#cc6633',
    };
    try {
        if (jsonString.includes("{") && jsonString.includes("}")) {
            const parsedJSON = JSON.parse(jsonString.replaceAll("<", "").replaceAll(">", ""));
            return <JSONTree theme={theme} data={parsedJSON}/>;
        } else {
            return jsonString
        }

    } catch (error) {
        return jsonString
    }
}

export default function TableOutput({
                                        extractedInfo,
                                    }: {
    extractedInfo: { [x: string]: string | any };
}) {

    // console.log(extractedInfo?.Results)

    extractedInfo = extractedInfo?.Results;

    return (
        <>
            <TableContainer component={Paper} sx={classes.container}>
                <Table sx={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Key</StyledTableCell>
                            <StyledTableCell>Value</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {extractedInfo !== null ? (
                            Object.keys(extractedInfo).map((key) => (
                                <StyledTableRow>
                                    <StyledTableCell>{key.replaceAll(".", " ")}</StyledTableCell>
                                    <StyledTableCell>
                                        {typeof extractedInfo[key] == 'object' ?
                                            renderJSON(JSON.stringify(extractedInfo[key]))
                                            :
                                            renderJSON(extractedInfo[key])
                                        }
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell>No Filters available</StyledTableCell>
                                <StyledTableCell>No Filters available</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}