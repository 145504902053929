import {Box, Grid} from "@mui/material";

import SelectWrapper from "@/components/formComponents/SelectWrapper";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import {useIsFetching} from "react-query";
import {useFormikContext} from "formik";

import useProjectsQuery from "@/hooks/api/Projects/useProjectsQuery";
import useFiltersQuery from "@/hooks/api/Filters/useFiltersQuery";

import {MergerSelectedDocType} from "@/srcTypes/customTypes";

const classes = {
    filterSelectorContainer: {
        display: "flex",
        flexDirection: 'column'
    },
    formControl: {
        margin: 1,
        minWidth: 120,
        display: "flex",
        justifyContent: "space-between",
    },
}

type Props = {
    mergerChildType: string;
    selectedProjectId: number;
    setSelectedProjectId: (selectedProjectId: number) => void;
    setSelectedList: (selectedList: MergerSelectedDocType[]) => void;
};

const FilterSelector = ({
                            mergerChildType,
                            selectedProjectId,
                            setSelectedProjectId,
                            setSelectedList,
                        }: Props) => {


    const {setFieldValue} = useFormikContext();

    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const {data: projectsData} = useProjectsQuery(isLoggedIn);

    const {data: filtersData} = useFiltersQuery(
        isLoggedIn,
        selectedProjectId ?? -1
    );

    const isFetchingDocs = useIsFetching(["Docs", selectedProjectId]);

    const handleProjectChange = (event: any) => {
        const newSelectedProjectId = Number(event.target.value);
        setSelectedProjectId(newSelectedProjectId);
        setFieldValue(filterName, "");
        setSelectedList([]);
    };

    const projectLabel = `${mergerChildType} Project`;
    const projectName = `${mergerChildType}_project`;
    const filterLabel = `${mergerChildType} Filter`;
    const filterName = `${mergerChildType}_filter`;

    return (
        <>
            <Box sx={classes.filterSelectorContainer}>
                <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                        <SelectWrapper
                            name={projectName}
                            label={projectLabel}
                            handleChange={handleProjectChange}
                            isFetching={isFetchingDocs !== 0 ? true : false}
                            options={
                                projectsData?.reduce(
                                    (accum, project) => ({
                                        [project.id.toString()]: project.name,
                                        ...accum,
                                    }),
                                    {}
                                ) ?? {}
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectWrapper
                            name={filterName}
                            label={filterLabel}
                            options={
                                filtersData?.reduce(
                                    (accum, currentFilter) => ({
                                        [currentFilter.id.toString()]: currentFilter.name,
                                        ...accum,
                                    }),
                                    {}
                                ) ?? {}
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FilterSelector;
