import React from "react";
import Grid from "@mui/material/Grid";

import { useField, useFormikContext } from "formik";

import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";
import SelectWrapper from "@/components/formComponents/SelectWrapper";
import RadioWrapper from "@/components/formComponents/RadioWrapper";

import AccordionWrapper from "./AccordionWrapper";

type Props = {
  expanded: false | string;
  setExpanded: (expanded: false | string) => void;
};

export default function StageValidation({ expanded, setExpanded }: Props) {
  const [validRule] = useField("validation_rule");
  const [validValue] = useField("validation_value");

  const { setFieldValue } = useFormikContext();

  return (
    <React.Fragment>
      <AccordionWrapper
        title={"Stage Validation"}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectWrapper
              name="validation_rule"
              label="Validation Rule"
              options={{
                "": "------------",
                n_chr: "Number of Characters",
                n_wrd: "Number of Words",
                n_col: "Number of Columns",
                n_line: "Number of Lines",
                match_found: "Match Found",
                regex: "Regex Found",
              }}
              handleChange={(event) => {
                //Inital Validation Value
                setFieldValue(validValue.name, "");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextfieldWrapper
              name="validation_value"
              label="Validation Value"
            />
          </Grid>

          <Grid item xs={12}>
            <SelectWrapper
              name="validation_action"
              label="Validation Action"
              options={{
                "": "------------",
                all_true: "Terminate If All True",
                all_false: "Terminate If All False",
                any_true: "Terminate If Any True",
                any_false: "Terminate If Any False",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RadioWrapper
              name="successor_link"
              legend="Successor Link"
              label="Successor Link"
              radios={[
                { value: "and", label: "AND" },
                { value: "or", label: "OR" },
              ]}
            />
          </Grid>
        </Grid>
      </AccordionWrapper>
    </React.Fragment>
  );
}
