import {Breadcrumbs, Typography} from "@mui/material"

import {Link, useLocation} from "react-router-dom";

import {useTranslation} from "react-i18next";

import * as React from "react";
import {useContext} from "react";
import MainContext from "@/contexts/MainContext";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import ModalWrapper from "@/components/ModalWrapper";

import useOneProjectQuery from "@/hooks/api/Projects/useOneProjectQuery";
import useOneFilterQuery from "@/hooks/api/Filters/useOneFilterQuery";


const RenderRedirectingModal = () => {

    const {redirectModalProps, setRedirectModalProps} = useContext(MainContext);

    const handleCloseRedirectingModal = () =>
        setRedirectModalProps({
            ...redirectModalProps,
            openRedirectingModal: false,
        });
    return (
        <ModalWrapper
            open={redirectModalProps.openRedirectingModal}
            handleClose={handleCloseRedirectingModal}
            disableClose={false}
        >
            <div>
                <Typography gutterBottom variant="h5" align="center">
                    {redirectModalProps.redirectMessage}
                    <Link
                        to={redirectModalProps.targetPath}
                        onClick={handleCloseRedirectingModal}
                        style={{color: "black", margin: "5px"}}

                    >
                        {redirectModalProps.targetName}
                    </Link>
                </Typography>
            </div>
        </ModalWrapper>
    );
};

export default function BreadcrumbsWrapper() {
    const {t} = useTranslation();
    const location = useLocation();

    const locationsList = location.pathname.split("/").slice(1);

    const {userCredentials} = useSelector((state: AppState) => state);

    const classes: { [key: string]: React.CSSProperties  } = {
        Link:{
            color: "black",
            margin: "5px",
            textDecoration: "none",

        },
        DisabledLink: {
            textDecoration: "none",
            pointerEvents: "none",
            color: "black",
            margin: "5px",
        },
        CurrentPage: {

            fontSize: "15px",
            fontWeight: "bold",
            color: "black",
            textTransform: "capitalize",
        },
        BreadCrumbsStyles: {
            marginTop: `0px`,
            marginBottom: "0px",
            marginLeft: 10,
            fontSize: "15px",
            textTransform: "capitalize",
        },
    }
    const {isLoggedIn} = userCredentials;

    const getIdFromCurrentLocation = (idType: string) => {
        const value = locationsList[locationsList.findIndex((location) => location === idType) + 1]

        return /^\d+$/.test(value) ? value : "-1"


    };

    const {data: projectData, status: oneProjectQueryStatus} =
        useOneProjectQuery(
            isLoggedIn,
            Number.isNaN(Number(getIdFromCurrentLocation("projects")))
                ? -1
                : Number(getIdFromCurrentLocation("projects"))
        );

    const {data: filterData, status: oneFilterQueryStatus} = useOneFilterQuery(
        isLoggedIn,
        Number.isNaN(Number(getIdFromCurrentLocation("filters")))
            ? -1
            : Number(getIdFromCurrentLocation("filters"))
    );

    const getLinkLocation = (linkLocationsList: string[]) => {
        //the locationList represents
        // the tree of locations and the last location (the leaf) should indicate the link target

        // if the last location in the locationList equals the project name
        // then it should direct us to the documents of this project and this is acheived by the next condition:
        if (
            linkLocationsList[linkLocationsList.length - 1] ===
            projectData?.id.toString()
        )
            linkLocationsList.push("documents");

        return linkLocationsList.reduce(
            (accomLocation, currentLocaiton) => accomLocation + "/" + currentLocaiton,
            ""
        );
    };

    return (
        <>
            {oneProjectQueryStatus === "loading" ||
            oneFilterQueryStatus === "loading" ? (
                ""
            ) : (
                <Breadcrumbs
                    aria-label="breadcrumb"
                    maxItems={8}
                    separator=">"
                    style={classes.BreadCrumbsStyles}
                >
                    {locationsList.map((location, locationIndex) => {
                        return locationIndex === locationsList.length - 1 ? (
                            // If it is the current location:
                            <Typography key={locationIndex} style={classes.CurrentPage}>
                                {t(location.toLowerCase())}
                            </Typography>
                        ) : (
                            // else show a link to this location
                            //the slice function will return a list starts at the first location and ends at the current location
                            <Link
                                key={locationIndex}
                                to={getLinkLocation(locationsList.slice(0, locationIndex + 1))}
                                style={
                                    // displaying links for only projects filters and project name
                                    locationIndex <= 2 ? classes.Link : classes.DisabledLink
                                }
                            >
                                {
                                    /* //Check if the id in the location equals either project id or filter id */
                                    location === projectData?.id.toString()
                                        ? projectData?.name
                                        : location === filterData?.id.toString()
                                            ? filterData?.name
                                            : t(location.toLowerCase())
                                }
                            </Link>
                        );
                    })}
                </Breadcrumbs>
            )}
            <RenderRedirectingModal/>
        </>
    );
}
