import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useTemplatesQuery = (isLoggedIn: boolean) => {
    const [templatesRefetchInterval, setTemplatesRefetchInterval] = useState(100);
    return useQuery("Templates", () => API.fetchTemplates(), {
        refetchInterval: templatesRefetchInterval,
        enabled: isLoggedIn,
        onSuccess: (data) => {
            if (data) {
                setTemplatesRefetchInterval(0);
            }
        },
    });
};

export default useTemplatesQuery;
