import {DropzoneInputProps, DropzoneRootProps} from "react-dropzone";
import {Paper} from "@mui/material";
import {useTheme} from "@mui/material/styles";

type Props = {
    getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
    getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
};


const Dropper = ({getRootProps, getInputProps}: Props) => {

    const theme = useTheme()
    const classes = {
        pageContent: {
            padding: theme.spacing(3),
            width: "100%",
            backgroundColor: "#FFFF",
            mt: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            alignContent: "center",
            flexDirection: "column",
            border: `2px dashed ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
        },
    }
    return (
        <>
            <Paper sx={classes.pageContent}>
                <div {...getRootProps({className: "dropzone"})}>
                    <input {...getInputProps()} />
                    <p>
                        Drag 'n' drop an image here, or click to select one for cropping
                    </p>
                </div>
            </Paper>
        </>
    );
};

export default Dropper;
