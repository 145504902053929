import * as React from "react";
import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";

import theme from "./theme";

export default function withRoot(Component: React.ComponentType<any>) {
    function WithRoot(props: any) {

        return (

            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <Component {...props} />
            </ThemeProvider>
        );
    }

    return WithRoot;
}
