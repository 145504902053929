import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import "./services/i18n";
import store from "@/redux/store";
import Main from "./Main";
import {createRoot} from "react-dom/client";

const queryClient = new QueryClient();
const root = createRoot(document.getElementById('root')!);
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false}/>
            <BrowserRouter>
                <Main/>
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>
);
