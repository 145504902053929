import React from "react";
import Grid from "@mui/material/Grid";

import TooltipWrapper from "@/components/TooltipWrapper";
import CheckboxWrapper from "@/components/formComponents/CheckboxWrapper";
import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";
import SelectWrapper from "@/components/formComponents/SelectWrapper";
import ButtonWrapper from "@/components/formComponents/ButtonWrapper";

type Props = {
    createProjectMutationStatus: "error" | "idle" | "loading" | "success";
};
export default function CreateProjectForm({
                                              createProjectMutationStatus,
                                          }: Props) {
    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={4}>
                <TooltipWrapper
                    message="Document detector is used to focus on your document within the image eliminating any other objects">
                    <CheckboxWrapper
                        name="use_document_detector"
                        legend=""
                        label="Run Document/ID Detector"
                    />
                </TooltipWrapper>
            </Grid>

            <Grid item xs={4}>
                <TooltipWrapper
                    message="Table detector is used to focus on your table within the image eliminating any other objects">
                    <CheckboxWrapper
                        name="use_table_detector"
                        legend=""
                        label="Run Table Detector"
                    />
                </TooltipWrapper>
            </Grid>

            <Grid item xs={4}>
                <TooltipWrapper
                    message="Rotation Estimator is used to auto-adjust the document orientation based on its content">
                    <CheckboxWrapper
                        name="use_rotation_estimator"
                        legend=""
                        label="Run Rotation Estimator"
                    />
                </TooltipWrapper>
            </Grid>

            <Grid item xs={4}>
                <TooltipWrapper
                    message="Screen Detector is used to detect whether the document is captured from a real document or from an electronic screen">
                    <CheckboxWrapper
                        name="use_screen_detector"
                        legend=""
                        label="Run Screen Detector"
                    />
                </TooltipWrapper>
            </Grid>
            <Grid item xs={4}>
                <TooltipWrapper
                    message="The Document Classifier is used to confirm whether the uploaded document matches the project template">
                    <CheckboxWrapper
                        name="use_document_classifier"
                        legend=""
                        label="Run Document Classifier"
                    />
                </TooltipWrapper>
            </Grid>

            <Grid item xs={12}>
                <TextfieldWrapper name="name" label="Project Name"/>
            </Grid>

            <Grid item xs={12}>
                <TextfieldWrapper name="description" label="Project  Description"/>
            </Grid>

            <Grid item xs={12}>
                {/* <TooltipWrapper message="Checking this box will only affect the layout and writing direction of the Word file"> */}
                <SelectWrapper
                    name="document_layout"
                    label="Document Layout"
                    options={{
                        RTL: "Right to Left",
                        LTR: "Left to Right",
                    }}
                />
                {/* </TooltipWrapper> */}
            </Grid>

            <Grid item xs={12}>
                <SelectWrapper
                    name="language"
                    label="Language"
                    options={{
                        ar: "Arabic#true",
                        en: "English#true",
                        nid: "Egyptian National ID#false",
                        cid: "Egyptian Car License#false",
                        mix: "Mixed#fasle",
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <ButtonWrapper status={createProjectMutationStatus}>
                    Create Project
                </ButtonWrapper>
            </Grid>
        </Grid>
    );
}
