import {useMutation} from "react-query";
import {API} from "@/utils/api";

const useMergePdfMutation = () => {

    return useMutation((data: any) => {
            return API.mergePdfUploader(data,
                {
                    onUploadProgress: (ev) => {
                        // setUploadProgress(Math.ceil((ev.loaded / ev.total) * 100))
                    },
                })
        }
        ,
        {}
    )
        ;
};

export default useMergePdfMutation;