import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useProjectsQuery = (isLoggedIn: boolean) => {
    const [projectsRefetchInterval, setProjectsRefetchInterval] = useState(100);
    return useQuery("Projects", () => API.fetchProjects(), {
        refetchInterval: projectsRefetchInterval,
        enabled: isLoggedIn,
        onSuccess: (data) => {
            if (data) {
                setProjectsRefetchInterval(0);
            }
        },
    });
};

export default useProjectsQuery;
