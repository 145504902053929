import React, {useEffect, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import useScreenMutation from "@/hooks/api/ScreenDetector/useScreenMutation";
import {Box, Button, CircularProgress, Paper, Typography} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import useDocClsQuery from "@/hooks/api/DocumentClassifier/useDocClsQuery";
import useDocClsMutation from "@/hooks/api/DocumentClassifier/useDocClsMutation";

export type imageDataType = { file?: File, thumbnail?: String }
const classes = {
    boxWrapper: {
        marginTop: 7,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
    },

    pageContent: {
        margin: 2,
        padding: 3,
        display: "flex",
        width: "100%",
        maxWidth: "500px",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",

    },
    imageCard: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        padding: 1,
        height: "300px",
        width: "100%",
        borderRadius: "15px",
        backgroundColor: "white",

    },
    dropZone: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        border: "2px dashed gray",
        borderRadius: "4px",
        padding: 1,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",

    },

}

function DocumentClassifier() {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const [imageData, setImageData] = useState<imageDataType>();
    const [recordUID, setRecordUID] = useState("");
    const [itemRefetchInterval, setItemRefetchInterval] = useState(1000);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isFetching, setIsFetching] = useState(false);
    const [classificationResult, setClassificationResult] = useState<number | null>(null);
    const {mutateAsync: uploadImagesAsync} = useDocClsMutation(uploadProgress, setUploadProgress);
    const {data: matchingQueryData} = useDocClsQuery(isLoggedIn, recordUID, itemRefetchInterval);

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: false,
        accept: ["image/*"],
        maxSize: 10000 * 1024, //10MB
        onDropAccepted: (files: File[]) => {

        },
    });
    useEffect(() => {
        if (acceptedFiles.length) {

            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader?.result?.toString()
                if (binaryStr) {
                    setImageData(prevState => ({...prevState, thumbnail: binaryStr}))
                }

            }
            setImageData(prevState => ({...prevState, file: acceptedFiles[0]}))
            reader.readAsDataURL(acceptedFiles[0])

        }
    }, [acceptedFiles]);
    useEffect(() => {

        if (matchingQueryData && matchingQueryData?.result !== null) {
            setClassificationResult(matchingQueryData?.result)
            setIsFetching(false)
            setItemRefetchInterval(0)
        }
    }, [matchingQueryData]);

    function uploadFormData(file: File) {

        const formData = new FormData()
        formData.append("document_image", file, file.name)
        setIsFetching(true)
        uploadImagesAsync(formData, {
            onSuccess: data => {
                if (data) {
                    setRecordUID(data['uid'])
                    setItemRefetchInterval(1000)
                }

            }
        });

    }


    function handleUploadButton() {
        if (imageData?.file) {
            uploadFormData(imageData.file)
        } else {
            alert("Please Select Document Images")
        }
    }

    const resultMapping: {[x:number]: string} = {
        0: "Car License Back Side",
        1: "Car License Front Side",
        2: "National ID Back Side",
        3: "National ID Front Side",
        4: "General Document"
    }
    return (
        <Box sx={classes.boxWrapper}>
            <Paper sx={classes.pageContent}>
                <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <Paper sx={classes.imageCard}>
                        <Typography sx={{marginBottom: 1}} variant="h6">
                            Document Image
                        </Typography>
                        <Box sx={{...classes.dropZone, backgroundImage: `url("${imageData?.thumbnail}")`}}
                             {...getRootProps({className: "dropzone"})}>
                            <input {...getInputProps()} />
                            <Box sx={{
                                display: imageData?.thumbnail ? "none" : "flex",
                                alignItems: "center", flexDirection: "column"
                            }}>
                                <UploadIcon fontSize="large"/>
                                <Typography>
                                    Click or drag Image to upload
                                </Typography>
                            </Box>
                        </Box>

                    </Paper>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 2,
                        flexDirection: "column",
                        width: "100%",
                    }}>
                        <Button disabled={isFetching} variant="contained" onClick={handleUploadButton}>
                            {isFetching ?
                                <CircularProgress color="primary" size={25}/>
                                :
                                "Classify"
                            }
                        </Button>
                        {classificationResult !== null &&
                            <Typography variant="h6"
                                        sx={{mt: 2, color: 'green'}}>{resultMapping[classificationResult]}</Typography>
                        }


                    </Box>

                </Box>
            </Paper>
        </Box>
    );
}

export default DocumentClassifier;