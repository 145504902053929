import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FiltersStateType} from "@/srcTypes/reduxTypes";
import {CreateFilterResponse} from "@/srcTypes/apiTypes";

const initialState: FiltersStateType = {
    filtersList: [],
    selectedFilter: null,
    updatedFilter: null,
    deletedFilter: null,
};
const filtersState = createSlice({
    name: "filtersState",
    initialState,
    reducers: {
        setSelectedFilter: (state, action: PayloadAction<CreateFilterResponse>) => {
            return {...state, selectedFilter: action.payload};
        },
        resetSelectedFilter: (state) => {
            return {...state, selectedFilter: null};
        },
        setUpdatedFilter: (state, action: PayloadAction<CreateFilterResponse>) => {
            return {...state, updatedFilter: action.payload};
        },
        resetUpdatedFilter: (state) => {
            return {...state, updatedFilter: null};
        },

        setDeletedFilter: (state, action: PayloadAction<CreateFilterResponse>) => {
            return {...state, deletedFilter: action.payload};
        },
        resetDeletedFilter: (state) => {
            return {...state, deletedFilter: null};
        },
        setFiltersList: (state, action: PayloadAction<CreateFilterResponse[]>) => {
            return {...state, filtersList: action.payload};
        },
        resetFiltersList: (state) => {
            return {...state, filtersList: []};
        },
        addFilter: (state, action: PayloadAction<CreateFilterResponse>) => {
            return {
                ...state,
                filtersList: [...state.filtersList, action.payload],
            };
        },
        updateFilter: (state, action: PayloadAction<CreateFilterResponse>) => {
            return {
                ...state,
                filtersList: state.filtersList.map((filter) =>
                    filter.id === action.payload.id ? action.payload : filter
                ),
            };
        },
        deleteFilter: (state, action: PayloadAction<CreateFilterResponse>) => {
            return {
                ...state,
                filtersList: state.filtersList.filter(
                    (filter) => filter.id !== action.payload.id
                ),
            };
        },
    },
});

export default filtersState;
