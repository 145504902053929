import React from "react";
import {CircularProgress, MenuItem, TextField, Typography,} from "@mui/material";
import {useField, useFormikContext} from "formik";

type Props = {
    name: string;
    label: string;
    handleChange?: (evt: React.ChangeEvent<{ value: string }>) => void;
    options: { [x: string]: any };
    isFetching?: boolean;
    otherProps?: { [x: string]: any };
};
const SelectWrapper = ({
                           name,
                           label,
                           handleChange = (evt: React.ChangeEvent<{ value: string }>) => {
                           },
                           options,
                           isFetching = false,
                           ...otherProps
                       }: Props) => {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const formikOnChangeCallback = (
        evt: React.ChangeEvent<{ value: string }>
    ) => {
        const {value} = evt.target;
        setFieldValue(name, value);
    };

    const configSelect = {
        ...field,
        ...otherProps,
        error: false,
        helperText: "",
        defaultValue: "",
        onChange: (evt: React.ChangeEvent<{ value: string }>) => {
            handleChange(evt);
            formikOnChangeCallback(evt);
        },
        name: name,
        label: label,
        select: true,
        fullWidth: true,
    };

    if (meta && meta?.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        //value={otherProps?.otherProps?.initialValue}
        <TextField {...configSelect}  variant="outlined">
            {Object.keys(options).map((item, pos) => {
                if (options[item].includes("*")) {
                    const value = options[item].split("*")[1];
                    return (
                        <MenuItem
                            key={pos}
                            value={item}
                            divider={true}
                            dense={true}
                            style={{pointerEvents: "none"}}
                        >
                            <Typography variant="h6">{value}</Typography>
                            {isFetching && (
                                <CircularProgress style={{margin: "0px 8px"}} size={20}/>
                            )}
                        </MenuItem>
                    );
                } else {
                    const [value, disabledValue] = options[item].split("#");
                    let disabledBool = disabledValue === "true";
                    return (
                        <MenuItem key={pos} value={item} disabled={disabledBool}>
                            {value}
                            {isFetching && (
                                <CircularProgress style={{margin: "0px 8px"}} size={20}/>
                            )}
                        </MenuItem>
                    );
                }
            })}
        </TextField>
    );
};

export default SelectWrapper;
