import React, {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Box, CircularProgress, Paper} from "@mui/material";

import ImageCropper from "./components/Cropper/ImageCropper";
import Uploader from "./components/Uploader";
import {UploadableFile} from "@/srcTypes/InputFormTypes";
import Dropper from "./components/Dropper";
import StepperWrapper from "./Stepper";
import usePdf2imgs from "@/hooks/components/usePdf2imgs";

import ImageListWrapper from "./components/ImageList";

const classes = {
    boxWrapper: {
        mt: '50px',
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        width: "100%",
        height: "100%",
    },

    pageContent: {
        margin: 2,
        padding: 2,
        display: "flex",
        width: "80%",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",
    },
    loaderContainer: {
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        height: "50vh",

        width: "100%",
    },
}

type Props = {
    setUploadedImageFiles: (
        uploadedImageFiles: UploadableFile[] | undefined
    ) => void;

    setUploadedImageProjectId: (uploadedImageProjectId: number) => void;
};
const GenericUploader = ({
                             setUploadedImageFiles,
                             setUploadedImageProjectId,
                         }: Props) => {


    const [activeStep, setActiveStep] = React.useState(0);

    const [imgListSrc, setImgListSrc] = useState<string[]>([]);
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [loadingImages, setLoadingImages] = useState(true);
    const [uploadableCroppedImages, setUploadableCroppedImages] = useState<any[]>(
        []
    );

    const [selectedProjectId, setSelectedProjectId] = React.useState(-1);

    const {convertPdf2ImagesSrc, loadImagesSrcFromFiles} = usePdf2imgs();

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        multiple: true,
        accept: ["image/*", ".pdf"],
        maxSize: 10000 * 1024, //10MB
        onDropAccepted: (files: File[]) => {
            setActiveStep(activeStep + 1);
        },
    });

    useEffect(() => {
        const getImagesSrc = async (acceptedFiles: File[]) => {
            setLoadingImages(true);
            let imageListSrc: string[] = [];
            await Promise.all(
                acceptedFiles.map(async (acceptedFile) => {
                    let newimageListSrc: string[] = [];
                    if (acceptedFile.name.toLowerCase().includes("pdf"))
                        newimageListSrc = (await convertPdf2ImagesSrc(
                            acceptedFile
                        )) as string[];
                    else
                        newimageListSrc = (await loadImagesSrcFromFiles([
                            acceptedFile,
                        ])) as string[];
                    imageListSrc = [...newimageListSrc, ...imageListSrc];
                })
            );

            setImgListSrc(imageListSrc);
            if (imageListSrc.length > 1) setSelectedImage(imageListSrc[0]);

            if (acceptedFiles.length > 0) setLoadingImages(false);
        };
        getImagesSrc(acceptedFiles);
    }, [acceptedFiles]);

    const handleUploadingCroppedImage = () => {
        setUploadedImageProjectId(selectedProjectId);
        setUploadedImageFiles(uploadableCroppedImages);
    };

    function getSteps() {
        return ["Drop the image", "Crop & Upload images"];
    }

    const getImageSrc = () => {
        if (selectedImage === "" && imgListSrc.length === 0) return "";
        else if (imgListSrc.length === 1) return imgListSrc[0];
        else return selectedImage;
    };

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <Dropper getRootProps={getRootProps} getInputProps={getInputProps}/>
                );

            case 1:
                return (
                    <>
                        {loadingImages ? (
                            <Box sx={classes.loaderContainer}>
                                <CircularProgress size={100}/>
                            </Box>
                        ) : (
                            <>
                                {imgListSrc.length > 1 && (
                                    <ImageListWrapper
                                        imageList={imgListSrc}
                                        selectedImage={selectedImage}
                                        setSelectedImage={setSelectedImage}
                                    />
                                )}

                                <ImageCropper
                                    imgSrc={getImageSrc()}
                                    setUploadableCroppedImages={setUploadableCroppedImages}
                                >
                                    <Uploader
                                        selectedProjectId={selectedProjectId}
                                        setSelectedProjectId={setSelectedProjectId}
                                        handleUploadingCroppedImage={handleUploadingCroppedImage}
                                    />
                                </ImageCropper>
                            </>
                        )}
                    </>
                );

            default:
                return "Unknown step";
        }
    }

    return (
        <Box sx={classes.boxWrapper}>
            <Paper sx={classes.pageContent}>
                <StepperWrapper
                    getSteps={getSteps}
                    getStepContent={getStepContent}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
            </Paper>
        </Box>
    );
};

export default GenericUploader;
