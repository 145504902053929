import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "@/redux/store";
import docsStateSlice from "@/redux/reducers/docsState";

import InfoBar from "@/components/InfoBar";

import { ExtractedInfoType } from "@/srcTypes/hooksTypes";

import MultipleTableOutput from "./MultipleTableOutput";
import SingleDocInfoBar from "./SingleDocInfoBar";

type props = {
  openInfoBar: boolean;
  setOpenInfoBar: (openInfoBar: boolean) => void;
};
export default function ProjectsInfoBar({
  openInfoBar,
  setOpenInfoBar,
}: props) {
  const { docsState } = useSelector((state: AppState) => state);
  const { checkedDocsList } = docsState;

  const dispatch: AppDispatch = useDispatch();
  const { resetSelectedDoc } = docsStateSlice.actions;

  return (
    <InfoBar
      openInfoBar={openInfoBar}
      handleInfoBarClose={() => {
        setOpenInfoBar(false);
        dispatch(resetSelectedDoc());
      }}
    >
      {checkedDocsList.length <= 1 ? (
        <SingleDocInfoBar setOpenInfoBar={setOpenInfoBar} />
      ) : (
        <MultipleTableOutput
          extractedInfoData={checkedDocsList.map(
            (doc) => (doc?.extracted_info as ExtractedInfoType)?.Results ?? {}
          )}
        />
      )}
    </InfoBar>
  );
}
