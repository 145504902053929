import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const usePreviewStageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((formData: FormData) => API.previewStage(formData, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("Stages");
        },
    });
};

export default usePreviewStageMutation;
