import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {Box, CardHeader, IconButton, Tooltip} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import {useTheme} from "@mui/material/styles";


type Props = {
    image: string;
    label?: string;
    isSmallImage?: boolean;
    isMobile?: boolean;
    isText?: boolean;
    handleDisplay: () => void;
    handleDownloadLink?: string | undefined;
    handleDownloadButton?: () => void;
};

export default function OutputImageCard({
                                            image,
                                            label = "",
                                            handleDisplay,
                                            isSmallImage = false,
                                            isMobile = false,
                                            isText = false,
                                            handleDownloadLink = undefined,
                                            handleDownloadButton = () => {
                                            },
                                        }: Props) {
    const theme = useTheme()
    const classes = {
        Card: {
            width: 200,
            height: 289,
            margin: "auto",
        },
        Media: {
            width: 200,
            height: 200,
            padding: "0px 18px",
            objectFit: "contain",
        },
        CardContent: {
            [theme.breakpoints.up("sm")]: {
                padding: "0px 16px 12px 16px",
            },
            [theme.breakpoints.only("xs")]: {
                padding: "0px 4px 12px",
            },
        },
        CardTitle: {
            textAlign: "center",
            padding: "7px 0px 0px",
        },
        MobileCard: {
            width: 120,
            height: 200,
            [theme.breakpoints.up("sm")]: {
                width: 200,
                height: 280,
            },
        },
        MobileMedia: {
            width: 120,
            height: 120,
            padding: "0px 10px",
            objectFit: "contain",
            [theme.breakpoints.up("sm")]: {
                width: 200,
                height: 200,
            },
        },
        SmallCard: {
            width: 50,
        },
        SmallMedia: {
            height: 50,
            width: 50,
            objectFit: "contain",
        },
        Link: {
            textDecorationLine: "none",
        },
        CardContainedButton: {
            padding: "9px",

            margin: "0px 2px",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.light,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: "white",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "6px",
                backgroundColor: theme.palette.primary.light,
                color: "white",
            },
        },
    }
    const {isMatchingBreakingPoint} = useSelector((state: AppState) => state);

    return (
        <Card
            sx={
                isSmallImage
                    ? classes.SmallCard
                    : isMobile
                        ? classes.MobileCard
                        : classes.Card
            }
        >
            <CardHeader
                title={label}
                sx={classes.CardTitle}
                titleTypographyProps={
                    isMatchingBreakingPoint ? {variant: "h5"} : {variant: "body1"}
                }
            />
            <CardMedia
                sx={
                    isSmallImage
                        ? classes.SmallMedia
                        : isMobile
                            ? classes.MobileMedia
                            : classes.Media
                }
                image={image}
                title=""
                component="img"
            />
            <CardContent title={label} sx={classes.CardContent}>
                <Box
                    display="flex"
                    justifyContent={
                        isMatchingBreakingPoint ? "space-around" : "space-between"
                    }
                    alignItems="center"
                >
                    <Tooltip title="Preview">
                        <IconButton
                            onClick={handleDisplay}
                            sx={classes.CardContainedButton}
                        >
                            <SearchIcon/>
                        </IconButton>
                    </Tooltip>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={handleDownloadLink}
                        download={isText ? "data.txt" : undefined}
                        style={classes.Link}
                    >
                        <Tooltip title="Download">
                            <IconButton
                                sx={classes.CardContainedButton}
                                onClick={handleDownloadButton}
                            >
                                <GetAppIcon/>
                            </IconButton>
                        </Tooltip>
                    </a>
                </Box>
            </CardContent>
        </Card>
    );
}
