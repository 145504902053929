import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useCloneOneProjectQuery = (
    isLoggedIn: boolean,
    projectId: number,
    fetchFlag: boolean
) => {
    const [cloneOneProjectRefetchInterval, setCloneOneProjectRefetchInterval] =
        useState(1000);
    return useQuery(
        ["cloneOneProject", projectId],
        () => API.fetchOneProjectClone(projectId),
        {
            refetchInterval: cloneOneProjectRefetchInterval,
            enabled: isLoggedIn && projectId !== -1 && fetchFlag,
            onSuccess: (data) => {
                if (data) {
                    setCloneOneProjectRefetchInterval(0);
                }
            },
        }
    );
};

export default useCloneOneProjectQuery;
