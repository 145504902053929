import {Card, CardMedia, Grid, IconButton, LinearProgress,} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/DeleteForever"
import {imageListType, uploadProgressType} from "@/screens/BatchAutomation/BatchAutomation";

type stylePropsType = { listLength: number }


type propsType = {
    uploadProgress: uploadProgressType
    imageList: imageListType;
    setImageList: (imageList: imageListType) => void
}
export default function ImageListWrapper({uploadProgress, imageList, setImageList}: propsType) {
    const listLength = imageList.length
    const classes = {
        cardListContent: {
            height: listLength ? "140px" : "0px",
            width: "100%",
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            margin: listLength ? "5px" : "0px",
        },
        card: (isUploadCompleted: boolean) => (
            {
                backgroundColor: isUploadCompleted ? "success.light" : "#f5f5f8",
                display: "flex",
                flexDirection: "column",
                height: "120px",
                width: "120px",
                margin: "0px 5px",
                alignItems: "center",
                "&:hover": {
                    boxShadow: "0 10px 20px -12.125px rgba(0,0,0,0.3)",
                },
            }
        ),

        cardMedia: {
            width: "95%",
            height: "95%",
            objectFit: "fill",
            margin: "5px",

        },
        removeButton: {
            padding: "5px",
            margin: "5px",
            width: "15px",
            height: "15px",

        },
        removeIcon: {
            "&:hover": {
                color: "#ff0000",

            }
        },
        progressBar: {
            border: "solid 1px",
            width: "80%",
            height: "10%",
            backgroundColor: "#f5f5f8",
        }

    }

    return (
        <Grid container sx={classes.cardListContent}>
            {
                imageList.map((imageData, imageIndex) => (

                    <Grid item>
                        <Card key={imageIndex} sx={classes.card(uploadProgress[imageData?.file.name] === 100)}>


                            <CardMedia sx={classes.cardMedia} image={imageData?.thumbnail} component="img"/>


                            {uploadProgress[imageData?.file.name] > 0 &&
                                <LinearProgress sx={classes.progressBar} variant="determinate"
                                                value={uploadProgress[imageData?.file.name]}/>
                            }
                            <IconButton sx={classes.removeButton}
                                        onClick={() => {
                                            setImageList(imageList.filter(value => value.file.name !== imageData?.file.name))
                                        }}
                            >
                                <RemoveCircleIcon sx={classes.removeIcon}/>
                            </IconButton>
                        </Card>
                    </Grid>
                ))}
        </Grid>
    );
}
