import {Box, Grid} from "@mui/material";
import DocsList from "./components/DocsList";
import SelectedDocList from "./components/SelectedDocList";

import {MergerSelectedDocType} from "@/srcTypes/customTypes";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


const FilterByDate = ({
                          baseProjectId,
                          targetProjectId,
                          baseSelectedList,
                          setBaseSelectedList,
                          targetSelectedList,
                          setTargetSelectedList,
                      }: {
    baseProjectId: number;
    targetProjectId: number;
    baseSelectedList: MergerSelectedDocType[];
    setBaseSelectedList: (baseSelectedList: MergerSelectedDocType[]) => void;
    targetSelectedList: MergerSelectedDocType[];
    setTargetSelectedList: (targetSelectedList: MergerSelectedDocType[]) => void;
}) => {


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DocsList
                        projectId={baseProjectId}
                        setCheckedDocsList={setBaseSelectedList}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DocsList
                        projectId={targetProjectId}
                        setCheckedDocsList={setTargetSelectedList}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Box sx={{display: "flex", justifyContent: "center",}}>
                        <ArrowDownwardIcon fontSize="large"/>
                        <ArrowDownwardIcon fontSize="large"/>
                        <ArrowDownwardIcon fontSize="large"/>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{display: "flex", justifyContent: "center",}}>
                        <ArrowDownwardIcon fontSize="large"/>
                        <ArrowDownwardIcon fontSize="large"/>
                        <ArrowDownwardIcon fontSize="large"/>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <SelectedDocList
                        tasksList={baseSelectedList}
                        setTasksList={setBaseSelectedList}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectedDocList
                        tasksList={targetSelectedList}
                        setTasksList={setTargetSelectedList}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default FilterByDate;
