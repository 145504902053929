import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const isMatchingBreakingPoint = createSlice({
    name: "isMatchingBreakingPoint",
    initialState: false as boolean,
    reducers: {
        setIsMatchingBreakingPoint: (state, action: PayloadAction<boolean>) => {
            return action.payload;
        },
    },
});

export default isMatchingBreakingPoint;
