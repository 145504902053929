import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import * as apiTypes from "@/srcTypes/apiTypes";

class Api {
    // private readonly baseURL = "https://sarwa.synapse-analytics.io"
    // baseURL = "http://127.0.0.1:8000";
    // baseURL = "https://api.doxter.ai";

    axios_obj: AxiosInstance;

    used = false;
    baseURL: string;

    constructor() {
        const currentDomain = window.location.hostname;
        const apiDomain = currentDomain.replace('app', 'api');
        this.baseURL = `https://${apiDomain}`;
        console.log(this.baseURL)
        this.axios_obj = axios.create({
            responseType: "json",
        });

        // const authToken =
        // if (authToken)
        //   this.axios_obj.defaults.headers.common[
        //     "AUTHORIZATION"
        //   ] = `Token ${authToken}`;

        this.axios_obj.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("authToken");
                if (token && config.headers) {
                    (
                        config.headers! as unknown as Record<string,
                            apiTypes.CommonHeaderProps>
                    ).common["AUTHORIZATION"] = `Token ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
        // Superuser access token
    }

    fetchResource = (endpoint: String, params = {}): Promise<AxiosResponse> => {
        const paramsObj = {params};
        return this.axios_obj.get(this.baseURL + endpoint, paramsObj);
    };

    postData = (
        endpoint: String,
        data = {},
        config: AxiosRequestConfig
    ): Promise<AxiosResponse> => {
        return this.axios_obj.post(this.baseURL + endpoint, data, config);
    };
    updateData = (
        endpoint: String,
        data = {},
        config: AxiosRequestConfig
    ): Promise<AxiosResponse> => {
        return this.axios_obj.put(this.baseURL + endpoint, data, config);
    };

    deleteData = (
        endpoint: String,
        config: AxiosRequestConfig
    ): Promise<AxiosResponse> => {
        return this.axios_obj.delete(this.baseURL + endpoint, config);
    };

    uploadImage = async (
        inputData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.UploadImageResponse> => {
        const {data} = await this.postData(
            "/api/analyzer/run/",
            inputData,
            config
        );
        return data;
    };
    fetchImageResults = async (
        id: string,
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchImageResultsResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/result/${id}/`,
            config
        );
        return data;
    };

    createProject = async (
        projectCreationData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateProjectResponse> => {
        const {data} = await this.postData(
            "/api/analyzer/projects/create/",
            projectCreationData,
            config
        );
        return data;
    };

    createProjectTree = async (
        projectTreeData: any,
        config: AxiosRequestConfig
    ): Promise<any> => {
        const {data} = await this.postData(
            "/api/analyzer/projects/create_tree/",
            projectTreeData,
            config
        );
        return data;
    };
    createFilterTree = async (
        projectId: string | undefined,
        filterTreeData: any,
        config: AxiosRequestConfig
    ): Promise<any> => {
        const {data} = await this.postData(
            `/api/analyzer/filters/create_tree/${projectId}/`,
            filterTreeData,
            config
        );
        return data;
    };
    deleteProject = async (
        project: number,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateProjectResponse> => {
        const {data} = await this.deleteData(
            `/api/analyzer/projects/${project}/`,
            config
        );
        return data;
    };

    updateProject = async (
        project: number,
        projectUpdatingData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateProjectResponse> => {
        const {data} = await this.updateData(
            `/api/analyzer/projects/${project}/`,
            projectUpdatingData,
            config
        );
        return data;
    };

    createFilter = async (
        filterCreationData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateFilterResponse> => {
        const {data} = await this.postData(
            "/api/analyzer/filters/create/",
            filterCreationData,
            config
        );
        return data;
    };

    updateFilter = async (
        filter: number,
        filterUpdatingData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateFilterResponse> => {
        const {data} = await this.updateData(
            `/api/analyzer/filters/${filter}/`,
            filterUpdatingData,
            config
        );
        return data;
    };

    deleteFilter = async (
        filter: number,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateFilterResponse> => {
        const {data} = await this.deleteData(
            `/api/analyzer/filters/${filter}/`,
            config
        );
        return data;
    };

    createStage = async (
        stageCreationData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateStageResponse> => {
        const {data} = await this.postData(
            "/api/analyzer/stages/create/",
            stageCreationData,
            config
        );
        return data;
    };

    updateStage = async (
        stage: number,
        stageUpdatingData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateStageResponse> => {
        const {data} = await this.updateData(
            `/api/analyzer/stages/${stage}/`,
            stageUpdatingData,
            config
        );
        return data;
    };

    deleteStage = async (
        stage: number,
        config: AxiosRequestConfig
    ): Promise<apiTypes.CreateStageResponse> => {
        const {data} = await this.deleteData(
            `/api/analyzer/stages/${stage}/`,
            config
        );
        return data;
    };

    previewStage = async (
        stagePreviewData: FormData,
        config: AxiosRequestConfig
    ): Promise<string> => {
        // let previewData: {} = JSON.parse(
        //   (stagePreviewData.get("stage_data") as string) ?? "{}"
        // );
        const {data} = await this.postData(
            "/api/analyzer/stages/preview/",
            stagePreviewData,
            config
        );
        return data;
    };

    login = async (
        loginData: FormData,
        config: AxiosRequestConfig
    ): Promise<apiTypes.LoginResponse> => {
        const {data} = await this.postData(
            "/api/auth/tokenAuth/",
            loginData,
            config
        );
        return data;
    };

    fetchProjects = async (
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchProjectsResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/projects/list/`,
            config
        );
        return data;
    };

    fetchOneProjectClone = async (
        project: number,
        config?: AxiosRequestConfig
    ): Promise<any> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/projects/clone/${project}/`,
            config
        );
        return data;
    };
    fetchOneFilterClone = async (
        filterId: number,
        config?: AxiosRequestConfig
    ): Promise<any> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/filters/clone/${filterId}/`,
            config
        );
        return data;
    };
    fetchOneProject = async (
        project: number,
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchProjectResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/projects/${project}/`,
            config
        );
        return data;
    };


    fetchFilters = async (
        project: number,
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchFiltersResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/filters/list/${project}/`,
            config
        );
        return data;
    };

    fetchOneFilter = async (
        filter: number,
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchFilterResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/filters/${filter}/`,
            config
        );
        return data;
    };

    fetchTasks = async (
        project: number,
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchTasksResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/list/${project}/`,
            config
        );
        return data;
    };

    deleteTask = async (formData: FormData, config: AxiosRequestConfig) => {
        const {data} = await this.postData(
            `/api/analyzer/delete/`,
            formData,
            config
        );
        return data;
    };

    fetchStages = async (
        project: number,
        filter: number,
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchStagesResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/stages/list/${project}/${filter}/`,
            config
        );
        return data;
    };
    fetchTemplates = async (
        config?: AxiosRequestConfig
    ): Promise<apiTypes.FetchTemplatesResponse> => {
        const {data} = await this.fetchResource(
            `/api/analyzer/templates/list/`,
            config
        );
        return data;
    };

    merge = async (
        mergeData: apiTypes.MergeDataType,
        config: AxiosRequestConfig
    ) => {
        const {data} = await this.postData(
            "/tools/merge/run/",
            mergeData,
            config
        );
        return data;
    };

    automation = async (
        automationData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/analyzer/batch_automation/",
            automationData,
            config
        );
        return data;
    };

    plateRecognition = async (
        plateData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/analyzer/extract_plate_number/",
            plateData,
            config
        );
        return data;
    };
    screenDetector = async (
        plateData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/ekyc/screen_detector/run/",
            plateData,
            config
        );
        return data;
    };
    pdfUploader = async (
        pdfData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/tools/pdf-automation/run/",
            pdfData,
            config
        );
        return data;
    };
    mergePdfUploader = async (
        uidData: any,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/tools/merge-excel-files/run/",
            uidData,
            config
        );
        return data;
    };
    documentClassifier = async (
        docData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/ekyc/document_classifier/run/",
            docData,
            config
        );
        return data;
    };
    userStatistics = async (
        postData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/tools/user-statistics/run/",
            postData,
            config
        );
        return data;
    };

    initializeLiveness = async (
        postData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/ekyc/eye_blink/initiate/",
            postData,
            config
        );
        return data;
    };

    uploadLiveness = async (
        postData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/ekyc/eye_blink/run/",
            postData,
            config
        );
        return data;
    };
    faceMatching = async (
        imageData: FormData,
        config: AxiosRequestConfig
    ) => {

        const {data} = await this.postData(
            "/ekyc/face_matching/run/",
            imageData,
            config
        );
        return data;
    };

    fetchLivenessResult = async (id: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/ekyc/eye_blink/result/${id}/`,
            config
        );
        return data;
    };

    fetchScreenResult = async (id: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/ekyc/screen_detector/result/${id}/`,
            config
        );
        return data;
    };

    fetchDocumentClassifierResult = async (id: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/ekyc/doc_classifier/result/${id}/`,
            config
        );
        return data;
    };
    fetchPlateResult = async (id: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/analyzer/extract_plate_number/result/${id}/`,
            config
        );
        return data;
    };
    fetchFaceResult = async (id: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/ekyc/face_matching/result/${id}/`,
            config
        );
        return data;
    };
    RefreshExtraction = async (uid: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/analyzer/refresh_extraction/${uid}/`,
            config
        );
        return data;
    };
    fetchMergeResults = async (id: string, config?: AxiosRequestConfig) => {
        const {data} = await this.fetchResource(
            `/tools/merge/result/${id}`,
            config
        );
        return data;
    };
}

export const API = new Api();
