import React, {useContext, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";


import ImageListModal, {ImageDataType,} from "@/screens/Stages/components/preview/components/ImageListModal";
import MainContext from "@/contexts/MainContext";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import {useParams} from "react-router-dom";

import {useField} from "formik";

import useDocsQuery from "@/hooks/api/Documents/useDocsQuery";
import {styled} from "@mui/system";

const classes = {
    root: {
        width: "95%",
    },
    ButtonGroup: {
        display: "flex",
        justifyContent: "center",
        margin: "0px 10px",
    },
    previewSectionContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0px",
    },
    previewGridContainer: {
        maxWidth: "630px",
        maxHeight: "450px",
        overflow: "auto",

    },

    previewGrid: {
        backgroundColor: "white",
        border: "2px solid",
    },
    previewGridRow: {
        border: "2px solid",
    },
    previewGridCell: {
        border: "2px solid",
        textAlign: "center",
        minWidth: "50px",
    },
    tableHeadStyles: {
        height: "0px",
    },
    headRow: {
        height: "0px",
    },
    stickyHeadCell: {
        position: "sticky",
        left: 0,
        height: "0px",
        padding: "0px",
    },
    previewGridIndexCell: {
        textAlign: "center",
        width: "10px",
        position: "sticky",
        left: 0,
        background: "#16202C",
        color: "white",
        boxShadow: "5px 2px 5px grey",
    },
}


const StyledTableCell = styled(TableCell)(({theme}) => (
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        }
    }
))


const getMaxCols = (previewText: string) => {
    const previewTextRows = previewText.split("\n");
    return previewTextRows.reduce(
        (maxColsLengt: number, row: string) =>
            row.split("\t").length > maxColsLengt
                ? row.split("\t").length
                : maxColsLengt,
        0
    );
};

const RenderPreviewGrid = ({previewText, RTL}: { previewText: string, RTL: boolean }) => {

    const maxColsLength = getMaxCols(previewText);
    return (
        <TableContainer sx={classes.previewGridContainer}>
            <Table sx={classes.previewGrid}>
                <TableHead sx={classes.tableHeadStyles}>
                    <TableRow sx={classes.headRow}>
                        <TableCell sx={classes.stickyHeadCell}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {previewText.split("\n").map((row: string, rowIndex) => {
                        // if (RTL){
                        //     alert("RTL")
                        // }
                        const rowCells = RTL ? row.split("\t").reverse() : row.split("\t");
                        return (
                            <TableRow key={rowIndex} sx={classes.previewGridRow}>
                                <StyledTableCell sx={classes.previewGridIndexCell}>
                                    {rowIndex}
                                </StyledTableCell>
                                {Array(maxColsLength)
                                    .fill(0)
                                    .map((cell: string, cellIndex: number) => (
                                        <StyledTableCell
                                            key={cellIndex}
                                            align="right"
                                            sx={classes.previewGridCell}
                                        >
                                            {cellIndex < rowCells.length ? rowCells[cellIndex] : ""}
                                        </StyledTableCell>
                                    ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

type Props = {
    previewMutationStatus: string;
    setSubmitFlag: ({
                        submitType,
                        previewType,
                    }: {
        submitType: string;
        previewType: string;
    }) => void;
    previewInputText: string;
    previewOutputText: string;
    setPreviewOutputText: (previewOutputText: string) => void;
    selectedImageData: ImageDataType;
    setSelectedImageData: (selectedImageData: ImageDataType) => void;
};

export default function StagePreview({
                                         previewMutationStatus,
                                         setSubmitFlag,
                                         previewInputText,
                                         previewOutputText,
                                         setPreviewOutputText,
                                         selectedImageData,
                                         setSelectedImageData,
                                     }: Props) {

    const [seqNumber] = useField("sequence_number");

    //Image List: is used to help user preview the stage by viewing the extracted text from an image
    //the images are those which the user previously uploaded and converted via the OCR app
    const [openImageListModal, setOpenImageListModal] = useState(false);

    //decoupling children components from the parent
    const {projectId} = useParams();

    const {setRedirectModalProps} = useContext(MainContext);

    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;

    const {data: docsData} = useDocsQuery(isLoggedIn, Number(projectId) ?? -1);


    const handleChoosePreviewDoc = () => {
        if (docsData && (docsData?.length ?? 0) > 0) {
            setOpenImageListModal(true);
        } else
            setRedirectModalProps({
                openRedirectingModal: true,
                redirectMessage:
                    "Please Upload Documents to use them in the preview, go to:",
                targetName: "Documents",
                targetPath: `projects/${projectId}/documents`,
            });
    };

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleChoosePreviewDoc}
                fullWidth
            >
                Choose Preview document
            </Button>

            <Grid container rowGap={1} sx={classes.previewSectionContainer}>
                <Grid item xs={12} md={5}>
                    <RenderPreviewGrid
                        RTL={selectedImageData.RTL}
                        previewText={
                            previewInputText === "" || seqNumber.value === 0
                                ? selectedImageData.documentText
                                : previewInputText
                        }
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Box sx={classes.ButtonGroup}>
                        <div>
                            <Button
                                disabled={previewMutationStatus === "loading"}
                                type="submit"
                                variant="contained"
                                onClick={() =>
                                    setSubmitFlag({
                                        submitType: "preview",
                                        previewType: "output",
                                    })
                                }
                                color="primary"
                            >
                                {previewMutationStatus === "loading" ? (
                                    <CircularProgress size={24}/>
                                ) : (
                                    "preview Stage"
                                )}
                            </Button>
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={12} md={5}>
                    <RenderPreviewGrid RTL={selectedImageData.RTL} previewText={previewOutputText.toString()}/>

                </Grid>
            </Grid>
            <Box sx={classes.previewSectionContainer}>


            </Box>

            <ImageListModal
                openImageListModal={openImageListModal}
                setOpenImageListModal={setOpenImageListModal}
                setSelectedImageData={setSelectedImageData}
                setPreviewOutputText={setPreviewOutputText}
                docsData={docsData}
                setSubmitFlag={setSubmitFlag}
            />
        </React.Fragment>
    );
}
