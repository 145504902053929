import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useCreateStageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((formData: FormData) => API.createStage(formData, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("Stages");
        },
    });
};

export default useCreateStageMutation;
