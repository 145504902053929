import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useDocsQuery = (isLoggedIn: boolean, projectId: number) => {
    const [docsRefetchInterval, setDocsRefetchInterval] = useState(1000);
    return useQuery(["Docs", projectId], () => API.fetchTasks(projectId ?? 0), {
        refetchInterval: docsRefetchInterval,
        retry: 1,
        enabled: isLoggedIn && projectId !== -1,
        onSuccess: (data) => {
            if (data) {
                setDocsRefetchInterval(0);
            }
        },
        onError: (error: Error) => {
            // If there is no Docs, backend will return error 404, (Maybe it would be better to return an empty list!)
            if (error.message.includes("404")) setDocsRefetchInterval(0);
        },
    });
};

export default useDocsQuery;
