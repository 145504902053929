import React from "react";
import {Box, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Grid, Typography,} from "@mui/material";

import useTemplatesQuery from "@/hooks/api/Projects/useTemplatesQuery";
import {getTemplateIcon} from "@/utils/getTemplateIcon";
import {useTheme} from "@mui/material/styles";


type Props = {
    isLoggedIn: boolean;
    selectedTemplateId: number;
    setSelectedTemplateId: (selectedTemplateId: number) => void;
};
export default function TemplatesCardList({
                                              isLoggedIn,
                                              selectedTemplateId,
                                              setSelectedTemplateId,
                                          }: Props) {
    const theme = useTheme()
    const classes = {
        boxWrapper: {
            width: "100%",
            height: "70vh",
            overflowY: "auto",
        },

        stepTitleContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        stepTitleContent: {
            paddingRight: "5px",
        },

        cardListContent: {
            height: "30vh",
            maxWidth: "90%",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            overflowX: "auto",
            margin: theme.spacing(2, 0, 4, 0),
        },
        card: {
            backgroundColor: "white",
            width: "13vw",
            height: "24vh",
            display: "flex",
            margin: theme.spacing(1),

            "&:hover": {
                boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
            },
        },

        selectedCard: {
            backgroundColor: "white",
            width: "14vw",
            height: "25vh",
            display: "flex",
            margin: theme.spacing(1),

            transition: "0.2s",
            border: "0.3vw solid  rgba(0,0,0,0.8)",
        },
        cardActionArea: {
            height: "100%",
        },

        cardTitle: {
            fontSize: "1vw",
            fontWeight: "bold",
        },

        cardMedia: {
            marginTop: "20px",
            width: "13vw",
            height: "8vh",
            objectFit: "contain",
        },
    }


    const {status: templatesQueryStatus, data: templatesData} =
        useTemplatesQuery(isLoggedIn);

    return (
        <Box sx={classes.cardListContent}>
            {templatesQueryStatus === "loading" ? (
                <Box sx={classes.boxWrapper}>
                    <CircularProgress size={40}/>
                </Box>
            ) : (
                templatesData?.map((template) => {
                    return (
                        <Grid item>
                            <Card
                                key={template.id}
                                sx={
                                    selectedTemplateId === template.id
                                        ? classes.selectedCard
                                        : classes.card
                                }
                            >
                                <CardActionArea
                                    sx={classes.cardActionArea}
                                    onClick={() => {
                                        setSelectedTemplateId(template.id);
                                    }}
                                >
                                    <CardMedia
                                        sx={classes.cardMedia}
                                        image={`/assests/icons/${getTemplateIcon(template.id)}`}
                                        title=""
                                        component="img"
                                    />
                                    <CardContent>
                                        <Typography
                                            align="center"
                                            sx={classes.cardTitle}
                                            gutterBottom
                                        >
                                            {template.name.replaceAll("_", " ")}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    );
                })
            )}
        </Box>
    );
}
