import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useMergeResultsQuery = (resultId: string | undefined) => {
    const [mergeResultsRefetchInterval, setmergeResultsRefetchInterval] =
        useState(2000);
    return useQuery(
        ["MergeResult", resultId],
        () => API.fetchMergeResults(resultId ?? ""),
        {
            enabled: resultId !== undefined,
            refetchInterval: mergeResultsRefetchInterval,
            onSuccess: (mergeResults) => {
                //Stop refetching
                setmergeResultsRefetchInterval(0);
            },
        }
    );
};

export default useMergeResultsQuery;
