import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import ChipWrapper from "@/components/ChipWrapper";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import useProjectsQuery from "@/hooks/api/Projects/useProjectsQuery";

const classes = {
    projectListContainer: {
        display: "flex",
        objecFit: "contain",
        justifyContent: "center",
        alignItems: "center",
    },
    projectList: {
        width: "200px",
    },
    projectListLabelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}

const Uploader = ({
                      setSelectedProjectId,
                      selectedProjectId,
                      handleUploadingCroppedImage,
                  }: {
    setSelectedProjectId: (selectedProjectId: number) => void;
    selectedProjectId: number;
    handleUploadingCroppedImage: () => void;
}) => {


    return (
        <>
            <Grid item xs={6} sx={classes.projectListLabelContainer}>
                <ChipWrapper label="Choose Project: "/>
            </Grid>
            <Grid item xs={6} sx={classes.projectListContainer}>
                <ProjectList
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{marginTop: "30px"}}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handleUploadingCroppedImage}
                    >
                        Upload Cropped Image
                    </Button>
                </Box>
            </Grid>
        </>
    );
};

export default Uploader;

function ProjectList({
                         setSelectedProjectId,
                         selectedProjectId,
                     }: {
    setSelectedProjectId: (selectedProjectId: number) => void;
    selectedProjectId: number;
}) {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;

    const {data: projectsData, status: projectsQueryStatus} =
        useProjectsQuery(isLoggedIn);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e: any) => {
        setSelectedProjectId(e.target.value);
        setAnchorEl(null);
    };


    return (
        <Box sx={classes.projectList}>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="secondary"
                variant="contained"
                onClick={handleClick}
                fullWidth
            >
                {projectsData?.find((project) => project.id === selectedProjectId)
                    ?.name ?? "Choose Project"}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {projectsData?.map((project) => (
                    <MenuItem onClick={handleClose} value={project.id}>
                        {project.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
