import React, { useEffect } from "react";
import {
  Box,
  Divider,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "@/redux/store";
import stagesStateSlice from "@/redux/reducers/stagesState";

import { useParams } from "react-router-dom";
import useOneFilterQuery from "@/hooks/api/Filters/useOneFilterQuery";

import InfoBar from "@/components/InfoBar";
import ChipWrapper from "@/components/ChipWrapper";

import { CreateStageResponse } from "@/srcTypes/apiTypes";

type KeyValueStringType = {
  [x: string]: string | any;
};

type props = {
  openInfoBar: boolean;
  setOpenInfoBar: (openInfoBar: boolean) => void;
  selectedStage: CreateStageResponse | null;
};
export default function StagesInfoBar({
  openInfoBar,
  setOpenInfoBar,
  selectedStage,
}: props) {
  const { filterId } = useParams();

  const { userCredentials } = useSelector((state: AppState) => state);
  const { isLoggedIn } = userCredentials;

  const { data: selectedFilterData } = useOneFilterQuery(
    isLoggedIn,
    Number(filterId) ?? -1
  );

  const dispatch: AppDispatch = useDispatch();
  const { resetSelectedStage } = stagesStateSlice.actions;

  useEffect(() => {
    //close infoBar on no state
    if (selectedStage === null) setOpenInfoBar(false);
  }, [selectedStage, setOpenInfoBar]);

  return (
    <InfoBar
      openInfoBar={openInfoBar}
      handleInfoBarClose={() => {
        setOpenInfoBar(false);
        dispatch(resetSelectedStage());
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        sx={{ width: "100%", mb: "10px" }}
      >
        <ChipWrapper label={selectedStage?.description ?? ""} />
      </Box>

      <Divider />
      <TableContainer>
        <Table aria-label="Stage's table">
          <TableBody>
            {selectedStage !== null &&
            (Object.keys(selectedStage).length ?? 0) > 0 ? (
              Object.keys(selectedStage).map((key: string) => {
                return key === "filter" ? (
                  <TableRow>
                    <TableCell>{key.replaceAll("_", " ")}</TableCell>
                    <TableCell>{selectedFilterData?.name ?? ""}</TableCell>
                  </TableRow>
                ) : key === "startby_criteria_value" ||
                  key === "match_upto_value" ||
                  key === "validation_value" ? (
                  <TableRow>
                    <TableCell>{key.replaceAll("_", " ")}</TableCell>
                    <TableCell>
                      {(selectedStage as KeyValueStringType)[key]["value"] ===
                      ""
                        ? "No Value"
                        : (selectedStage as KeyValueStringType)[key]["value"]}
                    </TableCell>
                  </TableRow>
                ) : key === "func_kwargs" ? (
                  <TableRow>
                    <TableCell>{key.replaceAll("_", " ")}</TableCell>
                    <TableCell>
                      {Object.keys((selectedStage as KeyValueStringType)[key])
                        .length === 0
                        ? "No Value"
                        : JSON.stringify(
                            (selectedStage as KeyValueStringType)[key]
                          )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>{key.replaceAll("_", " ")}</TableCell>
                    <TableCell>
                      {(selectedStage as KeyValueStringType)[key].toString() ===
                      ""
                        ? "No Value"
                        : (selectedStage as KeyValueStringType)[key].toString()}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No Details available</TableCell>
                <TableCell>No Details available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </InfoBar>
  );
}
