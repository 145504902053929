import {Chip} from "@mui/material";
import {styled} from "@mui/system";

type Props = {
    label: string;
};

const StyledChip = styled(Chip)({
    backgroundColor: "#16202C",
    height: "auto",
    textTransform: "capitalize",
    color: "white",
    fontSize: " x-large",
    zIndex: 900,
})

const ChipWrapper = ({label}: Props) => {
    return <StyledChip color="primary" label={label} variant="outlined"/>;
};

export default ChipWrapper;
