import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useFiltersQuery = (isLoggedIn: boolean, projectId: number) => {
    const [filtersRefetchInterval, setFiltersRefetchInterval] = useState(100);
    return useQuery(
        ["Filters", projectId],
        () => API.fetchFilters(projectId ?? 0),
        {
            refetchInterval: filtersRefetchInterval,
            enabled: isLoggedIn && projectId !== -1,
            onSuccess: (data) => {
                if (data) {
                    setFiltersRefetchInterval(0);
                }
            },
        }
    );
};

export default useFiltersQuery;
