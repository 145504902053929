import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DocsStateType} from "@/srcTypes/reduxTypes";
import {FetchImageResultsResponse} from "@/srcTypes/apiTypes";

const initialState: DocsStateType = {
    docsList: [],
    checkedDocsList: [],
    selectedDoc: null,
};
const docsState = createSlice({
    name: "docsState",
    initialState,
    reducers: {
        setSelectedDoc: (
            state,
            action: PayloadAction<FetchImageResultsResponse>
        ) => {
            return {...state, selectedDoc: action.payload};
        },
        resetSelectedDoc: (state) => {
            return {...state, selectedDoc: null};
        },

        setDocsList: (
            state,
            action: PayloadAction<FetchImageResultsResponse[]>
        ) => {
            return {...state, docsList: action.payload};
        },
        addDoc: (state, action: PayloadAction<FetchImageResultsResponse>) => {
            return {
                ...state,
                docsList: [...state.docsList, action.payload],
            };
        },
        resetDocsList: (state) => {
            return {...state, docsList: []};
        },

        setCheckedDocsList: (
            state,
            action: PayloadAction<FetchImageResultsResponse[]>
        ) => {
            return {...state, checkedDocsList: action.payload};
        },
        removeCheckedDoc: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                checkedDocsList: state.checkedDocsList.filter(
                    (checkedDoc) => checkedDoc.uid !== action.payload
                ),
            };
        },
        addCheckedDoc: (
            state,
            action: PayloadAction<FetchImageResultsResponse>
        ) => {
            return {
                ...state,
                checkedDocsList: [...state.checkedDocsList, action.payload],
            };
        },
    },
});

export default docsState;
