import {Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow} from "@mui/material/";

import Paper from "@mui/material/Paper";

import {ExtractedInfoType} from "@/srcTypes/hooksTypes";
import {styled} from "@mui/system";


const StyledTableRow = styled(TableRow)((props) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: props.theme.palette.action.hover,
    }
}))

const StyledTableCell = styled(TableCell)(({theme}) => (
    {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        }
    }
))


const classes = {
    table: {
        width: "100%",
    },
    container: {
        maxHeight: "80%",
    }
}


export default function MultipleTableOutput({
                                                extractedInfoData,
                                            }: {
    extractedInfoData: ExtractedInfoType[];
}) {


    const checkIsEmptyMultipleTable = (extractedInfoData: ExtractedInfoType[]) =>
        extractedInfoData.every(
            (extractedInfoItem) =>
                Object.keys(extractedInfoItem).length === 0,
            false
        );

    return (
        <>
            <TableContainer component={Paper} sx={classes.container}>
                <Table sx={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead sx={{backgroundColor: 'red'}}>
                        <TableRow>
                            {Object.keys(extractedInfoData[0]).map((key) => (
                                <StyledTableCell
                                    sx={{head: {backgroundColor: 'red'}}}>{key.replaceAll(".", " ")}</StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!checkIsEmptyMultipleTable(extractedInfoData) ? (
                            extractedInfoData.map((extractedInfoDoc) => (
                                <StyledTableRow>
                                    {Object.keys(extractedInfoDoc).map((key) => (
                                        <StyledTableCell
                                            sx={{head: {backgroundColor: 'red'}}}>{extractedInfoDoc[key]}</StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell sx={{head: {backgroundColor: 'red'}}}>No Filters
                                    available</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

// //Key
// //TS
// //reusable component
// //get data from api (from parent component)

/* {populateRows(keysData, valuesData).map(
                (row: { Key: String; Value: string }) => (
                  // <StyledTableRow key={row.Key}>
                  <StyledTableRow>
                    <TableCell>{row.Key}</TableCell>
                    <TableCell>{row.Value}</TableCell>
                  </StyledTableRow>
                )
              )} */

//Here I get the resulted data coming from the api that contains the values as (valuesDate)
//and create a row for each one with its corresponding Label from the static JSON file as (keysData)
// function populateRows(keysData: any, valuesData: any) {
//   return keysData.map((keyItem: any) => {
//     const valueItem = valuesData.filter(
//       (valueItem: any) => valueItem.nameFromApi === keyItem.nameFromApi
//     )[0];

// }
// function createData(Key: string, Value: string) {
//   return { Key, Value };
// }
//     return createData(keyItem.label, valueItem.value);
//   });
