import React, {useRef, useState} from "react";
import {Box, Button, CircularProgress, Typography,} from "@mui/material";
import {Form, Formik, FormikProps, FormikValues} from "formik";
import * as Yup from "yup";
import StageAccordion from "./accordion/StageAccordion";

import {getMutationErrorMessage} from "@/utils/getMutationErrorMessage";
import {AxiosError} from "axios";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import stagesStateSlice from "@/redux/reducers/stagesState";

import {useParams} from "react-router-dom";

import useCreateStageMutation from "@/hooks/api/Stages/useCreateStageMutation";
import useUpdateStageMutation from "@/hooks/api/Stages/useUpdateStageMutation";
import usePreviewStageMutation from "@/hooks/api/Stages/usePreviewStageMutation";

import useStageFormToFormData from "@/hooks/Stages/useStageFormToFormData";
import useStageJsonToStageForm from "@/hooks/Stages/useStageJsonToStageForm";

import SnackBarWrapper from "@/components/SnackBarWrapper";
import ModalWrapper from "@/components/ModalWrapper";

import StagePreview from "./preview/StagePreview";

import {ImageDataType} from "@/screens/Stages/components/preview/components/ImageListModal";

import {CreateStageResponse} from "@/srcTypes/apiTypes";

const classes = {
    root: {
        width: "95%",
    },
    ButtonGroup: {
        display: "flex",
        justifyContent: "flex-end",
        margin: "0px 10px",
    },
    previewSectionContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0px",
    },
}

const FORM_VALIDATION = Yup.object().shape({
    sequence_number: Yup.number()
        .required("required!")
        .test(
            "Is greater or equal zero?",
            "invalid Number",
            (value) => (value ?? 0) >= 0
        ),
});

type Props = {
    stagesData: CreateStageResponse[];
    openPreviewStageModal: boolean;
    setOpenPreviewStageModal: (openPreviewStageModal: boolean) => void;
    isDuplicate: boolean;
    setIsDuplicate: (isDuplicate: boolean) => void;
    newSeqNo: number;
};

export default function CreateStage({
                                        stagesData,
                                        openPreviewStageModal,
                                        setOpenPreviewStageModal,
                                        isDuplicate,
                                        setIsDuplicate,
                                        newSeqNo,
                                    }: Props) {

    const [convertStageFormToFormData] = useStageFormToFormData();
    //decoupling children components from the parent
    const {filterId} = useParams();

    //The resulted and the input text from the preview process
    const [previewInputText, setPreviewInputText] = useState<string>("");
    const [previewOutputText, setPreviewOutputText] = useState<string>("");

    //Submit flag used for checking whether the current submitting of the form is for the preview, create or update
    const [submitFlag, setSubmitFlag] = useState({submitType: "", previewType: ""});

    //Image List: is used to help user preview the stage by viewing the extracted text from an image
    //the images are those which the user previously uploaded and converted via the OCR app
    const initialImageData = {img: "", title: "", documentText: "", RTL: true};
    const [selectedImageData, setSelectedImageData] = useState<ImageDataType>(initialImageData);

    //Boolean variable used to control the accordion's children expansion
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleClosePreviewStageModal = () => {
        setOpenPreviewStageModal(false);
        dispatch(resetUpdatedStage());
        //Clear Stage Preview input a& Output TextFields on Closing the modal:
        setSelectedImageData(initialImageData);
        setPreviewOutputText("");
        setPreviewInputText("");
        setIsDuplicate(false);
    };

    const formRef = useRef<FormikProps<FormikValues>>(null);

    const submitFormOutsideFormik = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    //-----------------------Redux----------------------------
    const {stagesState} = useSelector((state: AppState) => state);
    const {updatedStage} = stagesState;
    const dispatch: AppDispatch = useDispatch();
    const {resetUpdatedStage} = stagesStateSlice.actions;

    //custom hook for obtaining the initial values of the form whether is to create a new stage
    //or update an old stage  --> (to populate the data of the stage in the form)
    const [convertStageJsonToStageForm] = useStageJsonToStageForm();
    const custom_init_form_state = convertStageJsonToStageForm(updatedStage, isDuplicate, newSeqNo,);

    //Managing the post request of create, update and preview a stage:
    const {mutateAsync: previewStageMutateAsync, status: previewStageMutationStatus} = usePreviewStageMutation();


    const {
        status: createStageMutationStatus, mutateAsync: createStageMutateAsync,
        error: createStageMutationError
    } = useCreateStageMutation();

    const {
        status: updateStageMutationStatus,
        mutateAsync: updateStageMutateAsync,
    } = useUpdateStageMutation(updatedStage?.id ?? 0);

    const handleRequestPreview = async (formData: FormData) => {
        try {
            let res = await previewStageMutateAsync(formData);
            if (submitFlag.previewType === "input") {
                setPreviewInputText(res.toString());
                setPreviewOutputText("");
                setSubmitFlag({
                    submitType: "preview",
                    previewType: "output",
                });
                submitFormOutsideFormik();
            } else {
                //on setting previewInputText to empty string the image raw data will be shown in the previewInput table
                setPreviewOutputText(res.toString());
            }
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    const handleRequestCreation = async (formData: FormData) => {
        try {
            await createStageMutateAsync(formData);
            handleClosePreviewStageModal();
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    const handleRequestUpdate = async (formData: FormData) => {
        try {
            await updateStageMutateAsync(formData);
            handleClosePreviewStageModal();
            dispatch(resetUpdatedStage());
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    //helper function for adding the current function to the functionList on clicking preview request only
    //it could detect whether the function added is a new one or existing one that has being editted
    const addCurrentToFuncListOnTheFly = (values: any): string => {
        //in order to add the function on the fly to function list on the preview, there is an edge case when the function itself equals a funciton list and this happens only
        //when accessing the preview from the update modal where all fields will be populated with the data coming from the backend, so when trying to add function on the fly
        //avoid the following conditions:
        if (
            values.apply_function !== "" &&
            values.apply_function !== "function_list" &&
            values.is_func_list
        ) {
            //check if order of the function doesn't equal to -1 this mean that the function is already existing in the function list
            //and the user is editting it on the fly:
            let parsedFuncListArray = JSON.parse(values.func_list)["func_list"];
            if (values.apply_function_order === -1)
                parsedFuncListArray.push({
                    func_name: values.apply_function,
                    params: JSON.parse(
                        values.func_kwargs === "" ? "{}" : values.func_kwargs
                    ),
                });
            else {
                parsedFuncListArray = parsedFuncListArray.map(
                    (func: any, funcIndex: number) =>
                        funcIndex === values.apply_function_order
                            ? {
                                func_name: values.apply_function,
                                params: JSON.parse(
                                    values.func_kwargs === "" ? "{}" : values.func_kwargs
                                ),
                            }
                            : func
                );
            }
            return JSON.stringify({func_list: parsedFuncListArray});
        } else return values.func_list;
    };

    async function _submitForm(values: any, actions: any) {
        //for initState preview INPUT whether in update or create, it must be empty, meaning that it shouldn't
        //have any values for stage criteria or functionality
        const overrideSomeValuesForInitState = {
            filter: Number(filterId) ?? -1,
            startby_criteria: "stage_input",
            include_criteria_value: false,
            return_n_matches: 1,
            match_upto_criteria: "current_matching",
            include_match_upto_value: false,
            stage_match_upto_threshold: 90,
            apply_function: "",
            func_kwargs: {},
            func_list: {},
            is_func_list: false,
        };

        if (submitFlag.submitType === "create") {
            handleRequestCreation(convertStageFormToFormData({...values, filter: Number(filterId) ?? -1,}));
        } else if (submitFlag.submitType === "preview") {
            //on Preview add current function to the functionList if the functionList is not empty

            //the formObject that will be converted to formData then be sent in the request,
            //is depending on the type of data_source, i.e. if the selected data_source is not raw_data,
            //then the formObject will be used for showing preview text only. Thus, it we will use the initFormObject
            //of the stage with the custom data_source. otherwise,
            // we will use the objecting coming from the form for previewing the stage.
            const stageFormObject =
                submitFlag.previewType === "input"
                    ? {
                        ...custom_init_form_state,
                        ...overrideSomeValuesForInitState,

                        data_source: values.data_source,
                    }
                    : {
                        ...values,
                        filter: Number(filterId) ?? -1,

                        func_list: addCurrentToFuncListOnTheFly(values),
                    };

            handleRequestPreview(
                convertStageFormToFormData(stageFormObject, selectedImageData)
            );
        } else if (submitFlag.submitType === "update") {
            handleRequestUpdate(
                convertStageFormToFormData({
                    ...values,
                    filter: Number(filterId) ?? -1,
                })
            );
        }

        setSubmitFlag({
            submitType: "",
            previewType: "",
        });
        actions.setSubmitting(false);
    }


    return (
        <>
            <ModalWrapper
                open={openPreviewStageModal}
                handleClose={handleClosePreviewStageModal}
                disableClose={false}
                isOverFlow={true}
            >
                <Box sx={classes.root}>
                    <Typography component="h1" variant="h6" align="center">
                        {updatedStage !== null && !isDuplicate ? "Stage Update" : "Stage Preview"}
                    </Typography>

                    <Formik
                        validationSchema={FORM_VALIDATION}
                        initialValues={custom_init_form_state}
                        onSubmit={_submitForm}
                        innerRef={formRef}
                    >
                        <Form>
                            <StagePreview
                                previewMutationStatus={previewStageMutationStatus}
                                setSubmitFlag={setSubmitFlag}
                                previewInputText={previewInputText}
                                previewOutputText={previewOutputText}
                                setPreviewOutputText={setPreviewOutputText}
                                selectedImageData={selectedImageData}
                                setSelectedImageData={setSelectedImageData}
                            />
                            <StageAccordion
                                expanded={expanded}
                                setExpanded={setExpanded}
                                setSubmitFlag={setSubmitFlag}
                            />

                            <Button
                                disabled={
                                    createStageMutationStatus === "loading" ||
                                    updateStageMutationStatus === "loading"
                                }
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSubmitFlag({
                                        submitType:
                                            updatedStage !== null && !isDuplicate
                                                ? "update"
                                                : "create",
                                        previewType: "",
                                    });
                                }}
                                fullWidth
                            >
                                {createStageMutationStatus === "loading" ||
                                updateStageMutationStatus === "loading" ? (
                                    <CircularProgress size={24}/>
                                ) : updatedStage !== null && !isDuplicate ? (
                                    "Update Stage"
                                ) : !isDuplicate ? (
                                    "Create Stage"
                                ) : (
                                    "Duplicate with edit"
                                )}
                            </Button>

                            <SnackBarWrapper
                                status={createStageMutationStatus}
                                message={
                                    createStageMutationStatus === "success"
                                        ? "Stage Created successfully"
                                        : `Failed to create Stage: ${getMutationErrorMessage(
                                            createStageMutationError as AxiosError
                                        )}`
                                }
                            />
                        </Form>

                    </Formik>
                </Box>
            </ModalWrapper>
        </>
    );
}
