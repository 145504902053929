import React from "react";
import useCloneOneProjectQuery from "@/hooks/api/Projects/useCloneOneProjectQuery";
import useCloneProjectTreeMutation from "@/hooks/api/Projects/useCloneProjectTreeMutation";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Box, CircularProgress} from "@mui/material";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import useCloneOneFilterQuery from "@/hooks/api/Filters/useCloneOneFilterQuery";
import UseCloneFilterTreeMutation from "@/hooks/api/Filters/useCloneFilterTreeMutation";

const classes = {
    contextMenuContainer: {
        cursor: "pointer",
        width: "100%",
        display: "contents",
    },
}

const FilterContextMenu = ({projectId, filterId = -1,children}: {projectId?: string, filterId?: number; children: React.ReactNode;}) => {

    const [contextMenuState, setContextMenuState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialContextMenuState);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setContextMenuState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });
    };

    const handleOutsideClick= (event: React.MouseEvent<HTMLDivElement>)=>{
        if (contextMenuState.mouseY !== null){
            event.stopPropagation()
        }
    }
    const handleClose = () => {
        setContextMenuState(initialContextMenuState);
    };

    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;

    return (
        <Box onContextMenu={handleClick} onClick={handleOutsideClick} sx={classes.contextMenuContainer}>
            {children}
            <Menu
                keepMounted
                open={contextMenuState.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
                        ? {top: contextMenuState.mouseY, left: contextMenuState.mouseX}
                        : undefined
                }
            >
                <RenderCopyMenuItem
                    handleClose={handleClose}
                    isLoggedIn={isLoggedIn}
                    filterId={filterId}
                />

                <RenderPasteMenuItem projectId={projectId} handleClose={handleClose}/>
            </Menu>
        </Box>
    );
};
export default FilterContextMenu;

const initialContextMenuState = {
    mouseX: null,
    mouseY: null,
};

type RenderCopyMenuItemProps = {
    handleClose: () => void;
    isLoggedIn: boolean;
    filterId: number;
};
const RenderCopyMenuItem = ({
                                handleClose,
                                isLoggedIn,
                                filterId,
                            }: RenderCopyMenuItemProps) => {
    const [fetchCloneFlag, setFetchCloneFlag] = React.useState<boolean>(false);
    const {data: clonedOneFilterData, status: clonedOnefilterStatus} =
        useCloneOneFilterQuery(isLoggedIn, filterId, fetchCloneFlag);

    React.useEffect(() => {
        if (clonedOnefilterStatus === "success") {

            localStorage.setItem(
                "copiedFilter",
                JSON.stringify(clonedOneFilterData)
            );
            handleClose();
            setFetchCloneFlag(false);
        }
    }, [clonedOnefilterStatus, clonedOneFilterData]);
    return (
        <MenuItem
            disabled={filterId === -1}
            onClick={(e) => {
                setFetchCloneFlag(true)
                e.stopPropagation()
            }}
        >
            {clonedOnefilterStatus === "loading" ? (
                <>
                    Copying Filter...
                    <CircularProgress style={{margin: "0px 8px"}} size={20}/>
                </>
            ) : (
                <>Copy Filter</>
            )}
        </MenuItem>
    );
};

type RenderPasteMenuItemProps = {
    handleClose: () => void;
    projectId?: string ,
};
const RenderPasteMenuItem = ({handleClose, projectId}: RenderPasteMenuItemProps) => {
    const {
        status: cloneFilterTreeMutationStatus,
        mutateAsync: cloneFilterTreeMutationStatusAsync,
    } = UseCloneFilterTreeMutation(projectId);

    const handleRequestCreation = async (data: any) => {
        console.log(data);
        try {
            await cloneFilterTreeMutationStatusAsync(data);
            handleClose();
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    const handlePastingFromLocalStorage = (copiedProjectText: string) => {
        const reshapeingCopiedProject = {data: copiedProjectText};
        handleRequestCreation(reshapeingCopiedProject);
    };

    return (
        <MenuItem
            disabled={localStorage.getItem("copiedFilter") === null ? true : false}
            onClick={(e) => {
                handlePastingFromLocalStorage(localStorage.getItem("copiedFilter") ?? "{}");
                localStorage.removeItem("copiedFilter");
                e.stopPropagation()
            }}
        >
            {cloneFilterTreeMutationStatus === "loading" ? (
                <>
                    Pasting Filter...
                    <CircularProgress style={{margin: "0px 8px"}} size={20}/>
                </>
            ) : (
                <>Paste Filter</>
            )}
        </MenuItem>
    );
};
