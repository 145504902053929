import { Paper } from "@mui/material";

import SortableList from "@/components/SortableList";

import { MergerSelectedDocType } from "@/srcTypes/customTypes";

const SelectedDocList = ({
  tasksList,
  setTasksList,
}: {
  tasksList: MergerSelectedDocType[];
  setTasksList: (tasksList: MergerSelectedDocType[]) => void;
}) => {
  return (
    <>
      <Paper>
        <SortableList items={tasksList} setItems={setTasksList} />
      </Paper>
    </>
  );
};

export default SelectedDocList;
