import {DropzoneInputProps, DropzoneRootProps} from "react-dropzone";
import {Paper} from "@mui/material";
import {useTheme} from "@mui/material/styles";

type Props = {
    getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
    getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
    isDragActive: boolean | undefined,
};


const Dropper = ({getRootProps, getInputProps, isDragActive}: Props) => {
    const theme = useTheme()
    const classes = {
        pageContent: {
            height: "80%",
            width: "100%",
            margin: "10px",
            backgroundColor: "#FFFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            alignContent: "center",
            flexDirection: "column",
            border: `2px dashed ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
        },
        dropDiv: {
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }
    return (
        <>
            <Paper sx={classes.pageContent}>
                <div  {...getRootProps({className: `${classes.dropDiv} dropzone`})}>
                    <input {...getInputProps()} />
                    {isDragActive ?
                        (<p>Drop the files here ...</p>)
                        :
                        (<p> Drag 'n' drop an image here, or click to select one for cropping </p>)
                    }

                </div>
            </Paper>
        </>
    );
};

export default Dropper;
