import {useContext, useState} from "react";

import {Box, Button, CircularProgress, Divider, Paper,} from "@mui/material";
import MainContext from "@/contexts/MainContext";

import StageContextMenu from "@/screens/Stages/components/StageContextMenu";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import {useParams} from "react-router-dom";

import useStagesQuery from "@/hooks/api/Stages/useStagesQuery";

import StagesList from "./components/StagesList";
import CreateStage from "./components/CreateStage";
import StagesInfoBar from "./components/StagesInfoBar";
import {useTheme} from "@mui/material/styles";


const Stages = () => {
    const theme = useTheme()
    const classes = {
        stagesContainer: {
            pointeEvents: "none",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: theme.spacing(8),
            flexGrow: 1,
        },

        stagesHeader: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
        },
        stageListBarContainer: {
            position: "relative",
            width: "100%",
        },
        pageContent: {
            padding: 4,
            width: "50%",
            height: "30vh",
            borderRadius: "15px",
            backgroundColor: "#f1f4f9",
            mt: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            alignContent: "center",
            flexDirection: "column",
        },
        boxWrapper: {
            mt: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            alignContent: "center",
            height: "80vh",

            width: "100%",
        },
        landingPageButton: {
            minHeight: "50%",
            fontSize: "25px",
        },
    }

    //decoupling children components from the parent
    const {projectId, filterId} = useParams();

    const {isInfoBarOpen, setIsInfoBarOpen} = useContext(MainContext);

    const {userCredentials, stagesState} = useSelector(
        (state: AppState) => state
    );
    const {selectedStage} = stagesState;
    const {isLoggedIn} = userCredentials;

    const [openPreviewStageModal, setOpenPreviewStageModal] = useState(false);
    const [isDuplicate, setIsDuplicate] = useState(false);

    const {data: stagesData, status: stagesQueryStatus} = useStagesQuery(
        isLoggedIn,
        Number(projectId) ?? -1,
        Number(filterId) ?? -1
    );

    //On Creating a new stage its sequence numeber = max + 1
    //The first sequence number sequence numeber = +1 + 1 = 0
    const newSeqNo =
        (stagesData ?? [])?.reduce(
            (prevMaxSeqNo, stageItem) =>
                stageItem?.sequence_number > prevMaxSeqNo
                    ? stageItem.sequence_number
                    : prevMaxSeqNo,
            -1
        ) + 1;

    return (
        <>
            <Box sx={classes.stagesContainer}>
                {/*Check if there is no stages to display stage's landing page */}
                {stagesQueryStatus === "loading" ? (
                    <Box sx={classes.boxWrapper}>
                        <CircularProgress size={100}/>
                    </Box>
                ) : stagesData?.length === 0 ? (
                    <StageContextMenu
                        newSeqNo={newSeqNo}
                        filterId={Number(filterId ?? -1)}
                    >
                        <Box sx={classes.boxWrapper}>
                            <Paper sx={classes.pageContent}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                    sx={classes.landingPageButton}
                                    onClick={() => setOpenPreviewStageModal(true)}
                                >
                                    Create New Stage
                                </Button>
                            </Paper>
                        </Box>
                    </StageContextMenu>
                ) : (
                    <Box>
                        <Box sx={classes.stagesHeader}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setOpenPreviewStageModal(true)}
                                disabled={false}
                            >
                                Create New Stage
                            </Button>
                        </Box>
                        <Box sx={classes.stageListBarContainer}>
                            <Divider/>
                            <StagesList
                                setOpenPreviewStageModal={setOpenPreviewStageModal}
                                setOpenInfoBar={setIsInfoBarOpen}
                                setIsDuplicate={setIsDuplicate}
                                stagesData={stagesData ?? []}
                                newSeqNo={newSeqNo}
                            />
                        </Box>

                        <StagesInfoBar
                            openInfoBar={isInfoBarOpen}
                            setOpenInfoBar={setIsInfoBarOpen}
                            selectedStage={selectedStage}
                        />
                    </Box>
                )}
            </Box>

            <Box sx={{width: "500px"}}>
                <CreateStage
                isDuplicate={isDuplicate}
                setIsDuplicate={setIsDuplicate}
                stagesData={stagesData ?? []}
                openPreviewStageModal={openPreviewStageModal}
                setOpenPreviewStageModal={setOpenPreviewStageModal}
                newSeqNo={newSeqNo}
            />
            </Box>
        </>
    );
};

export default Stages;
