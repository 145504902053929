import TextfieldWrapper from "./TextfieldWrapper";
import {Box} from "@mui/material";

const classes = {
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: 1,
        marginRight: 1,
        width: 200,
    },
}

export default function DateAndTimePickerWrapper({label,name,}: {label: string;name: string;}) {
    return (
        <Box sx={classes.container}>
            <TextfieldWrapper name={name} label={label} type="datetime-local"/>
        </Box>
    );
}
