import React from "react";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel,} from "@mui/material";

import {useField, useFormikContext} from "formik";
import {styled} from "@mui/system";

const StyledFormLabel = styled(FormControlLabel)(({theme}) => (
    {
        [`&.root`]: {
            marginRight: "5px",
        },
        [`&.label`]: {
            color: "black",
        }
    }
))


type ConfigFormControl = {
    error: boolean;
};

type Props = {
    name: string;
    label: string;
    legend: string;
    handleChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;

    otherProps?: { [x: string]: any };
};
const CheckboxWrapper = ({
                             name,
                             label,
                             legend,
                             handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
                             },
                             ...otherProps
                         }: Props) => {
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const formikOnChangeCallback = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = evt.target;
        setFieldValue(name, checked);
    };

    const configCheckbox = {
        ...field,
        ...otherProps,
        onChange: (evt: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(evt);
            formikOnChangeCallback(evt);
        },
    };

    const configFormControl: ConfigFormControl = {error: false};
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
    }
    return (
        <FormControl {...configFormControl}>
            <FormLabel component="legend">{legend}</FormLabel>
            <FormGroup>
                <StyledFormLabel
                    control={
                        <Checkbox {...configCheckbox} checked={configCheckbox.value}/>
                    }
                    label={label}
                />
            </FormGroup>
        </FormControl>
    );
};

export default CheckboxWrapper;
