import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FilterByDate from "./components/FilterByDate";
import FilterByDocSelection from "./components/FilterByDocSelection/FilterByDocSelection";

import {MergerSelectedDocType} from "@/srcTypes/customTypes";
import {useTheme} from "@mui/material/styles";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


function TabPanel(props: TabPanelProps) {


    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{background: "#f1f4f9"}} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function MergerTabs({
                                       baseProjectId,
                                       targetProjectId,
                                       setIsDateRange,
                                       baseSelectedList,
                                       setBaseSelectedList,
                                       targetSelectedList,
                                       setTargetSelectedList,
                                   }: {
    baseProjectId: number;
    targetProjectId: number;
    setIsDateRange: (isDateRange: boolean) => void;
    baseSelectedList: MergerSelectedDocType[];
    setBaseSelectedList: (baseSelectedList: MergerSelectedDocType[]) => void;
    targetSelectedList: MergerSelectedDocType[];
    setTargetSelectedList: (targetSelectedList: MergerSelectedDocType[]) => void;
}) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        setIsDateRange(newValue === 0 ? true : false);
    };
    const theme = useTheme()
    return (
        <Box sx={{flexGrow: 1, backgroundColor: theme.palette.background.paper,}}>

            <Tabs
                sx={{backgroundColor: theme.palette.background.paper}}
                textColor="primary"
                value={value}
                onChange={handleChange}
                aria-label="merger tabs"
                variant="fullWidth"
            >
                <Tab label="Filter By Date" {...a11yProps(0)} />
                <Tab label="Filter By Document Selection" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <FilterByDate/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FilterByDocSelection
                    baseProjectId={baseProjectId}
                    targetProjectId={targetProjectId}
                    baseSelectedList={baseSelectedList}
                    setBaseSelectedList={setBaseSelectedList}
                    targetSelectedList={targetSelectedList}
                    setTargetSelectedList={setTargetSelectedList}
                />
            </TabPanel>
        </Box>
    );
}
