import React from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            sx={{width: "100%", height: "90%"}}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{width: "100%", height: "100%"}}>{children}</Box>
            )}
        </Box>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}


export default function TabsWrapper({
                                        children,
                                        value,
                                        setValue,
                                    }: {
    children: React.ReactNode;
    value: number;
    setValue: (value: number) => void;
}) {


    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const theme = useTheme()
    const classes = {
        root: {
            flexGrow: 1,
            width: "100%",
            backgroundColor: theme.palette.background.paper,
        },
    }
    return (
        <Box sx={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={true}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    <Tab
                        label="Image Info"
                        icon={<ImageIcon/>}
                        {...a11yProps(0)}
                    />
                    <Tab label="Word" icon={<DescriptionIcon/>} {...a11yProps(1)} />
                    <Tab
                        label="Extracted Info"
                        icon={<AssignmentIcon/>}
                        {...a11yProps(2)}
                    />
                </Tabs>
            </AppBar>
            {children}
        </Box>
    );
}
