import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {useTheme} from "@mui/material/styles";


export default function AccordionWrapper({
                                             title,
                                             setIsExpanded,
                                             children,
                                         }: {
    title: string;
    setIsExpanded: (isExpanded: boolean) => void;
    children: React.ReactNode;
}) {

    const theme = useTheme()
    const classes = {
        root: {
            width: "100%",
            height: "100%",
            borderRadius: "50%",

        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            color: "white",
        },
        summary: {
            backgroundColor: "#16202C",
        },
        expandingIcon: {
            color: "white",
        },
        accordionDetails: {
            display: "flex",
            padding: "0px",
            flexGrow: 1,
            height: '100%',
            flexDirection: 'column',
            // backgroundColor: '#131fe7'

        },
    }
    return (
        <Accordion
            onChange={(event: object, expanded: boolean) => setIsExpanded(!expanded)}
            sx={classes.root}
            defaultExpanded={true}
        >
            <AccordionSummary
                expandIcon={<ExpandLessIcon sx={classes.expandingIcon}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={classes.summary}
            >
                <Typography sx={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={classes.accordionDetails}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
