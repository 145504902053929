import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useDeleteFilterMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((filterId: number) => API.deleteFilter(filterId, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("Filters");
        },
    });
};

export default useDeleteFilterMutation;
