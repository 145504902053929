import React, {useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, IconButton, Tooltip, Typography} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import docsStateSlice from "@/redux/reducers/docsState";

import {FetchImageResultsResponse} from "@/srcTypes/apiTypes";

import {getDocName} from "@/utils/docHelpers";
import ModalWrapper from "@/components/ModalWrapper";

import {useParams} from "react-router-dom";
import useDocsQuery from "@/hooks/api/Documents/useDocsQuery";
import useDeleteDocMutation from "@/hooks/api/Documents/useDeleteDocMutation";
import TableWrapper from "@/components/TableWrapper";
import {MRT_ColumnDef, MRT_Row, MRT_TableInstance} from "material-react-table";
import FeedIcon from "@mui/icons-material/Feed";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from '@mui/icons-material/Refresh';
import {DocumentsSelectedDocType} from "@/srcTypes/customTypes";
import {API} from "@/utils/api";


const classes = {
    docNameAndThumbnailContainer: {
        display: "flex",
        alignItems: "center",
    },
    docName: {
        marginLeft: "10px",
    },
    deleteModalContainer: {
        display: "flex",
        flexDirection: "column",
        margin: "0px 5px",
        justifyContent: "space-around",
        height: "120px",
    },
    deleteModalButtonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    deleteBtn: {
        marginLeft: "15px",
    },
    loadingBoxWrapper: {
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        height: "80vh",

        width: "100%",
    },

    docNameCell: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center"
    }
}
type RenderDeleteConfirmationModalProps = {
    openDeleteConfimationModal: boolean;
    setOpenDeleteConfimationModal: (openImageListModal: boolean) => void;
    handleDeleteSelectedDocs: () => void;
};

const RenderDeleteConfirmationModal = ({
                                           openDeleteConfimationModal,
                                           setOpenDeleteConfimationModal,
                                           handleDeleteSelectedDocs,
                                       }: RenderDeleteConfirmationModalProps) => {

    const handleCloseDeleteConfirmationModal = () =>
        setOpenDeleteConfimationModal(false);
    return (
        <ModalWrapper
            open={openDeleteConfimationModal}
            handleClose={handleCloseDeleteConfirmationModal}
            disableClose={true}
        >
            <Box sx={classes.deleteModalContainer}>
                <Typography gutterBottom variant="h5" align="center">
                    Are you sure you want to permanently delete these selected docs?
                </Typography>
                <Box sx={classes.deleteModalButtonsContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloseDeleteConfirmationModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={classes.deleteBtn}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            handleCloseDeleteConfirmationModal();
                            handleDeleteSelectedDocs();
                        }}
                    >
                        Delete selected docs
                    </Button>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

type Props = {
    handleOnClickItem?: () => void;
    setOpenInfoBar: (openInfoBar: boolean) => void;
    handleCustomFuncOnCheckedDocs?: (
        docsData: FetchImageResultsResponse[]
    ) => any;
};
export default function DocsList({
                                     handleOnClickItem = () => {
                                     },
                                     setOpenInfoBar,
                                     handleCustomFuncOnCheckedDocs = (docsData: FetchImageResultsResponse[]) => {
                                     },
                                 }: Props) {

    //decoupling children components from the parent
    const {projectId} = useParams();

    const [openDeleteConfimationModal, setOpenDeleteConfirmationModal] = useState(false);


    const {docsState, userCredentials} = useSelector((state: AppState) => state);


    const {checkedDocsList, selectedDoc} = docsState;
    const {isLoggedIn} = userCredentials;

    const dispatch: AppDispatch = useDispatch();
    const {setSelectedDoc, setCheckedDocsList} = docsStateSlice.actions;


    const {data: docsData} = useDocsQuery(isLoggedIn, Number(projectId ?? -1));


    const {
        mutateAsync: deleteDocMutateAsync,
        status: deleteDocMutationStatus
    } = useDeleteDocMutation(Number(projectId) ?? -1);


    type TableDataType = {
        uid: string
        name: string;
        id: number;
        image: string;
        tableData: {
            checked: boolean;
        };
    };
    const [tableData, setTableData] = useState<TableDataType[]>([]);

    const [isRefreshExtraction, setIsRefreshExtraction] = useState(false);
    useEffect(() => {
        //on uploading any doc the tableData should be updated
        //only show the detected raw json docs
        if (docsData !== undefined && docsData.length > 0) {
            setTableData(
                docsData
                    ?.filter((doc) => (doc.completed ? true : false))
                    .map((doc, docIndex) => ({
                        id: docIndex,
                        uid: doc.uid,
                        name: `${getDocName(doc.image ?? "")}`,
                        image: doc.image ?? "",
                        tableData: {checked: false},
                    }))
            );
        } else {
            setTableData([]);
        }
    }, [docsData]);

    const [selectedDocs, setSelectedDocs] = useState<TableDataType[]>([]);

    const handleDeleteSelectedDocs = () => {
        const selectedUIDs = selectedDocs.map((doc) => docsData?.[doc.id].uid);
        deleteDocMutateAsync((selectedUIDs as string[]) ?? []);
    };

    function overlayTableRow(id: number, value: string, image: any) {
        const onClickHandler = () => {

            if (docsData !== undefined) {
                if (docsData?.[id]) {
                    dispatch(setSelectedDoc(docsData?.[id]));
                    setOpenInfoBar(false);
                    handleOnClickItem();
                }
            }
        }

        return (
            <Box sx={classes.docNameCell} onClick={onClickHandler}>
                {image && <img src={image} alt="" height="40" width="40"/>}
                <Typography sx={classes.docName}>{value}</Typography>
            </Box>
        )
    }

    const columns: MRT_ColumnDef<TableDataType>[] = [
        {
            accessorKey: 'id', //simple recommended way to define a column
            header: 'ID',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
            Cell: ({cell, row}) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>(), null)
        },
        {

            accessorKey: 'name', //simple recommended way to define a column
            header: 'Name',
            muiTableBodyCellProps: {align: 'left', sx: {'&': {width: '100% '}}},


            muiTableHeadCellProps: {align: 'left', sx: {'&': {width: '100% '}}},

            Cell: ({
                       cell, row


                   }) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>(), row.original.image)
        },

    ]

    function actionMenu(row: MRT_Row<TableDataType>) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: "right",
            }}>

                <Tooltip title="Properties">
                    <IconButton onClick={() => {
                        if (docsData !== undefined) {
                            const docIndex = docsData?.findIndex(
                                (doc, docIndex) => docIndex === row.original.id
                            );
                            if (docsData?.[docIndex]) {
                                dispatch(setSelectedDoc(docsData?.[docIndex]));
                                setOpenInfoBar(true);
                            }
                        }
                    }
                    }>
                        <FeedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Refresh Extracted Information">
                    <IconButton onClick={() => {
                        if (docsData !== undefined) {
                            setIsRefreshExtraction(true)
                            const docIndex = docsData?.findIndex(
                                (doc, docIndex) => docIndex === row.original.id
                            );
                            if (docsData?.[docIndex]) {
                                dispatch(setSelectedDoc(docsData?.[docIndex]));

                                API.RefreshExtraction(docsData?.[docIndex].uid ?? "-1", {}).then(() => setIsRefreshExtraction(false)).catch(() => setIsRefreshExtraction(false))

                            }
                        }
                    }
                    }>
                        <RefreshIcon/>

                    </IconButton>
                </Tooltip>

                <Tooltip title="Remove">
                    <IconButton onClick={() => {
                        setSelectedDocs([row.original as TableDataType]);
                        setOpenDeleteConfirmationModal(true);
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    function renderToolbarCustomActions(table: any) {
        const onClickHandler = () => {
            setSelectedDocs(table.getSelectedRowModel().flatRows as TableDataType[]);
            setOpenDeleteConfirmationModal(true);
        }

        if (table.getSelectedRowModel().flatRows.length > 1) {

            return (
                <Box sx={{display: 'flex',}}>
                    <Button variant="contained" color="primary" disabled={checkedDocsList.length === 0} onClick={() => {
                        return handleCustomFuncOnCheckedDocs(checkedDocsList);
                    }}> Download </Button>
                    <Button sx={{marginLeft: "2px"}} color="error" variant="contained" onClick={onClickHandler}>Delete
                        All!</Button>

                </Box>
            )
        }


    }

    const [selectedRows, setSelectedRows] = useState({});
    const tableInstanceRef = useRef<MRT_TableInstance<TableDataType>>(null);
    useEffect(() => {
        // console.log(tableInstanceRef?.current?.getSelectedRowModel().flatRows)
        const selectedRows = tableInstanceRef?.current?.getSelectedRowModel().flatRows
        const newCheckedDocsList: DocumentsSelectedDocType[] = [];
        if (docsData) {
            // alert(JSON.stringify(selectedRows))
            const tableUIDs = selectedRows?.map((sr => sr.original.uid))
            const result = docsData?.filter((docData) => tableUIDs?.includes(docData.uid))
            dispatch(setCheckedDocsList(result))

        }


    }, [selectedRows]);
    return (
        <>
            {deleteDocMutationStatus === "loading" ? (
                <Box sx={classes.loadingBoxWrapper}>
                    <CircularProgress size={100}/>
                </Box>
            ) : (
                <>
                    <Box>
                        <TableWrapper columns={columns} data={tableData} actionMenu={actionMenu}
                                      enableRowActions={true} enableRowSelection={true}
                                      onRowSelectionChange={setSelectedRows}
                                      rowSelection={selectedRows}
                                      toolbarCustomActions={renderToolbarCustomActions}
                                      tableInstanceRef={tableInstanceRef}
                        />

                    </Box>
                </>
            )}

            <RenderDeleteConfirmationModal
                openDeleteConfimationModal={openDeleteConfimationModal}
                setOpenDeleteConfimationModal={setOpenDeleteConfirmationModal}
                handleDeleteSelectedDocs={handleDeleteSelectedDocs}
            />
        </>
    );
}
