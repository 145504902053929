import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/Inbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import PublishIcon from "@mui/icons-material/Publish";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

import {NavLink} from "react-router-dom";

import {useQueryClient} from "react-query";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import navBarStateSlice from "@/redux/reducers/navBarState";
import projectsStateSlice from "@/redux/reducers/projectsState";
import filtersStateSlice from "@/redux/reducers/filtersState";
import stagesStateSlice from "@/redux/reducers/stagesState";

import docsStateSlice from "@/redux/reducers/docsState";

import {Trans, useTranslation} from "react-i18next";
import CarIcon from "@mui/icons-material/DriveEta"
import PaperIcon from "@mui/icons-material/Description"
import FilterIcon from "@mui/icons-material/FilterAlt"
import StageIcon from "@mui/icons-material/AccountTree"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import {styled} from "@mui/system";
import {Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DifferenceIcon from '@mui/icons-material/Difference';
import {PictureAsPdf} from "@mui/icons-material";

const StyledListItemIcon = styled(ListItemIcon)({
    minWidth: "0px",
    // color: "white",
})
const StyledListItemText = styled(ListItemText)(
    {
        marginLeft: "10px"
    }
)
type RenderListItemsProps = {
    handleOnLinkSubmit: () => void
    drawerWidth: string
    navProjectId: number | undefined,
    navFilterId: number | undefined,

}
const getClasses = (drawerWidth: string) => ({
    drawer: {
        width: drawerWidth,
        // transition: "width .2s ease",
        flexShrink: 0,
        zIndex: 10,

        '& .MuiDrawer-paper': {
            width: drawerWidth,
            // rgb(30,47,66)
            // backgroundColor: '#16202C',

            // boxShadow: 'inset 0 0 10px  #6e7b8d'
        }
    },

    drawerContainer: {
        overflow: "auto",
    },
    Link: {
        textDecoration: "none",
        color: "black",

    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },

})

const RenderListItems = ({handleOnLinkSubmit, drawerWidth, navProjectId, navFilterId}: RenderListItemsProps) => {

    const classes = getClasses(drawerWidth)
    const {t} = useTranslation();
    if (navProjectId && !navFilterId) {

        return (
            <>
                <NavLink
                    to={`projects/${Number(navProjectId ?? "0")}/documents`}
                    style={classes.Link}
                    onClick={handleOnLinkSubmit}
                >
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<PaperIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>{t("documents")}</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink
                    to={`projects/${Number(navProjectId ?? "0")}/filters`}
                    style={classes.Link}
                    onClick={handleOnLinkSubmit}
                >
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<FilterIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>{t("filters")}</Trans>}/>
                    </ListItem>
                </NavLink>
            </>
        )
    } else if (navProjectId && navFilterId) {

        return (

            <>
                <NavLink
                    to={`projects/${Number(navProjectId ?? "0")}/filters/${Number(
                        navFilterId ?? "0"
                    )}/stages`}
                    style={classes.Link}
                    onClick={handleOnLinkSubmit}
                >
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<StageIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>{t("stages")}</Trans>}/>
                    </ListItem>
                </NavLink>
            </>
        )
    } else if (!navProjectId && !navFilterId) {
        return (
            <>
                <NavLink to="projects" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={1} selected={false}>
                        <StyledListItemIcon>{<InboxIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>{t("projects")}</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="merger" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<CallMergeIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Merger</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="Generic-Uploader" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<PublishIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Generic Uploader</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="automation" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<LibraryBooksIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Batch Automation</Trans>}/>
                    </ListItem>
                </NavLink>

                <Divider/>
                <ListItem sx={{backgroundColor: '#eae9e9', height: "30px"}}>
                    <StyledListItemText primary={<Trans>EKYC</Trans>}/>
                </ListItem>
                <Divider/>
                <NavLink to="face-matching" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<PeopleAltIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Face Matching</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="screen-detector" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<ScreenSearchDesktopIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Screen Detector</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="document-classifier" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<DifferenceIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Document Classifier</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="liveness-detection" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<EmojiPeopleIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Liveness Detection</Trans>}/>
                    </ListItem>
                </NavLink>
                <Divider/>
                <ListItem sx={{backgroundColor: '#eae9e9', height: "30px"}}>
                    <StyledListItemText primary={<Trans>Tools</Trans>}/>
                </ListItem>
                <Divider/>
                <NavLink to="plate_recognition" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<CarIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Plate Recognition</Trans>}/>
                    </ListItem>
                </NavLink>
                <NavLink to="pdf-uploader" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<PictureAsPdf/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Upload PDF</Trans>}/>
                    </ListItem>
                </NavLink>
                <Divider/>
                <ListItem sx={{backgroundColor: '#eae9e9', height: "30px"}}>
                    <StyledListItemText primary={<Trans>Statistics</Trans>}/>
                </ListItem>
                <Divider/>
                <NavLink to="dashboard" style={classes.Link} onClick={handleOnLinkSubmit}>
                    <ListItem button key={0} selected={false}>
                        <StyledListItemIcon>{<EqualizerIcon/>}</StyledListItemIcon>
                        <StyledListItemText primary={<Trans>Dashboard</Trans>}/>
                    </ListItem>
                </NavLink>
            </>
        )

    }
}
type Props = {
    setIsInfoBarOpen: (isInfoBarOpen: boolean) => void;
};
export default function NavBarWrapper({setIsInfoBarOpen}: Props) {
    const {t} = useTranslation();


    const queryClient = useQueryClient();

    //After updating the navBarState using route parameters in the Projects component (main)
    //The NavBar will render conditionally using the navBarState
    //Can't use the router parameters here as the layout is only rendered once!
    const {navBarState} = useSelector((state: AppState) => state);
    const {navProjectId, navFilterId, drawerWidth} = navBarState;

    const classes = getClasses(drawerWidth)

    const dispatch: AppDispatch = useDispatch();
    const {setNavProjectId, setNavFilterId} = navBarStateSlice.actions;
    const {resetSelectedProject} = projectsStateSlice.actions;
    const {resetDocsList} = docsStateSlice.actions;
    const {resetFiltersList, resetSelectedFilter} = filtersStateSlice.actions;
    const {resetStagesList} = stagesStateSlice.actions;
    const {setDrawerWidth} = navBarStateSlice.actions;
    const clearSelectedProjectCache = () => {
        setIsInfoBarOpen(false);

        dispatch(setNavProjectId(undefined));
        dispatch(resetSelectedProject());
        dispatch(resetDocsList());
        queryClient.removeQueries("Docs");
        dispatch(resetFiltersList());
        queryClient.removeQueries("Filters");
    };
    const clearSelectedFilterCache = () => {
        setIsInfoBarOpen(false);
        dispatch(setNavFilterId(undefined));
        dispatch(resetSelectedFilter());
        queryClient.removeQueries("Stages");
        dispatch(resetStagesList());
    };
    const handleReturnToMainNav = () => clearSelectedProjectCache();

    const handleReturnToSelectedProjectNav = () => clearSelectedFilterCache();
    const {isMatchingBreakingPoint} = useSelector((state: AppState) => state);

    function handleOnLinkClick() {
        if (!isMatchingBreakingPoint) {
            dispatch(setDrawerWidth("0%"))
        }
    }

    return (
        <Drawer
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={classes.drawer}
            variant="permanent" //{largeScreen ? "permanent": "temporary"}
        >
            <Toolbar/>

            <Box sx={classes.drawerContainer}>
                <Box sx={classes.drawerHeader}>
                    {navProjectId ? (
                        <NavLink
                            to={
                                navFilterId
                                    ? `projects/${Number(navProjectId ?? "0")}/filters`
                                    : "projects"
                            }
                            style={classes.Link}
                        >
                            <IconButton
                                onClick={
                                    //if the filter is selected then the current page is the stages else it's docs or filters
                                    navFilterId
                                        ? handleReturnToSelectedProjectNav
                                        : handleReturnToMainNav
                                }
                            >
                                <ArrowBackIcon fontSize="small"/>
                            </IconButton>
                        </NavLink>
                    ) : (
                        ""
                    )}
                </Box>

                <List>
                    {RenderListItems({handleOnLinkSubmit: handleOnLinkClick, drawerWidth, navProjectId, navFilterId})}
                </List>
                <Divider/>
            </Box>
        </Drawer>
    );
}
