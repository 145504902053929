import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// import ChipInput from "material-ui-chip-input";
import {useField, useFormikContext} from "formik";
import Chip from "@mui/material/Chip"
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import CheckboxWrapper from "@/components/formComponents/CheckboxWrapper";
import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";
import SelectWrapper from "@/components/formComponents/SelectWrapper";
import SliderWrapper from "@/components/formComponents/SliderWrapper";

import stageFunctionsObj from "@/utils/stageFunctionsObj";
import {Autocomplete, TextField, useMediaQuery, useTheme} from "@mui/material";

const oneArgsFns = [
    "return_value",
    "char_number",
    "word_number",
    "column_number",
    "line_number",
    "table_number",
    "table_delete_row",
    "table_delete_col",
];

const allStageKWargsTextFields = [
    "n",
    "search_for",
    "replace_by",
    "pattern",
    "start_idx",
    "end_idx",
    "nearest_match_key",
    "nearest_match_value",
    "min_score",
    "return_value",
    "return_type",
    "group_no",
    "n_matches",
    "join_chr",
    "row_idx",
    "col_idx",
    "keyword",
];

const allKWargsInitStates = {
    "": {},
    receipt_extraction: {},
    words_count: {},
    char_count: {},
    extract_number: {},
    extract_email: {},
    remove_spaces: {},
    strip: {},
    return_value: {n: ""},
    char_number: {n: ""},
    word_number: {n: ""},
    column_number: {n: ""},
    line_number: {n: ""},
    search_replace: {search_for: "", replace_by: ""},
    search_replace_fuzzy: {search_for: "", replace_by: "", threshold: 0.8, aggressive: false},
    filter: {str_list: "", whitelist: "False"},
    regex: {pattern: "", return_type: "", n_matches: "1", group_no: "0"},
    split: {pattern: "", join_chr: "", start_idx: "", end_idx: ""},
    nearest_match: {list: "", min_score: "", return_value: ""},
    table_number: {n: ""},
    table_row: {
        start_idx: "",
        end_idx: "",
        slice_rows: "False",
    },
    table_col: {
        start_idx: "",
        end_idx: "",
        slice_cols: "False",
    },
    table_cell: {
        row_idx: "",
        col_idx: "",
    },

    table_delete_row: {n: ""},
    table_delete_col: {n: ""},
    table_rows_count: {},
    table_cols_count: {},
    table_sum_rows: {},
    table_sum_cols: {},

    table_contain_keyword: {
        keyword: "",
        matching_threshold: "0.9",
        aggressive: false,
    },
    table_find_keyword: {
        unit: "",
        keyword: "",
        matching_threshold: "0.9",
        aggressive: false,
    },
    table_find_regex: {
        unit: "",
        pattern: "",
    },
};

const allStageKWargsCheckboxesFields = [
    "whitelist",
    "slice_rows",
    "slice_cols",
    "aggressive",
];

type Props = {
    setApplyFunctionValue: (applyFunctionValue: string) => void;
    fnArgsValues: any;
    setFnArgsValues: (applyFunctionValue: any) => void;
};
export default function StageFunctionality({
                                               setApplyFunctionValue,
                                               fnArgsValues,
                                               setFnArgsValues,
                                           }: Props) {
    const [applyFunction] = useField("apply_function");
    const [applyFunctionOrder] = useField("apply_function_order");

    const [fnArgs] = useField("func_kwargs");
    const {setFieldValue, values: fieldsValues} = useFormikContext();



    const updateFnArgs = (fnArgsValues: any) => {

        setFieldValue(fnArgs.name, JSON.stringify(fnArgsValues));
        setFnArgsValues(fnArgsValues);
    };

    //-----------------------Redux----------------------------
    const {stagesState} = useSelector((state: AppState) => state);
    const {updatedStage} = stagesState;

    //Obtain chips array for populating it into the form when updating a specific stage
    const [defaultChipsValue, setDefaultChipsValue] = useState<string[] | any>(
        updatedStage?.apply_function === "nearest_match" ||
        updatedStage?.apply_function === "filter"
            ? (Object.values(updatedStage?.func_kwargs)[0] as string)
                .substring(1)
                .slice(0, -1)
                .split(",")
                .map((item: any) => {
                    //if the applied function is filter, then we need to convert the coming data from backend
                    //to numbers and Characters
                    //Else we will return the same exact strings separated in a list after removing the wrapping: {} characters
                    //Note that for any number (either 123 or '123') isNaN will be false
                    return updatedStage?.apply_function === "filter"
                        ? isNaN(item) === true
                            ? item[1]
                            : Number(item)
                        : item;
                })
            : []
    );

    //For Regex
    const [regexFnReturnType] = useField("return_type");

    //For Nearest Match
    //The initial value for the nearest_match chipList = defaultChipsValue
    const [nearestMatchList, setNearestMatchList] = useState<string[]>(
        defaultChipsValue as string[]
    );
    const [nearestMatchKey] = useField("nearest_match_key");
    const [nearestMatchValue] = useField("nearest_match_value");
    const handleDeleteNearestMatchItem = (
        deletedChipIndex: number
    ) => {
        const newNearestMatchList = nearestMatchList.filter(
            (chip, index) => index !== deletedChipIndex
        );
        updateFnArgs({
            ...fnArgsValues,
            list: `{${newNearestMatchList}}`,
        });
        setNearestMatchList(newNearestMatchList);
    };
    const handleAddNearestMatchItem = () => {
        const chip = `${nearestMatchKey.value}:${nearestMatchValue.value}`;

        //reformat the chip to 'key':'value'  to match the backend standards
        const ReformedChip = `'${chip.replace(":", `':'`)}'`;

        const newNearestMatchList = [...nearestMatchList, ReformedChip];

        setNearestMatchList(newNearestMatchList);

        updateFnArgs({
            ...fnArgsValues,
            list: `{${newNearestMatchList}}`,
        });
    };

    //Clear all keyword args values on changing the stage funciton
    const clearAllStagesFields = () => {
        //clear all text fields
        allStageKWargsTextFields.forEach((fieldName, index) => {
            //check if the field is not empty before clearing it --> expensive operation!
            if (
                (fieldsValues as any)[fieldName] !== "" &&
                (fieldsValues as any)[fieldName] !== undefined
            )
                setFieldValue(fieldName, "");
        });

        // clear all checkboxes
        allStageKWargsCheckboxesFields.forEach((fieldName) => {
            //check if the field is not false before clearing it --> expensive operation!
            if (
                (fieldsValues as any)[fieldName] !== false &&
                (fieldsValues as any)[fieldName] !== undefined
            )
                setFieldValue(fieldName, false);
        });

        //clear all chips
        defaultChipsValue.length > 0 && setDefaultChipsValue([]);
        nearestMatchList.length > 0 && setNearestMatchList([]);

        //clear func_kwargs values: --> The parent of all functions:
        setFnArgsValues({});
    };
    const theme = useTheme()
    const matchMdAndUp = useMediaQuery(theme.breakpoints.up("md"))
    return (
        <React.Fragment>
            <Grid item xs={applyFunctionOrder.value === -1 ? 12 : 10}>
                <SelectWrapper
                    name="apply_function"
                    label="Apply Function"
                    options={{
                        ...stageFunctionsObj,
                    }}
                    handleChange={(event) => {
                        //Clear kw args values on changing stage function (clear cache)
                        clearAllStagesFields();
                        setApplyFunctionValue(event.target.value);
                        //set init state to the chosen function's kwargs:
                        updateFnArgs(
                            allKWargsInitStates[
                                event.target.value as keyof typeof allKWargsInitStates
                                ]
                        );
                    }}
                />
            </Grid>

            {applyFunctionOrder.value !== -1 && (
                <Grid item xs={2}>
                    <TextfieldWrapper
                        name="apply_function_order"
                        label={"Order"}
                        disabled
                    />
                </Grid>
            )}
            {/* Handling Function arguments:
            Rendering the form Function arguments is conditional w.r.t the Stage Applied function
            and then use formik logic to collect all the args into one textField: func_kwargs(that is sent to the form on submitting) --> (Helper function called: updateFnArgs)

        */}

            {oneArgsFns.includes(applyFunction.value) && <Grid item xs={12}>
                <TextfieldWrapper
                    name="n"
                    label={
                        applyFunction.value.includes("return")
                            ? "Return Value"
                            : "Position"
                    }
                    handleChange={(event) => {
                        updateFnArgs({
                            ...fnArgsValues,
                            n: event.target.value,
                        });
                    }}
                />
            </Grid>}
            {applyFunction.value === "search_replace" && <>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="search_for"
                        label="Search For"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                search_for: event.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="replace_by"
                        label="Replace By"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                replace_by: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "search_replace_fuzzy" && <>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="search_for"
                        label="Search For"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                search_for: event.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="replace_by"
                        label="Replace By"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                replace_by: event.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SliderWrapper
                        min={0.0}
                        max={1.0}
                        step={0.05}
                        defaultValue={0.8}
                        name="threshold"
                        label="Matching Threshold"
                        handleChange={(newValue) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                threshold: `${(newValue as number)}`,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CheckboxWrapper
                        name="aggressive"
                        legend=""
                        label={"Aggressive"}
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                aggressive: event.target.value === "true" ? "False" : "True",
                            });
                        }}
                    />
                </Grid>

            </>}
            {applyFunction.value === "filter" && <>
                <Grid item sx={{width: "0px"}} xs={6}>
                    <Autocomplete
                        // onChange={(e, value) => setReceivers((state) => value)}
                        multiple
                        id="tags-filled"
                        value={defaultChipsValue}
                        options={[]}
                        sx={{maxWidth: "100% !important"}}
                        freeSolo
                        onChange={(e, values) => {

                            let newChips = values.map((chip) =>
                                isNaN(chip) !== false ? `'${chip}'` : chip
                            );

                            updateFnArgs({
                                ...fnArgsValues,
                                str_list: `[${newChips}]`,
                            });
                            setDefaultChipsValue(values)

                        }}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{width: "100%", flexGrow: 0}}
                                variant="outlined"
                                label="Characters List"
                                disabled={true}
                                placeholder=""

                            />
                        )}
                    />
                    {/*<ChipInput*/}
                    {/*    defaultValue={defaultChipsValue}*/}
                    {/*    onChange={(chips: any[]) => {*/}
                    {/*        //Need to check whether the new chip is numebr or string, if it's a string we need to wrap with ''*/}
                    {/*        //to match the Backend standards*/}
                    {/*        //Note that for any number (either 123 or '123') isNaN will be false*/}
                    {/*        let newChips = chips.map((chip) =>*/}
                    {/*            isNaN(chip) !== false ? `'${chip}'` : chip*/}
                    {/*        );*/}

                    {/*        updateFnArgs({*/}
                    {/*            ...fnArgsValues,*/}
                    {/*            str_list: `[${newChips}]`,*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*    placeholder="Press enter after each character"*/}
                    {/*    variant="standard"*/}
                    {/*    label="Special Characters"*/}
                    {/*/>*/}
                </Grid>

                <Grid item xs={6}>
                    <CheckboxWrapper
                        name="whitelist"
                        legend=""
                        label="Is it Whitelist?"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                whitelist: event.target.value === "true" ? "False" : "True",
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "split" && <>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="pattern"
                        label="Pattern"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                pattern: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="join_chr"
                        label="Join Character"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                join_chr: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="start_idx"
                        label="Start Index"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                start_idx: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="end_idx"
                        label="End Index"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                end_idx: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "nearest_match" && <>
                <Grid item xs={12} md={4}>
                    <TextfieldWrapper name="nearest_match_key" label="Key"/>
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextfieldWrapper name="nearest_match_value" label="Value"/>
                </Grid>
                <Grid item xs={4} md={4}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleAddNearestMatchItem}
                    >
                        {matchMdAndUp ? "Add to the list" : "Add Item"}

                    </Button>
                </Grid>
                <Grid item xs={8} md={4}>

                    <Autocomplete
                        // onChange={(e, value) => setReceivers((state) => value)}
                        multiple
                        id="tags-filled"
                        value={nearestMatchList}
                        options={[]}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    sx={{marginLeft: "3px"}}
                                    key={index}
                                    label={option}
                                    onDelete={() => handleDeleteNearestMatchItem(index)}/>
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Nearest items list"
                                disabled={true}
                                placeholder=""
                                onKeyDown={(event) => {
                                    event.preventDefault();
                                }}
                            />
                        )}
                    />

                    {/*<ChipInput*/}
                    {/*  value={nearestMatchList}*/}
                    {/*  onDelete={handleDeleteNearestMatchItem}*/}
                    {/*  placeholder="Press enter after each item"*/}
                    {/*  variant="standard"*/}
                    {/*  label="Nearest items list"*/}
                    {/*/>*/}
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextfieldWrapper
                        name="min_score"
                        label="Minimum Score"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                min_score: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <TextfieldWrapper
                        name="return_value"
                        label="Return Value"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                return_value: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "regex" && <>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="pattern"
                        label="Pattern"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                pattern: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SelectWrapper
                        name="return_type"
                        label="Return Type"
                        options={{
                            whole: "All Matched Results",
                            group: "By Group",
                        }}

                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                return_type: event.target.value,
                            });
                        }}

                        // otherProps={{'initialValue': 'whole'}}
                    />
                </Grid>

                <Grid item xs={regexFnReturnType.value === "group" ? 6 : 12}>
                    <TextfieldWrapper
                        name="n_matches"
                        label="Return N mathces"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                n_matches: event.target.value,
                            });
                        }}
                    />
                </Grid>

                {regexFnReturnType.value === "group" ? (
                    <Grid item xs={6}>
                        <TextfieldWrapper
                            name="group_no"
                            label="Group Number"
                            type="number"
                            handleChange={(event) => {
                                updateFnArgs({
                                    ...fnArgsValues,
                                    group_no: event.target.value,
                                });
                            }}
                        />
                    </Grid>
                ) : (
                    ""
                )}
            </>}
            {(applyFunction.value === "table_row" || applyFunction.value === "table_col") && <>
                {applyFunction.value === "table_row" ? (
                    <>
                        <Grid item xs={12}>
                            <CheckboxWrapper
                                name="slice_rows"
                                legend=""
                                label={"Select Multiple Rows"}
                                handleChange={(event) => {
                                    updateFnArgs({
                                        ...fnArgsValues,
                                        slice_rows:
                                            event.target.value === "true" ? "False" : "True",
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={fnArgsValues.slice_rows === "False" ? 12 : 6}>
                            <TextfieldWrapper
                                name="start_idx"
                                label="Start Index"
                                handleChange={(event) => {
                                    updateFnArgs({
                                        ...fnArgsValues,
                                        start_idx: event.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        {fnArgsValues.slice_rows === "True" && (
                            <Grid item xs={6}>
                                <TextfieldWrapper
                                    name="end_idx"
                                    label="End Index"
                                    handleChange={(event) => {
                                        updateFnArgs({
                                            ...fnArgsValues,
                                            end_idx: event.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <CheckboxWrapper
                                name="slice_cols"
                                legend=""
                                label={"Select Multiple Columns"}
                                handleChange={(event) => {
                                    updateFnArgs({
                                        ...fnArgsValues,
                                        slice_cols:
                                            event.target.value === "true" ? "False" : "True",
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={fnArgsValues.slice_cols === "False" ? 12 : 6}>
                            <TextfieldWrapper
                                name="start_idx"
                                label="Start Index"
                                handleChange={(event) => {
                                    updateFnArgs({
                                        ...fnArgsValues,
                                        start_idx: event.target.value,
                                    });
                                }}
                            />
                        </Grid>
                        {fnArgsValues.slice_cols === "True" && (
                            <Grid item xs={6}>
                                <TextfieldWrapper
                                    name="end_idx"
                                    label="End Index"
                                    handleChange={(event) => {
                                        updateFnArgs({
                                            ...fnArgsValues,
                                            end_idx: event.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        )}
                    </>
                )}
            </>}
            {applyFunction.value === "table_cell" && <>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="row_idx"
                        label="Row Index"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                row_idx: event.target.value,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="col_idx"
                        label="Column Index"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                col_idx: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "table_contain_keyword" && <>
                <Grid item xs={6}>
                    <CheckboxWrapper
                        name="aggressive"
                        legend=""
                        label={"Aggressive"}
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                aggressive: event.target.value === "true" ? "False" : "True",
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SliderWrapper
                        min={0.0}
                        max={100.0}
                        step={5.0}
                        defaultValue={90.0}
                        name="matching_threshold"
                        label="Matching Threshold"
                        handleChange={(newValue) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                matching_threshold: `${(newValue as number) / 100}`,
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextfieldWrapper
                        name="keyword"
                        label="Keyword"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                keyword: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "table_find_keyword" && <>
                <Grid item xs={6}>
                    <CheckboxWrapper
                        name="aggressive"
                        legend=""
                        label={"Aggressive"}
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                aggressive: event.target.value === "true" ? "False" : "True",
                            });
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SliderWrapper
                        min={0.0}
                        max={100.0}
                        step={5.0}
                        defaultValue={90.0}
                        name="matching_threshold"
                        label="Matching Threshold"
                        handleChange={(newValue) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                matching_threshold: `${(newValue as number) / 100}`,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="keyword"
                        label="Keyword"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                keyword: event.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectWrapper
                        name="unit"
                        label="Unit"
                        options={{
                            col: "Column",
                            row: "Row",
                            cell: "Cell",
                        }}
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                unit: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}
            {applyFunction.value === "table_find_regex" && <>
                <Grid item xs={6}>
                    <TextfieldWrapper
                        name="pattern"
                        label="Pattern"
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                pattern: event.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectWrapper
                        name="unit"
                        label="Unit"
                        options={{
                            col: "Column",
                            row: "Row",
                            cell: "Cell",
                        }}
                        handleChange={(event) => {
                            updateFnArgs({
                                ...fnArgsValues,
                                unit: event.target.value,
                            });
                        }}
                    />
                </Grid>
            </>}

        </React.Fragment>
    );
}
