import React, {useEffect, useRef, useState} from "react";

import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import {canvasPreview} from "./canvasPreview";
import {useDebounceEffect} from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";
import {Box, Card, Grid} from "@mui/material";

import {UploadableFile} from "@/srcTypes/InputFormTypes";
import ChipWrapper from "@/components/ChipWrapper";
import CroppingControllers from "./CroppingControllers";

const classes = {
    croppedDataContainer: {
        marginTop: "30px",
    },
    croppingControllersContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        marginTop: "30px",
    },

    croppedImageLabelContaienr: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    resetButton: {
        marginRight: "50px",
    },

    croppingImageGrid: {
        display: "flex",
        objecFit: "contain",
        justifyContent: "center",
        alignItems: "center",
    },
    croppingImageContainer: {
        width: "50vw",
        maxHeight: "450px",
    },

    croppedContainer: {
        display: "flex",
        objecFit: "contain",
        justifyContent: "center",
        alignItems: "center",
    },
    croppedCard: {
        backgroundColor: "white",
        width: "200px",
        height: "200px",
    },
}

export default function ImageCropper({
                                         imgSrc,
                                         setUploadableCroppedImages,
                                         children,
                                     }: {
    imgSrc: string;
    setUploadableCroppedImages: (uploadableCroppedImages: any[]) => void;
    children: JSX.Element;
}) {


    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);

    useEffect(() => {
        setCrop(undefined); // Makes crop preview update between images.
    }, [imgSrc]);

    const asyncCanvasToBlob = (canvas: HTMLCanvasElement) => {
        return new Promise(function (resolve: (blob: Blob | null) => void) {
            canvas.toBlob(resolve, "image/jpeg");
        });
    };

    const convertImags2UploadableImags = (images: any) => {
        let counter = 0;
        const uploadableImags: UploadableFile[] = images.map((image: any) => ({
            id: ++counter,
            file: image,
            errors: [],
            url: "",
        }));
        return uploadableImags;
    };

    const convertBlobToImageFile = (blob: any) => {
        const randomNumber = Math.floor(Math.random() * 10000);

        return new File(
            [blob ?? ({} as BlobPart)],
            `cropped_image_${randomNumber}.jpg`,
            {
                type: "image/jpeg",
            }
        );
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate
                );

                //Obtain the cropped image file to upload it to the project's documents:
                const croppedBlob = await asyncCanvasToBlob(previewCanvasRef.current);
                const croppedImageFile = convertBlobToImageFile(croppedBlob);
                setUploadableCroppedImages(
                    convertImags2UploadableImags([croppedImageFile])
                );
            }
        },
        100,
        [completedCrop, scale, rotate]
    );

    return (
        <Box className="App">
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={classes.croppingControllersContainer}>
                        <CroppingControllers
                            rotate={rotate}
                            setRotate={setRotate}
                            scale={scale}
                            setScale={setScale}
                            imgSrc={imgSrc}
                        />
                    </Grid>
                    <Grid item xs={12} sx={classes.croppingImageGrid}>
                        {Boolean(imgSrc) && (
                            <ReactCrop
                                style={classes.croppingImageContainer}
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
                                />
                            </ReactCrop>
                        )}
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={classes.croppedDataContainer}>
                    <Grid item xs={6} sx={classes.croppedImageLabelContaienr}>
                        <div>
                            <ChipWrapper label="Cropped Image: "/>
                        </div>
                    </Grid>

                    <Grid item xs={6} sx={classes.croppedContainer}>
                        {Boolean(completedCrop) && (
                            <Card sx={classes.croppedCard}>
                                <canvas
                                    ref={previewCanvasRef}
                                    style={{
                                        border: "1px solid black",
                                        objectFit: "contain",
                                        width: "200px",
                                        height: "200px",
                                    }}
                                />
                            </Card>
                        )}
                    </Grid>

                    {children}
                </Grid>
            </>
        </Box>
    );
}
