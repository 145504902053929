import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent, CircularProgress, IconButton, TextField, Typography} from "@mui/material";
import {useMutation} from "react-query";
import {API} from "@/utils/api";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import Chart from "react-apexcharts"
import {ApexOptions} from "apexcharts";

import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";

type dateRangeType = { from: string, to: string }
type statsType = {
    date_from: Date,
    date_to: Date,
    stats: {
        total_post_requests: number,
        total_get_requests: number,
        document_detector: {
            POST: {
                total_count: number,
                batch_automation_count: number,
                ocr_pipeline_count: number,
                analyzer_count: number
            },
            GET: {
                total_count: number,
                analyzer_count: number
            }
        },
        rotation_estimator: {
            POST: {
                total_count: number,
                batch_automation_count: number,
                ocr_pipeline_count: number,
                analyzer_count: number
            },
            GET: {
                total_count: number
            }
        },
        document_classifier: {
            POST: {
                total_count: number,
                batch_automation_count: number,
                ekyc_count: number
            },
            GET: {
                total_count: number,
                ekyc_count: number
            }

        },
        face_matching: {
            POST: {
                total_count: number,
                ekyc_count: number,
            },
            GET: {
                total_count: number,
                ekyc_count: number,
            }
        },
        eye_blink: {
            POST: {
                total_count: number,
                ekyc_count: number,
            },
            GET: {
                total_count: number,
                ekyc_count: number,
            }
        },
        screen_detector: {
            POST: {
                total_count: number,
                ocr_pipeline_count: number,
                ekyc_count: number,
            },
            GET: {
                total_count: number,
                ekyc_count: number,
            }
        },
        batch_automation: {
            POST: {
                total_count: number
            },
            GET: {
                total_count: number
            }
        },
        plate_recognition: {
            POST: {
                total_count: number
            },
            GET: {
                total_count: number
            }
        },
        ocr_pipeline: {
            POST: {
                total_count: number,
                projects_stats: { [key: string]: number },
                templates_stats: { [key: string]: number },
            },
            GET: {
                total_count: number
            }
        }
    }
}
const initialStats: statsType = {
    date_from: new Date(),
    date_to: new Date(),
    stats: {
        total_post_requests: 0,
        total_get_requests: 0,
        document_detector: {
            POST: {
                total_count: 0,
                batch_automation_count: 0,
                ocr_pipeline_count: 0,
                analyzer_count: 0
            },
            GET: {
                total_count: 0,
                analyzer_count: 0
            }
        },
        rotation_estimator: {
            POST: {
                total_count: 0,
                batch_automation_count: 0,
                ocr_pipeline_count: 0,
                analyzer_count: 0
            },
            GET: {
                total_count: 0,
            }
        },
        document_classifier: {
            POST: {
                total_count: 0,
                batch_automation_count: 0,
                ekyc_count: 0
            },
            GET: {
                total_count: 0,
                ekyc_count: 0
            }

        },
        face_matching: {
            POST: {
                total_count: 0,
                ekyc_count: 0,
            },
            GET: {
                total_count: 0,
                ekyc_count: 0,
            }
        },
        eye_blink: {
            POST: {
                total_count: 0,
                ekyc_count: 0,
            },
            GET: {
                total_count: 0,
                ekyc_count: 0,
            }
        },
        screen_detector: {
            POST: {
                total_count: 0,
                ocr_pipeline_count: 0,
                ekyc_count: 0,
            },
            GET: {
                total_count: 0,
                ekyc_count: 0,
            }
        },
        batch_automation: {
            POST: {
                total_count: 0
            },
            GET: {
                total_count: 0
            }
        },
        plate_recognition: {
            POST: {
                total_count: 0
            },
            GET: {
                total_count: 0
            }
        },
        ocr_pipeline: {
            POST: {
                total_count: 0,
                projects_stats: {},
                templates_stats: {},
            },
            GET: {
                total_count: 0
            }
        }
    }
}

const classes = {
    boxWrapper: {
        mt: '50px',
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        background: "#f1f4f9",
        overflow: 'auto'
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'flex-start',

    },
    dateFilterBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: 4,
        marginTop: 2,
        borderRadius: '20px',
        backgroundColor: 'white'
    },
    chartFlexBox: {
        display: 'flex',
        justifyContent: "flex-start",
        width: '100%',
        height: '100%',
        ml: 1,
        padding: 1,
        borderRadius: '20px',
        flexWrap: 'wrap',
    },
    barContainer: {
        backgroundColor: 'white',
        width: "100%",
        height: "400px",
        ml: 2,
        mr: 2,
        mb: 2,
    },
    smallBarContainer: {
        backgroundColor: 'white',
        width: "100%",
        height: "200px",
        ml: 2,
        mt: 2,
    },
    pieContainer: {
        border: "2px black solid",
        backgroundColor: 'white',
        height: "300px",
        width: "30%",
        minWidth: "480px",
        m: 2,
    },
    cardContainer: {
        "&:hover": {
            boxShadow: "0 16px 50px -12.125px rgba(0,0,0,0.3)",
        },
        minWidth: 275,
        m: 2,
    }
}

function Dashboard() {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const [stats, setStats] = useState<statsType>(initialStats);
    const [dateRange, setDateRange] = useState<dateRangeType>({
        from: moment().subtract(1, "months").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
    });

    const {mutateAsync: getStatistics} = useMutation((data: FormData) => {
        return API.userStatistics(data, {})
    })

    const services = ["ocr_pipeline", "document_detector", "rotation_estimator", "document_classifier", "face_matching",
        "eye_blink", "screen_detector", "batch_automation", "plate_recognition"] as const
    const [projectStatsBarOptions, setProjectStatsBarOptions] = useState<ApexOptions>({
        chart: {
            id: "Projects Statistics",
        },
        xaxis: {
            categories: Object.keys(stats?.stats.ocr_pipeline.POST.projects_stats) //will be displayed on the x-asis
        },
        title: {
            text: 'Projects Statistics',
            align: 'left'
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: true
        },
    })
    const [templateStatsBarOptions, setTemplateStatsBarOptions] = useState<ApexOptions>({
        chart: {
            id: "Templates Statistics",
        },
        xaxis: {
            categories: Object.keys(stats?.stats.ocr_pipeline.POST.templates_stats) //will be displayed on the x-asis
        },
        title: {
            text: 'Templates Statistics',
            align: 'left'
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: true
        },
    })

    const [projectStatsBarSeries, setProjectStatsBarSeries] = useState([
        {
            name: "Number of Requests", //will be displayed on the y-axis
            data: Object.values(stats.stats.ocr_pipeline.POST.projects_stats)
        }
    ]);
    const [templateStatsBarSeries, setTemplateStatsBarSeries] = useState([
        {
            name: "Number of Requests", //will be displayed on the y-axis
            data: Object.values(stats.stats.ocr_pipeline.POST.templates_stats)
        }
    ]);

    const [projectStatsPieSeries, setProjectStatsPieSeries] = useState(Object.values(stats.stats.ocr_pipeline.POST.projects_stats));

    const [isLoading, setIsLoading] = useState(false);
    const [serviceStatsPieSeries, setServiceStatsPieSeries] = useState(Array(services.length).fill(0));

    const [totalStatsPieSeries, setTotalStatsPieSeries] = useState([0, 0]);

    const pieChartOptions: ApexOptions = {
        labels: [],
        title: {
            text: '',
            align: 'left'
        },
        responsive: [{
            breakpoint: 900,
            options: {
                legend: {
                    position: 'bottom',
                }

            }
        }],
        legend: {
            show: true,
            width: 190,
            position: 'left',
            horizontalAlign: 'left'
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: false,
            }
        },
        dataLabels: {
            enabled: true
        },
    }

    useEffect(() => {
        if (stats?.stats) {
            setTotalStatsPieSeries([stats?.stats.total_post_requests, stats?.stats.total_get_requests])
            setServiceStatsPieSeries(
                services.map(value => (stats?.stats[value].POST.total_count))
            )
            setProjectStatsBarOptions(prevState => ({
                ...prevState,
                xaxis: {categories: Object.keys(stats?.stats.ocr_pipeline.POST.projects_stats)}
            }))
            setTemplateStatsBarOptions(prevState => ({
                ...prevState,
                xaxis: {categories: Object.keys(stats?.stats.ocr_pipeline.POST.templates_stats)}
            }))
            setProjectStatsPieSeries(Object.values(stats.stats.ocr_pipeline.POST.projects_stats))

            setProjectStatsBarSeries([{
                name: "Number of Requests", //will be displayed on the y-axis
                data: Object.values(stats.stats.ocr_pipeline.POST.projects_stats)
            }])
            setTemplateStatsBarSeries([{
                name: "Number of Requests", //will be displayed on the y-axis
                data: Object.values(stats.stats.ocr_pipeline.POST.templates_stats)
            }])
        }

    }, [stats]);

    useEffect(() => {
        uploadFormDate()
    }, []);


    function renderCards() {
        const barOptions: ApexOptions = {
            chart: {
                id: "",
            },
            xaxis: {
                categories: ['POST', 'GET'] //will be displayed on the x-asis
            },

            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: true
            },
        }
        const modules = ["ocr_pipeline", "document_detector", "rotation_estimator", "document_classifier", "face_matching",
            "eye_blink", "screen_detector", "batch_automation", "plate_recognition"] as const
        return (modules).map((value, index) => {
            return (
                <Card key={index} sx={classes.cardContainer}>
                    <CardContent>
                        <Typography sx={{fontSize: 14, fontWeight: 'bold'}} gutterBottom>
                            {
                                value.replace("_", " ").toUpperCase()
                            }
                        </Typography>
                        <Box sx={{display: 'flex', margin: 2}}>
                            <Typography sx={{fontSize: 16, fontWeight: 'bold'}} color="text.secondary">
                                POST: {stats.stats[value].POST.total_count}
                            </Typography>
                            <Box sx={{flex: '1 1 auto'}}/>
                            <Typography sx={{fontSize: 16, fontWeight: 'bold'}} color="text.secondary">
                                GET: {stats.stats[value].GET.total_count ?? 0}
                            </Typography>
                        </Box>

                        <Chart width="100%" height="300px" type="bar"
                               options={barOptions}
                               series={[{
                                   name: 'Number of Requests',
                                   data: [stats.stats[value].POST.total_count, stats.stats[value].GET.total_count]
                               }]}/>

                    </CardContent>
                </Card>
            )
        })
    }

    function uploadFormDate() {
        const formData = new FormData()
        formData.append("date_from", dateRange.from)
        formData.append("date_to", dateRange.to)
        if (isLoggedIn) {
            setIsLoading(true)
            getStatistics(formData, {

                onSuccess: (data: statsType) => {
                    console.log(data)
                    setIsLoading(false)
                    data.stats.rotation_estimator['GET'] = {total_count: 0}
                    data.stats.rotation_estimator.GET.total_count = 0
                    setStats(data)

                }
            })
        }
    }

    function handleFromChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDateRange(prevState => ({...prevState, from: event.target.value}))
    }

    function handleToChange(event: React.ChangeEvent<HTMLInputElement>) {
        setDateRange(prevState => ({...prevState, to: event.target.value}))
    }


    return (

        <Box sx={classes.boxWrapper}>
            <Box sx={classes.pageContent}>

                <Box sx={classes.dateFilterBox}>
                    <TextField name="Start Date"
                               label="Start Date"
                               value={dateRange.from}

                               onChange={handleFromChange}
                               sx={{m: 2}}
                               type="date"/>

                    <TextField name="End Date"
                               label="End Date"
                               value={dateRange.to}
                               onChange={handleToChange}
                               sx={{m: 2}}
                               variant="outlined" type="date"/>
                    {isLoading ?
                        <CircularProgress sx={{m: 1}} color="secondary" size="40px"/>
                        :
                        <IconButton sx={{m: 1}} onClick={uploadFormDate}><SearchIcon/></IconButton>

                    }


                </Box>
                <Box sx={classes.chartFlexBox}>
                    <Box sx={classes.barContainer}>
                        <Chart width="100%" height="400px" type="bar"
                               options={projectStatsBarOptions} series={projectStatsBarSeries}/>
                    </Box>
                    {/*<Box sx={classes.barContainer}>*/}
                    {/*    <Chart width="100%" height="400px" type="bar"*/}
                    {/*           options={templateStatsBarOptions} series={templateStatsBarSeries}/>*/}
                    {/*</Box>*/}
                </Box>
                <Box sx={classes.chartFlexBox}>
                    <Box sx={{...classes.pieContainer, width: '20%'}}>
                        <Chart width="100%" height="300px" type="pie"
                               options={{
                                   ...pieChartOptions,
                                   labels: ["POST", "GET"],
                                   title: {text: 'Total Requests'}
                               }} series={totalStatsPieSeries}/>
                    </Box>
                    <Box sx={{...classes.pieContainer,}}>
                        <Chart width="100%" height="300px" type="pie"
                               options={{
                                   ...pieChartOptions,
                                   labels: Object.keys(stats?.stats.ocr_pipeline.POST.projects_stats),
                                   title: {text: 'Projects Statistics'}
                               }} series={projectStatsPieSeries}/>
                    </Box>
                    <Box sx={classes.pieContainer}>
                        <Chart width="100%" height="300px" type="pie"
                               options={{
                                   ...pieChartOptions,
                                   title: {text: 'Service Statistics'},
                                   labels: ["OCR Pipeline", "Document Detector", "Rotation Estimator", "Document Classifier", "Face Matching",
                                       "Liveness Detection", "Screen Detector", "Batch Automation", "Plate Recognition"]
                               }} series={serviceStatsPieSeries}/>
                    </Box>
                </Box>
                <Box sx={classes.chartFlexBox}>
                    {renderCards()}
                </Box>


            </Box>


        </Box>
    );
}

export default Dashboard;