import {Box, Button, useTheme} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {FileRejection, useDropzone} from "react-dropzone";

import {UploadableFile} from "@/srcTypes/InputFormTypes";
import usePdf2imgs from "@/hooks/components/usePdf2imgs";
import ChipWrapper from "@/components/ChipWrapper";

let currentId = 0;

function getNewId() {
    // we could use a fancier solution instead of a sequential ID :)
    return ++currentId;
}

type stylePropsType = {
    isDragging: boolean;
    isDraggingAboveChildren: boolean;
};


//on Starting dnd a scroll to top fn will be applied
const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "auto",
    });
};

type Props = {
    name: string;
    children?: React.ReactNode;
    setUploadedImageFiles?: (
        uploadedImageFiles: UploadableFile[] | undefined
    ) => void;

    setUploadedImageProjectId: (uploadedImageProjectId: number) => void;
    currentProjectId: number;
};

export function MultipleFileUploadField({
                                            children,
                                            setUploadedImageFiles = (uploadedImageFiles = []) => {
                                            },
                                            setUploadedImageProjectId,
                                            currentProjectId = -1,
                                        }: Props) {
    const {convertPdf2UploadableImages} = usePdf2imgs();
    const [isDragging, setIsDragging] = useState(false);
    const [isDraggingAboveChildren, setIsDraggingAboveChildren] = useState(false);
    const theme = useTheme()
    const classes = {
        listBarDropzone: {
            width: "99.7%",
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            outline: "none",
            ...(isDragging
                ? {
                    border: `2px dashed ${theme.palette.primary.main}`,
                }
                : {
                    border: `none`,
                }),
        },
        dropzone: {
            border: `2px dashed ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.background.default,
            height: theme.spacing(10),
            outline: "none",
        },
        dndContainer: {
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 900,
        },
        childrenContainer: {
            position: "absolute",
            width: "100%",
            zIndex: isDraggingAboveChildren ? 800 : 1000,
        },
    }
    const [files, setFiles] = useState<UploadableFile[]>([]);
    const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
        setIsDragging(false);
        setIsDraggingAboveChildren(false);
        const mappedAcc = accFiles.map((file) => ({
            file,
            errors: [],
            id: getNewId(),
        }));

        const mappedRej = rejFiles.map((r) => ({...r, id: getNewId()}));
        setFiles((_) => [...mappedAcc, ...mappedRej]);
    }, []);
    const onDragOver = useCallback(() => {
        setIsDragging(true);
    }, []);
    const onDragLeave = useCallback(() => {
        setIsDragging(false);
    }, []);


    //adding dragging event listener to the children components to shift the  z-index of the uploader when dragging files into the react app.
    const onDragOverChildren = useCallback(() => {
        setIsDraggingAboveChildren(true);
        scrollToTop();
    }, []);
    const onDropChildren = useCallback(() => {
        setIsDraggingAboveChildren(false);
    }, []);
    window.addEventListener("dragover", onDragOverChildren);
    window.addEventListener("drop", onDropChildren);
    //--------------------------------------------------------------------------------------------------------------------------------------

    useEffect(() => {
        const getUploadedImages = async (files: UploadableFile[]) => {
            //if the file is a pdf usePdf2imgs hook to convert it to an array of images:
            //the conversion is async process so we need to wait until all pdfs are converted
            const nestedUploadableImages = await Promise.all(
                files.map(async (file) =>
                    file.file.name.toLowerCase().includes("pdf")
                        ? await convertPdf2UploadableImages(file.file)
                        : file
                )
            );
            //then spread the arrays of images resulted from pdf conversion into the uploadableImages array
            //to avoid having nested arrays of images
            const uploadableImages: UploadableFile[] = [];
            nestedUploadableImages.forEach((ele) =>
                Array.isArray(ele)
                    ? ele.forEach((childrenEle) => uploadableImages.push(childrenEle))
                    : uploadableImages.push(ele as UploadableFile)
            );

            setUploadedImageProjectId(currentProjectId);
            setUploadedImageFiles(uploadableImages);
        };

        getUploadedImages(files);
    }, [files]);

    const {open, getRootProps, getInputProps} = useDropzone({
        multiple: true,
        noClick: true,
        onDrop,
        onDragOver,
        onDragLeave,
        accept: ["image/*, .pdf"],
        maxSize: 10 * 1024 * 1024, //10MB
    });

    return (

        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: "100%",
                width: "100%",

            }}
            {...getRootProps({...classes.listBarDropzone,})}
        >
            <input {...getInputProps()}/>
            {isDragging && (
                <Box
                    display="flex"
                    justifyContent="center"
                    width="inherit"
                    sx={{
                        mb: "10px",
                        position: "absolute",

                    }}
                >
                    <ChipWrapper label={"Drag 'n' drop the images here!"}/>
                </Box>)
            }

            <Box display="flex" justifyContent="flex-end" width="inherit">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={open}
                    disabled={false}
                >
                    Upload Images
                </Button>
            </Box>
            <Box sx={{width: "100%", height: "100%"}}>{children}</Box>

        </Box>


    );
}
