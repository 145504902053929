import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Paper, Typography} from "@mui/material";
import {useMutation} from "react-query";
import {API} from "@/utils/api";
import {useRecordWebcam} from 'react-record-webcam'
import useLivenessMutation from "@/hooks/api/LivenessDetection/useLivenessMutation";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import useLivenessQuery from "@/hooks/api/LivenessDetection/useLivenessQuery";

const classes = {
    boxWrapper: {
        mt: '50px',
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
    },
    pageContent: {
        margin: 2,
        padding: 2,
        display: "flex",
        width: "80%",
        maxWidth: "600px",
        height: '500px',
        maxHeight: '500px',
        overflow: 'auto',
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",

    },

    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        maxHeight: '90%',

    },
    stepBox: {
        display: 'flex',
        justifyContent: 'center',
        height: '70%',
        margin: 4
    }
}

type livenessDataType = { isValid: boolean, uid: string, required_blinks: number, required_duration: null }

type currentStateType = "idle" | "initialized" | "opened" | "recording" | "uploading" | "processing" | "data"

function LivenessDetection() {
    const recordWebcam = useRecordWebcam({frameRate: 20, aspectRatio: -1});
    const steps = ["Upload", "Result"]
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const [activeStep, setActiveStep] = React.useState(0);
    const [livenessData, setLivenessData] = useState<livenessDataType>({} as livenessDataType);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [currentState, setCurrentState] = useState<currentStateType>("idle");
    const [itemRefetchInterval, setItemRefetchInterval] = useState(0);
    const {mutateAsync: uploadImagesAsync} = useLivenessMutation(setUploadProgress);
    const {data: matchingQueryData} = useLivenessQuery(isLoggedIn, livenessData?.uid, itemRefetchInterval);
    const {mutateAsync: initializeLiveness} = useMutation((data: FormData) => {
        return API.initializeLiveness(data, {})
    })


    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if (recordWebcam.status === "OPEN") {
            recordWebcam.start()
            setCountDown(livenessData.required_duration ?? 0)
            setTimeout(() => {
                recordWebcam.stop()
            }, 1000 * Number(livenessData.required_duration))
        } else if (recordWebcam.status === "PREVIEW") {
            recordWebcam.getRecording().then((blob) => uploadFormData(blob, livenessData.uid));
        } else if (recordWebcam.status === "RECORDING") {
            setCurrentState('recording')
        }
    }, [recordWebcam.status]);
    useEffect(() => {
        if (livenessData) {
            // alert(JSON.stringify(livenessData))
        }
    }, [livenessData]);
    useEffect(() => {
        if (matchingQueryData && matchingQueryData?.detected_blinks !== null) {
            setItemRefetchInterval(0)
            setCurrentState('data')
        }
    }, [matchingQueryData]);
    useEffect(() => {
        countDown > 0 && setTimeout(() => {
            setCountDown(prevState => prevState - 1)
        }, 1000)
    }, [countDown]);


    function uploadFormData(file: Blob | null, uid: string) {
        if (file) {
            setCurrentState('uploading')
            const formData = new FormData()
            formData.append("uid", uid)
            formData.append("video", file)
            uploadImagesAsync(formData, {
                onSuccess: data => {
                    if (data) {
                        setCurrentState('processing')
                        setItemRefetchInterval(1000)
                    }

                }
            });

        }

    }

    function initialize() {
        initializeLiveness(new FormData(), {
            onSuccess: (data) => {
                setCurrentState('initialized')
                setLivenessData({
                    isValid: true,
                    uid: data.uid,
                    required_blinks: data.required_blinks,
                    required_duration: data.required_duration
                })
            }
        })
    }

    function handleStartCam() {
        setCurrentState('opened')
        recordWebcam.open()
    }

    function handleReset() {
        setLivenessData({} as livenessDataType)
        setCurrentState("idle")
    }

    return (
        <>
            <Box sx={classes.boxWrapper}>
                <Paper sx={classes.pageContent}>
                    <Box sx={classes.mainBox}>

                        <Box sx={{display: 'flex', width: '100%', mb: 3}}>
                            <Typography variant="h6"> Required Blinks: {livenessData.required_blinks}</Typography>

                            <Box sx={{flex: '1 1 auto'}}/>
                            <Button disabled={livenessData.isValid} variant="contained"
                                    onClick={initialize}> Initialize </Button>
                        </Box>


                        <Box sx={{
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',

                        }}>
                            <Box sx={{display: 'flex', width: '100%'}}>
                                <Button variant="contained" disabled={currentState !== "initialized"}
                                        onClick={handleStartCam}>{countDown ? countDown : "Start Recording"}</Button>
                                <Box sx={{flex: '1 1 auto'}}/>
                                <Button variant="contained" disabled={currentState !== "recording"}
                                        onClick={() => recordWebcam.stop()}>Stop Recording</Button>

                            </Box>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                maxHeight: '300px',
                                border: "2px solid black",
                                mt: 3,
                            }}>
                                {currentState === "uploading" || currentState === "processing" ?
                                    <CircularProgress/>
                                    :
                                    currentState === "data" ?
                                        <Typography
                                            variant="h6"
                                            color={matchingQueryData?.detected_blinks === livenessData.required_blinks ? 'green' : 'error'}
                                        > Detected Blinks: {matchingQueryData?.detected_blinks}</Typography>
                                        :
                                        <video width='100%'
                                               style={{opacity: currentState === "recording" ? '100%' : '0%'}}
                                               ref={recordWebcam.webcamRef} autoPlay muted/>

                                }
                            </Box>
                            <Box sx={{display: 'flex', mt: 3, width: '100%'}}>
                                <Box sx={{flex: '1 1 auto'}}/>
                                <Button variant="contained" disabled={!livenessData?.isValid}
                                        onClick={handleReset}>Reset</Button>
                            </Box>

                        </Box>


                    </Box>

                </Paper>
            </Box>
        </>
    );
}

export default LivenessDetection;