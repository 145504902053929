import {useState} from "react";
import {useQuery, useQueryClient} from "react-query";
import {API} from "@/utils/api";

import {useDispatch} from "react-redux";
import {AppDispatch} from "@/redux/store";
import uploadingImagesSlice from "@/redux/reducers/uploadingImages";

const useImageResultsQuery = (
    uploadingImageId: string,
    resultId: string | undefined,
    projectId: number
) => {
    const dispatch: AppDispatch = useDispatch();
    const {removeUploaingImage} = uploadingImagesSlice.actions;

    const queryClient = useQueryClient();

    const [imageResultsRefetchInterval, setImageResultsRefetchInterval] =
        useState(2000);
    return useQuery(
        ["imageResult", resultId],
        () => API.fetchImageResults(resultId ?? ""),
        {
            enabled: resultId !== "NON" || resultId !== undefined,
            refetchInterval: imageResultsRefetchInterval,
            onSuccess: (imageResults) => {
                if (imageResults?.completed) {
                    //Stop refetching
                    setImageResultsRefetchInterval(0);
                    //Invalidate Docs to refetch the updated list
                    queryClient.invalidateQueries(["Docs", projectId]);
                    //Remove the image from the uploading phase (floating window) to  Docs List
                    dispatch(removeUploaingImage(uploadingImageId));
                }
            },
        }
    );
};

export default useImageResultsQuery;
