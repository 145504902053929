import {useField, useFormikContext} from "formik";
import {FunctionMetaDataType} from "@/srcTypes/hooksTypes";

//This hook is used in multiple components in the StagesScreen when dealing with stage
//FunctionList functionalities --> add, delete, reset, etc..
//to avoid declaring the same functions and use formik custom login many times

//These functions have common structuer, as they all take the old state of the function list
//and return the new mutated one

const useStageFunctionList = () => {
    const [funcList] = useField("func_list");
    const [isFuncList] = useField("is_func_list");
    const {setFieldValue} = useFormikContext();

    const addFuncToFuncList = (
        funcListValue: FunctionMetaDataType[],
        newFunctionMetaData: FunctionMetaDataType
    ) => {
        const newFuncList = [...funcListValue, newFunctionMetaData];
        setFieldValue(funcList.name, JSON.stringify({func_list: newFuncList}));
        return newFuncList;
    };
    const updateFuncInFuncList = (
        funcListValue: FunctionMetaDataType[],
        newFunctionMetaData: FunctionMetaDataType,
        funcOrder: number
    ) => {
        const newFuncList = funcListValue.map((func, funcIndex) =>
            funcIndex === funcOrder ? newFunctionMetaData : func
        );
        setFieldValue(funcList.name, JSON.stringify({func_list: newFuncList}));
        return newFuncList;
    };
    const deleteFuncFromFuncList = (
        funcListValue: FunctionMetaDataType[],
        oldFuncOrder: number
    ) => {
        const newFuncList = funcListValue.filter(
            (func, funcOrder) => funcOrder !== oldFuncOrder
        );
        setFieldValue(funcList.name, JSON.stringify({func_list: newFuncList}));
        return newFuncList;
    };
    const resetFuncList = (funcListValue: FunctionMetaDataType[]) => {
        setFieldValue(funcList.name, JSON.stringify({func_list: []}));
        return [];
    };
    const setIsFuncList = (isFuncListValue: boolean) => {
        setFieldValue(isFuncList.name, isFuncListValue);
        return isFuncListValue;
    };

    return {
        addFuncToFuncList,
        updateFuncInFuncList,
        deleteFuncFromFuncList,
        resetFuncList,
        setIsFuncList,
    };
};

export default useStageFunctionList;
