import {Box, IconButton, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {styled} from "@mui/system";


const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: '#f5f5f9',

    fontSize: '14px',
  },
}));

const TooltipWrapper = ({children, message,}: { children: React.ReactNode; message: string; }) => {

    return (
        <Box display="flex" justifyContent="flex-start" alignItems="center">
            {children}
            <CustomTooltip title={message} enterTouchDelay={10}>
                <IconButton>
                    <InfoIcon fontSize="small"/>
                </IconButton>
            </CustomTooltip>
        </Box>
    );
};

export default TooltipWrapper;
