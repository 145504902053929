import React, { ReactNode } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {CircularProgress } from "@mui/material";

import { CreateStageResponse } from "@/srcTypes/apiTypes";

import useCreateStageMutation from "@/hooks/api/Stages/useCreateStageMutation";

import useStageFormToFormData from "@/hooks/Stages/useStageFormToFormData";
import useStageJsonToStageForm from "@/hooks/Stages/useStageJsonToStageForm";
import {Box} from "@mui/material";

const classes = {
  contextMenuContainer: {
    cursor: "context-menu",
    width: "100%",
    display: "contents",
  },
}

const initialContextMenuState = {
  mouseX: null,
  mouseY: null,
};

type RenderCopyMenuItemProps = {
  copiedStage: CreateStageResponse | undefined;
  handleClose: () => void;
};
const RenderCopyMenuItem = ({
  copiedStage,
  handleClose,
}: RenderCopyMenuItemProps) => (
  <MenuItem
    disabled={copiedStage === undefined}
    onClick={(e) => {
      if (copiedStage){
        localStorage.setItem("copiedData", JSON.stringify(copiedStage));
      }

      handleClose();
      e.stopPropagation()

    }}
  >
    Copy Stage
  </MenuItem>
);

type RenderPasteMenuItemProps = {
  handleClose: () => void;
  newSeqNo: number;
  filterId: number;
};
const RenderPasteMenuItem = ({
  handleClose,
  newSeqNo,
  filterId,
}: RenderPasteMenuItemProps) => {
  //In order to make a copy (a create stage request to another stage) we need to
  //obtain the stage's formData from the copied JSON in the clipboard: we will use two of the custom hooks
  //that reshape the stage data as following:
  // 1st: parse the stringified JSON.
  // 2nd: useStageJsonToStageForm
  // 3rd: useStageFormToFormData

  const {
    status: createStageMutationStatus,
    mutateAsync: createStageMutateAsync,
  } = useCreateStageMutation();

  const handleRequestCreation = async (formData: FormData) => {
    try {
      await createStageMutateAsync(formData);
      handleClose();
    } catch (error) {
      console.log(error, "Error!");
    }
  };

  const [convertStageFormToFormData] = useStageFormToFormData();
  const [convertStageJsonToStageForm] = useStageJsonToStageForm();

  const handlePastingFromLocalStorage = (copiedStageText: string) => {
    //1st step:
    let copiedStage: CreateStageResponse = JSON.parse(copiedStageText);
    //overriding with the new filterId:
    copiedStage = { ...copiedStage, filter: filterId };
    //2nd step:
    //when isDuplicate === true: (second argument) that indicates that the sequence_number will overridden by the newSeqNo
    const copiedStageForm = convertStageJsonToStageForm(
      copiedStage,
      true,
      newSeqNo
    );

    //3rd step:
    const copiedStageFormData = convertStageFormToFormData(copiedStageForm);
    //pasting the copy:
    handleRequestCreation(copiedStageFormData);
  };

  return (
    <MenuItem
      disabled={localStorage.getItem("copiedData") === null ? true : false}
      onClick={(e) => {
        handlePastingFromLocalStorage(
          localStorage.getItem("copiedData") ?? "{}"
        );
        localStorage.removeItem("copiedData");
        e.stopPropagation()
      }}
    >
      {createStageMutationStatus === "loading" ? (
        <>
          Pasting Stage...
          <CircularProgress style={{ margin: "0px 8px" }} size={20} />
        </>
      ) : (
        <>Paste Stage</>
      )}
    </MenuItem>
  );
};

type Props = {
  children: ReactNode;
  copiedStage?: CreateStageResponse | undefined;
  newSeqNo?: number;
  filterId?: number;
};
export default function StageContextMenu({
  children,
  copiedStage,
  newSeqNo = 0,
  filterId = -1,
}: Props) {

  const [contextMenuState, setContextMenuState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialContextMenuState);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextMenuState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setContextMenuState(initialContextMenuState);

  };

  return (
    <Box onContextMenu={handleClick} sx={classes.contextMenuContainer}>
      {children}
      <Menu
        keepMounted
        open={contextMenuState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuState.mouseY !== null && contextMenuState.mouseX !== null
            ? { top: contextMenuState.mouseY, left: contextMenuState.mouseX }
            : undefined
        }
      >
        <RenderCopyMenuItem
          copiedStage={copiedStage}
          handleClose={handleClose}
        />

        <RenderPasteMenuItem
          handleClose={handleClose}
          newSeqNo={newSeqNo}
          filterId={filterId}
        />
      </Menu>
    </Box>
  );
}
