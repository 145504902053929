import {UploadableFile} from "@/srcTypes/InputFormTypes";

// @ts-ignore
import * as PDFJS from "pdfjs-dist/build/pdf";

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

export default function usePdf2imgs() {
    const readFileData = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const convertImags2UploadableImags = (images: any) => {
        let counter = 0;
        const uploadableImags: UploadableFile[] = images.map((image: any) => ({
            id: ++counter,
            file: image,
            errors: [],
            url: "",
        }));
        return uploadableImags;
    };

    const asyncCanvasToBlob = (canvas: HTMLCanvasElement) => {
        return new Promise(function (resolve: (blob: Blob | null) => void) {
            canvas.toBlob(resolve);
        });
    };

    const convertPdf2Images = async (file: File) => {
        file = new File([file], file.name.replaceAll(".PDF", ".pdf"));
        //Changing DPI to 300
        document.addEventListener("webviewerloaded", function () {
            PDFJS.PDFViewerApplicationOptions.set("printResolution", 300);
        });

        const images: any = [];
        const data = await readFileData(file);
        const pdf = await PDFJS.getDocument(data as any).promise;
        const canvas = document.createElement("canvas");
        const randomNumber = Math.floor(Math.random() * 10000);
        for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);

            const scale = 2;
            const viewport = page.getViewport({scale: scale});
            // Support HiDPI-screens.
            const outputScale = window.devicePixelRatio || 1;
            const context = canvas.getContext("2d");

            canvas.width = Math.floor(viewport.width * outputScale);
            canvas.height = Math.floor(viewport.height * outputScale);
            canvas.style.width = Math.floor(viewport.width) + "px";
            canvas.style.height = Math.floor(viewport.height) + "px";

            const transform =
                outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            await page.render({
                canvasContext: context ?? {},
                transform: transform,
                viewport: viewport,
            }).promise;

            const blob = await asyncCanvasToBlob(canvas);
            const imageFile = new File(
                [blob ?? ({} as BlobPart)],
                `pdf_image_${randomNumber}_${i}.jpg`,
                {
                    type: "image/jpeg",
                }
            );
            images.push(imageFile);
        }
        canvas.remove();
        return images;
    };

    const convertPdf2ImagesSrc = async (file: File) => {
        const imagesFiles: File[] = await convertPdf2Images(file);
        const imagesSrc = await loadImagesSrcFromFiles(imagesFiles);
        return imagesSrc;
    };

    const loadImagesSrcFromFiles = async (files: File[]) => {
        const filePromises = files.map((file) => {
            // Return a promise per file
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = async () => {
                    try {
                        // Resolve the promise with the response value
                        resolve(reader?.result?.toString() || "");
                    } catch (err) {
                        reject(err);
                    }
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        });

        return Promise.all(filePromises);
    };

    //param: file -> the input file (e.g. event.target.files[0])
    //return: images -> an array of images encoded in base64
    const convertPdf2UploadableImages = async (file: File) => {
        const images = await convertPdf2Images(file);
        return convertImags2UploadableImags(images);
    };

    return {
        convertPdf2UploadableImages,
        convertPdf2Images,
        convertPdf2ImagesSrc,
        loadImagesSrcFromFiles,
    };
}
