import {useEffect, useRef, useState} from "react";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import useOneProjectQuery from "@/hooks/api/Projects/useOneProjectQuery";
import useDocsQuery from "@/hooks/api/Documents/useDocsQuery";

import {getDocName} from "@/utils/docHelpers";

import {MergerSelectedDocType} from "@/srcTypes/customTypes";
import TableWrapper from "@/components/TableWrapper";
import {MRT_ColumnDef, MRT_TableInstance} from "material-react-table";
import {Button} from "@mui/material";


const DocsList = ({
                      projectId,
                      setCheckedDocsList,
                  }: {
    projectId: number;
    setCheckedDocsList: (checkedDocsList: MergerSelectedDocType[]) => void;
}) => {

    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;

    const {data: selectedProjectData} = useOneProjectQuery(
        isLoggedIn,
        Number(projectId) ?? -1
    );

    const {data: docsData} = useDocsQuery(isLoggedIn, Number(projectId) ?? -1);

    type TableDataType = {
        name: string;
        id: number;
        image: string;
        uploadedAt: Date;
        tableData: {
            checked: boolean;
        };
    };

    const tableInstanceRef = useRef<MRT_TableInstance<TableDataType>>(null);

    const [selectedRows, setSelectedRows] = useState({});

    useEffect(() => {
        // console.log(tableInstanceRef?.current?.getSelectedRowModel().flatRows)
        const selectedRows = tableInstanceRef?.current?.getSelectedRowModel().flatRows
        const newCheckedDocsList: MergerSelectedDocType[] = [];
        if (docsData) {

            selectedRows?.forEach((selectedRow) => {
                newCheckedDocsList.push({
                    id: Number(selectedRow.original.id),
                    name: selectedRow.original.name,
                    uid: docsData[selectedRow.original.id].uid,
                });
            })
        }

        setCheckedDocsList(newCheckedDocsList)
    }, [selectedRows]);

    const [tableData, setTableData] = useState<TableDataType[]>([]);

    useEffect(() => {
        //on changing the selected project
        if (docsData !== undefined && docsData.length > 0) {
            setTableData(
                docsData
                    ?.filter((doc) => (doc.completed ? true : false))
                    .map((doc, docIndex) => ({
                        id: docIndex,
                        uploadedAt: doc.created_at,
                        name: `${getDocName(doc.image ?? "")}`,
                        image: doc.image ?? "",
                        tableData: {checked: false},
                    }))
            );
        } else {
            setTableData([]);
        }
    }, [projectId, docsData]);

    const columns: MRT_ColumnDef<TableDataType>[] = [
        {
            accessorKey: 'id', //simple recommended way to define a column
            header: 'ID',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
            // Cell: ({cell, row}) => overlayContextMenu(row.original.id ?? -1, cell.getValue<string>())
        },
        {

            accessorKey: 'name', //simple recommended way to define a column
            header: 'Name',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},

            // Cell: ({cell, row}) => overlayContextMenu(row.original.id ?? -1, cell.getValue<string>())
        },
        {

            accessorKey: 'uploadedAt', //simple recommended way to define a column
            header: 'Uploading Time',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},

            // Cell: ({cell, row}) => overlayContextMenu(row.original.id ?? -1, cell.getValue<string>())
        },

    ]

    return (
        <>
            <div style={{maxWidth: "100%"}}>
                <TableWrapper
                    columns={columns}
                    data={tableData}
                    enableRowSelection={true}
                    onRowSelectionChange={setSelectedRows}
                    rowSelection={selectedRows}
                    tableInstanceRef={tableInstanceRef}
                />

            </div>
        </>
    );
};

export default DocsList;
