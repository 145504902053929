import React from "react";

import Grid from "@mui/material/Grid";
import {useField, useFormikContext} from "formik";

import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";
import SelectWrapper from "@/components/formComponents/SelectWrapper";
import SliderWrapper from "@/components/formComponents/SliderWrapper";
import CheckboxWrapper from "@/components/formComponents/CheckboxWrapper";

import AccordionWrapper from "./AccordionWrapper";

enum ClearEnum {
    CLEAR_START_BY = 0,
    CLEAR_MATCH_UPTO,
}

const startbyCriteria = {
    "stage_input": "Stage Input",
    "prev_stage_output": "Previous Stage Output",
    "keyword": "Fuzzy Keywords",
    "email": "Email Address",
    "phone": "Phone Number",
    "date": "Date",
    "time": "Time",
    "char_number": "Character Position",
    "word_number": "Word Position",
    "line_number": "Line Position",
    "column_number": "Column Position",
    "regex": "Regex Pattern",
}

const matchUptoCriteria = {
    "current_matching": "Current Matching",
    "n_chr_after": "[N] Characters Forward",
    "n_chr_before": "[N] Characters Backward",
    "n_wrd_after": "[N] Words Forward",
    "n_wrd_before": "[N] Words Backward",
    "n_col_after": "[N] Columns Forward",
    "n_col_before": "[N] Columns Backward",
    "n_lin_after": "[N] Lines Forward",
    "n_lin_before": "[N] Lines Backward",
    "start_of_line": "The Beginning of the Line",
    "end_of_line": "The End of the Line",
    "current_line": "The Whole Line",
    "end_of_document": "The End of the Document",
    "termination_keyword": "Fuzzy Keyword",
    "termination_regex": "Regex Pattern",
}

const noValueStartbyCriteria = [
    "stage_input",
    "prev_stage_output",
    "email",
    "phone",
    "date",
    "time",
    "",
];
const nReturnsStartbyCriteria = [
    "regex",
    "keyword",
    "email",
    "phone",
    "date",
    "time",
];
const noValueMatchUptoCriteria = [
    "current_matching",
    "start_of_line",
    "end_of_line",
    "current_line",
    "end_of_document",
    "",
];



type Props = {
    expanded: false | string;
    setExpanded: (expanded: false | string) => void;
};
export default function StageCriteria({expanded, setExpanded}: Props) {


    const {setFieldValue, values: fieldsValues} = useFormikContext();

    const [selectedStartBy] = useField("startby_criteria");
    const [selectedMatchUpto] = useField("match_upto_criteria");

    //Clear all keyword args values on changing the stage funciton
    const clearAllCriteriaFields = (clearEnumValue: ClearEnum) => {
        let targetFeildName = "";
        let targetCheckboxName = "";

        if (clearEnumValue === ClearEnum.CLEAR_START_BY) {
            targetFeildName = "startby_criteria_value";
            targetCheckboxName = "include_criteria_value";
        } else if (clearEnumValue === ClearEnum.CLEAR_MATCH_UPTO) {
            targetFeildName = "match_upto_value";
            targetCheckboxName = "include_match_upto_value";
        }

        //clear text field
        //check if the field is not empty before clearing it --> expensive operation!
        if ((fieldsValues as any)[targetFeildName] !== "")
            setFieldValue(targetFeildName, "");

        // clear checkboxe
        //check if the field is not false before clearing it --> expensive operation!
        if ((fieldsValues as any)[targetCheckboxName] !== false)
            setFieldValue(targetCheckboxName, false);
    };
    return (
        <React.Fragment>
            <AccordionWrapper
                title={"Stage Criteria"}
                expanded={expanded}
                setExpanded={setExpanded}
            >
                <Grid container spacing={2}>
                    {/*Select Part */}
                    <Grid item xs={12} md={6}>
                        <SelectWrapper
                            name="startby_criteria"
                            label="Startby Criteria"
                            options={startbyCriteria}
                            handleChange={(event) => {
                                //Clear kw args values on changing stage function (clear cache)
                                clearAllCriteriaFields(ClearEnum.CLEAR_START_BY);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SelectWrapper
                            name="match_upto_criteria"
                            label="Match Upto Criteria"
                            options={matchUptoCriteria}
                            handleChange={(event) => {
                                //Clear kw args values on changing stage function (clear cache)
                                clearAllCriteriaFields(ClearEnum.CLEAR_MATCH_UPTO);
                            }}
                        />
                    </Grid>

                    {/*Value Part */}
                    {!noValueStartbyCriteria.includes(selectedStartBy.value) ? (
                        <>
                            <Grid item xs={7} md={3}>
                                <TextfieldWrapper
                                    name="startby_criteria_value"
                                    label="Startby Criteria Value"
                                />
                            </Grid>
                            <Grid item xs={5} md={3}>
                                <CheckboxWrapper
                                    name="include_criteria_value"
                                    legend=""
                                    label="Include the value"
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={6}/>
                    )}

                    {!noValueMatchUptoCriteria.includes(selectedMatchUpto.value) ? (
                        <>
                            <Grid item xs={7} md={3}>
                                <TextfieldWrapper
                                    name="match_upto_value"
                                    label="Match Upto Value"
                                />
                            </Grid>

                            <Grid item xs={5} md={3}>
                                <CheckboxWrapper
                                    name="include_match_upto_value"
                                    legend=""
                                    label="Include the value"
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={6}/>
                    )}

                    {/*Threshold Part */}
                    {selectedStartBy.value === "keyword" ? (
                        <Grid item xs={12} md={6}>
                            <SliderWrapper
                                min={0.0}
                                max={100.0}
                                step={5.0}
                                defaultValue={90.0}
                                name="stage_matching_threshold"
                                label="Matching Threshold"
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={6}/>
                    )}

                    {selectedMatchUpto.value === "termination_keyword" ? (
                        <Grid item xs={12} md={6}>
                            <SliderWrapper
                                min={0.0}
                                max={100.0}
                                step={5.0}
                                defaultValue={90.0}
                                name="stage_match_upto_threshold"
                                label="Match Upto Threshold"
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={6}/>
                    )}

                    {/*n matches Part */}
                    {nReturnsStartbyCriteria.includes(selectedStartBy.value) ? (
                        <Grid item xs={12}>
                            <TextfieldWrapper
                                name="return_n_matches"
                                label="Return N number of Matches"
                                type="number"
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            </AccordionWrapper>
        </React.Fragment>
    );
}
