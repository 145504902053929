import {useContext, useState} from "react";
import {Box, CircularProgress, Paper, Typography,} from "@mui/material";
import MainContext from "@/contexts/MainContext";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import {useParams} from "react-router-dom";

import {MultipleFileUploadField} from "@/components/formComponents/upload/MultipleFileUploadField";

import useDocsQuery from "@/hooks/api/Documents/useDocsQuery";

import useExportExcelSheet from "@/hooks/global/useExportExcelSheet";

import DocsList from "./components/DocsList";
import DocsInfoBar from "./components/DocsInfoBar/DocsInfoBar";
import DocsOutputs from "./components/DocsOutputs";

import {UploadableFile} from "@/srcTypes/InputFormTypes";

type ExtractedInfoType = { [x: string]: string | any };

const classes = {
    docsContainer: {
        pointeEvents: "none",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: 8,
        flexGrow: 1,
        // height: "100%"
    },

    docsListBarContainer: {

        position: "relative",
        width: "100%",
        flexGrow: 1,
    },
    pageContent: {
        padding: 4,
        width: "50%",
        height: "20vh",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        flexDirection: "column",
    },
    boxWrapper: {
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        height: "50vh",

        width: "100%",
    },
    landingPageButton: {
        height: "50%",
        fontSize: "25px",
    },
}

type Props = {
    setUploadedImageFiles: (
        uploadedImageFiles: UploadableFile[] | undefined
    ) => void;

    setUploadedImageProjectId: (uploadedImageProjectId: number) => void;
};

const Docs = ({setUploadedImageFiles, setUploadedImageProjectId}: Props) => {


    //decoupling children components from the parent
    const {projectId} = useParams();

    const {isInfoBarOpen, setIsInfoBarOpen} = useContext(MainContext);

    const handleExportExcelSheet = useExportExcelSheet();

    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;

    const [openDocsOutputModal, setOpenDocsOutputModal] = useState(false);

    const handleOnClickItem = () => {
        setOpenDocsOutputModal(true);
        setIsInfoBarOpen(false);
    };

    const {data: docsData, status: docsQueryStatus} = useDocsQuery(
        isLoggedIn,
        Number(projectId) ?? -1
    );

    return (
        <>
            <Box sx={classes.docsContainer}>

                <Box sx={classes.docsListBarContainer}>

                    <MultipleFileUploadField
                        name="files"
                        setUploadedImageFiles={setUploadedImageFiles}
                        setUploadedImageProjectId={setUploadedImageProjectId}
                        currentProjectId={Number(projectId ?? -1)}
                    >
                        {(docsData?.length ?? 0) === 0 &&
                        docsQueryStatus === "loading" ? (
                            <Box sx={classes.boxWrapper}>
                                <CircularProgress size={100}/>
                            </Box>
                        ) : (
                            <>
                                {/*Check if there is no Docs to display Loader or error message */}
                                {(docsData?.length ?? 0) === 0 &&
                                docsQueryStatus === "success" ?
                                    (
                                        <Box sx={classes.boxWrapper}>
                                            <Paper sx={classes.pageContent}>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="h3"
                                                    align="center"
                                                >
                                                    {/* No Documents available for this Project, you can
                                                        upload new Documents by drag and drop or by upload
                                                            button */}
                                                    Upload your Documents
                                                </Typography>
                                            </Paper>
                                        </Box>
                                    ) :
                                    (
                                        <DocsList
                                            setOpenInfoBar={setIsInfoBarOpen}
                                            handleOnClickItem={handleOnClickItem}
                                            handleCustomFuncOnCheckedDocs={(checkedDocsData) => {
                                                handleExportExcelSheet(
                                                    checkedDocsData.map((doc) => {
                                                        return (
                                                            (doc?.extracted_info as ExtractedInfoType)
                                                                ?.Results ?? {}
                                                        );
                                                    })
                                                );
                                            }}
                                        />
                                    )}
                            </>
                        )}
                    </MultipleFileUploadField>
                </Box>
                <DocsInfoBar
                    openInfoBar={isInfoBarOpen}
                    setOpenInfoBar={setIsInfoBarOpen}
                />
                <DocsOutputs
                    open={openDocsOutputModal}
                    setOpen={setOpenDocsOutputModal}
                />

            </Box>
        </>
    );
};

export default Docs;
