import {useMutation} from "react-query";
import {API} from "@/utils/api";
import {uploadProgressType} from "@/screens/BatchAutomation/BatchAutomation"

const useAutomationMutation = (uploadProgress: uploadProgressType,
                               setUploadProgress: React.Dispatch<React.SetStateAction<uploadProgressType>>) => {

    type propType = { fileName: string, automationData: FormData }

    return useMutation(({fileName, automationData}: propType) => API.automation(automationData,
            {

                onUploadProgress: (ev) => {
                    setUploadProgress({
                        ...uploadProgress,
                        [fileName]: Math.ceil((ev.loaded / ev.total) * 100),

                    })
                },


            })
        ,
        {}
    )
        ;
};

export default useAutomationMutation;
