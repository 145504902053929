import {useEffect} from "react";

import {Box, Grid} from "@mui/material";

import {Form, Formik} from "formik";
import * as Yup from "yup";

import {useMutation} from "react-query";
import {getMutationErrorMessage} from "@/utils/getMutationErrorMessage";
import {AxiosError} from "axios";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import userCredentialsSlice from "@/redux/reducers/userCredentials";

import {API} from "@/utils/api";
import {LoginRequest} from "@/srcTypes/apiTypes";

import ButtonWrapper from "@/components/formComponents/ButtonWrapper";
import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";

import SnackBarWrapper from "@/components/SnackBarWrapper";
import ChipWrapper from "@/components/ChipWrapper";
import ModalWrapper from "@/components/ModalWrapper";

const INITIAL_FORM_STATE = {
    username: "",
    password: "",
};

const FORM_VALIDATION = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
});

const createFormData = ({username, password}: LoginRequest) => {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return formData;
};

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export default function Login({open, setOpen}: Props) {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;

    const dispatch: AppDispatch = useDispatch();
    const {setUserCredentials} = userCredentialsSlice.actions;

    const handleCloseLoginModal = () => {
        if (isLoggedIn) setOpen(false);
    };

    const LoginMutation = useMutation((formData: FormData) =>
        API.login(formData, {})
    );

    useEffect(() => {
        if (isLoggedIn) handleCloseLoginModal();
        else setOpen(true)

    }, [isLoggedIn]);

    const handleSubmit = async (formData: FormData, username: string) => {
        try {
            let res = await LoginMutation.mutateAsync(formData);
            localStorage.setItem("authToken", res?.token);
            localStorage.setItem("username", username);
            dispatch(setUserCredentials({username: username, isLoggedIn: true}));
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    return (
        <>
            <ModalWrapper
                open={open}
                handleClose={handleCloseLoginModal}
                disableClose={true}
            >
                <Formik
                    initialValues={{
                        ...INITIAL_FORM_STATE,
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values) => {
                        handleSubmit(createFormData(values), values.username);
                    }}
                >
                    {({isValid, isSubmitting}) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    width="inherit"
                                    sx={{mb: "10px"}}
                                >
                                    <ChipWrapper label="Login"/>
                                </Box>
                                <Grid item xs={12}>
                                    <TextfieldWrapper
                                        name="username"
                                        label="Username"
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextfieldWrapper
                                        type="password"
                                        name="password"
                                        label="Password"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <ButtonWrapper status={LoginMutation.status}>
                                        Login
                                    </ButtonWrapper>

                                    <SnackBarWrapper
                                        status={LoginMutation.status}
                                        message={
                                            LoginMutation.status === "success"
                                                ? "Logged in successfully"
                                                : `Failed to login: ${getMutationErrorMessage(
                                                    LoginMutation.error as AxiosError
                                                )}`
                                        }
                                    />
                                </Grid>
                                {!isLoggedIn && (
                                    <SnackBarWrapper
                                        status={"info"}
                                        message={"Please login first to access this Demo"}
                                    />
                                )}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </ModalWrapper>
        </>
    );
}
