import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";


type Props = {
    title: string;
    children: React.ReactNode;
    expanded: false | string;
    setExpanded: (expanded: false | string) => void;
};
export default function AccordionWrapper({
                                             title,
                                             children,
                                             expanded,
                                             setExpanded,
                                         }: Props) {


    const handleChange =
        (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const theme = useTheme()
    const classes = {
        root: {
            width: "100%",
            margin: "10px 0px",
        },
        heading: {
            fontSize: theme.typography.pxToRem(20),
        },
    }
    return (
        <Box sx={classes.root}>
            <Accordion expanded={expanded === title} onChange={handleChange(title)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={`${title}-content`}
                    id={`${title}-header`}
                >
                    <Typography sx={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </Box>
    );
}
