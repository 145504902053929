import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useUpdateFilterMutation = (filterId: number) => {
    const queryClient = useQueryClient();
    return useMutation(
        (formData: FormData) => API.updateFilter(filterId, formData, {}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("Filters");
            },
        }
    );
};

export default useUpdateFilterMutation;
