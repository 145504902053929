import {useState} from "react";

import {Box, Button, CircularProgress, Grid, IconButton, Paper, Tooltip,} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

import {Form, Formik} from "formik";

import ProjectAndFilterSelector from "./components/ProjectAndFilterSelector";
import MergerContext from "@/contexts/MergerContext";
import MergerTabs from "./components/MergerTabs/MergerTabs";

import {MergeDataType} from "@/srcTypes/apiTypes";
import useMergeMutation from "@/hooks/api/Merger/useMergeMutation";
import useMergeResultsQuery from "@/hooks/api/Merger/useMergeResultsQuery";

import {MergerSelectedDocType} from "@/srcTypes/customTypes";
import {useTheme} from "@mui/material/styles";

const INITIAL_FORM_STATE = {
    base_project: "",
    base_filter: "",
    target_project: "",
    target_filter: "",
    date_from: "2021-05-24T10:30",
    date_to: "2021-06-24T10:30",
    base_task_list: [],
    target_task_list: [],
};


const Merger = () => {
    const theme = useTheme()
    const classes = {
        boxWrapper: {
            mt: "50px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            height: "100%",
        },
        pageContent: {
            margin: 2,
            padding: 2,
            display: "flex",
            borderRadius: "15px",
            backgroundColor: "#f1f4f9",
            overflowWrap: "break-word"
        },
        mergerContainer: {
            width: "95%",
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px 0px 20px",

        },
        selectorsContainer: {
            display: "flex",
            justifyContent: "space-around",
            width: "70%",
        },
        Link: {
            textDecorationLine: "none",
        },
        CardContainedButton: {
            padding: "9px",

            margin: "0px 2px",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.light,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
                color: "white",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "6px",
                backgroundColor: theme.palette.primary.light,
                color: "white",
            },
        },
    }

    const [baseProjectId, setBaseProjectId] = useState<number>(-1);
    const [targetProjectId, setTargetProjectId] = useState<number>(-1);

    const [baseSelectedList, setBaseSelectedList] = useState<MergerSelectedDocType[]>([]);
    const [targetSelectedList, setTargetSelectedList] = useState<MergerSelectedDocType[]>([]);

    const [isDateRange, setIsDateRange] = useState(true);

    const [resultId, setResultId] = useState<undefined | string>(undefined);

    const {status: mergeMutationStatus, mutateAsync: mergeMutateAsync} =
        useMergeMutation();

    const {data: mergeResultsData, status: mergeResultsStatus} =
        useMergeResultsQuery(resultId);

    const getMergeData = (values: any): MergeDataType => {
        //temp vars until fixing some backend issues
        const tempBaseFilterId = 6730;
        const tempTargetFilterId = 6730;
        //------------------------------------------------

        return {
            base_project: baseProjectId,
            target_project: targetProjectId,
            use_filter_name:
                values.base_filter === "" && values.target_filter === "" ? false : true,
            base_filter:
                values.base_filter !== ""
                    ? Number(values.base_filter)
                    : tempBaseFilterId,
            target_filter:
                values.target_filter !== ""
                    ? Number(values.target_filter)
                    : tempTargetFilterId,
            is_date_range: isDateRange,
            date_from: values.date_from,
            date_to: values.date_to,
            base_task_list: baseSelectedList.map((selectedItem) => selectedItem.uid),
            target_task_list: targetSelectedList.map(
                (selectedItem) => selectedItem.uid
            ),
        };
    };
    const handleSubmit = async (mergeData: MergeDataType) => {
        try {
            const res = await mergeMutateAsync(mergeData);
            setResultId(res.uid);
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    return (
        <>
            <MergerContext.Provider value={{}}>
                <Box sx={classes.boxWrapper}>
                    <Paper sx={classes.pageContent}>
                        <Box sx={classes.mergerContainer}>
                            <Formik
                                initialValues={{
                                    ...INITIAL_FORM_STATE,
                                }}
                                onSubmit={(values) => {
                                    handleSubmit(getMergeData(values));
                                }}
                            >
                                {({values, isValid, isSubmitting}) => (
                                    <Form>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <ProjectAndFilterSelector
                                                    mergerChildType="base"
                                                    selectedProjectId={baseProjectId}
                                                    setSelectedProjectId={setBaseProjectId}
                                                    setSelectedList={setBaseSelectedList}
                                                />
                                            </Grid>

                                            <Grid item xs={6}>
                                                <ProjectAndFilterSelector
                                                    mergerChildType="target"
                                                    selectedProjectId={targetProjectId}
                                                    setSelectedProjectId={setTargetProjectId}
                                                    setSelectedList={setTargetSelectedList}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <MergerTabs
                                                    baseProjectId={baseProjectId}
                                                    targetProjectId={targetProjectId}
                                                    setIsDateRange={setIsDateRange}
                                                    baseSelectedList={baseSelectedList}
                                                    setBaseSelectedList={setBaseSelectedList}
                                                    targetSelectedList={targetSelectedList}
                                                    setTargetSelectedList={setTargetSelectedList}
                                                />
                                            </Grid>

                                            <Grid item xs={mergeResultsStatus === "success" ? 10 : 12}>
                                                <Button
                                                    disabled={
                                                        mergeMutationStatus === "loading" ||
                                                        mergeResultsData === "loading" ||
                                                        (!isDateRange && (
                                                                !baseSelectedList.length ||
                                                                !targetSelectedList.length)
                                                        ) ||
                                                        values.base_filter === "" ||
                                                        values.target_filter === "" ||
                                                        values.base_project === "" ||
                                                        values.target_project === ""

                                                    }
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                    }}

                                                    fullWidth
                                                >
                                                    {mergeMutationStatus === "loading" ||
                                                    mergeResultsData === "loading" ? (
                                                        <CircularProgress size={24}/>
                                                    ) : (
                                                        "Merge"
                                                    )}
                                                </Button>
                                            </Grid>
                                            {mergeResultsStatus === "success" && (
                                                <Grid item xs={2}>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={mergeResultsData?.url ?? ""}
                                                        download={"mergeFile.csv"}
                                                        style={classes.Link}
                                                    >
                                                        <Tooltip title="Download">
                                                            <IconButton sx={classes.CardContainedButton}>
                                                                <GetAppIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </a>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Paper>
                </Box>


            </MergerContext.Provider>
        </>
    );
};

export default Merger;
