import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography,} from "@mui/material";

const classes = {
    cardListContent: {
        height: "32vh",
        width: "100%",
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "auto",
        margin: "2px 0px 4px 0px",
    },
    card: {
        backgroundColor: "white",
        display: "flex",
        height: "170px",
        width: "200px",

        margin: "0px 7px",

        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
        },
    },

    selectedCard: {
        backgroundColor: "white",
        display: "flex",
        height: "170px",
        width: "200px",
        margin: "0px 7px",

        transition: "0.2s",
        border: "0.3vw solid  rgba(0,0,0,0.8)",
    },
    cardActionArea: {
        height: "100%",
    },

    cardTitle: {
        fontSize: "1vw",
        fontWeight: "bold",
    },

    cardMedia: {
        objectFit: "contain",
        marginTop: "5px",
        height: "100px",
        width: "200px",
    },
}

export default function ImageListWrapper({
                                             imageList,
                                             selectedImage,
                                             setSelectedImage,
                                         }: {
    imageList: string[];
    selectedImage: string;
    setSelectedImage: (selectedImage: string) => void;
}) {

    return (
        <Grid container sx={classes.cardListContent}>
            {imageList.map((image, imageIndex) => (
                <Grid item>
                    <Card
                        key={imageIndex}
                        sx={
                            image === selectedImage ? classes.selectedCard : classes.card
                        }
                    >
                        <CardActionArea
                            sx={classes.cardActionArea}
                            onClick={() => {
                                setSelectedImage(image);
                            }}
                        >
                            <CardMedia
                                sx={classes.cardMedia}
                                image={image}
                                title=""
                                component="img"
                            />
                            <CardContent>
                                <Typography
                                    align="center"
                                    sx={classes.cardTitle}
                                    gutterBottom
                                >
                                    {imageIndex}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
