import AppBarWrapper from "@/layouts/AppBarWrapper";

import FloatingWindowUploader from "@/screens/Documents/components/uploadingFloatingWindow/FloatingWindowUploader";
import {UploadableFile} from "@/srcTypes/InputFormTypes";

import {useContext} from "react";
import MainContext from "@/contexts/MainContext";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import {Box, useTheme} from "@mui/material";
import NavBarWrapper from "@/layouts/NavBarWrapper";
import BreadcrumbsWrapper from "@/layouts/BreadCrumbsWrapper";

type stylePropsType = {
    isInfoBarOpen: boolean;
    drawerWidth: number;
};


type Props = {
    children?: React.ReactNode;
    uploadedImageFiles: UploadableFile[] | undefined;
    uploadedImageProjectId: number;
};

function Layout({
                    children = <></>,
                    uploadedImageFiles,
                    uploadedImageProjectId,
                }: Props) {
    const {isInfoBarOpen, setIsInfoBarOpen} = useContext(MainContext);

    const {navBarState, infoBarState, appBarState} = useSelector((state: AppState) => state);
    const {infoDrawerWidth} = infoBarState;
    const {appBarHeight} = appBarState
    const classes = {
        childrenWrapper: {
            display: "flex",
            flexDirection: 'column',
            width: "100%",
            height: "100vh",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            overflowY: "auto",
            marginRight: isInfoBarOpen ? infoDrawerWidth : "0px",
        },

        bodyContainer: {
            width: '100%',
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            marginRight: isInfoBarOpen ? infoDrawerWidth : "0px",
        }

    }

    const theme = useTheme()
    return (
        <>
            <AppBarWrapper/>

            {/*Moved the FloatingWindowUploader to the layout to help user navigate the uploading Docs anywhere in the project  */}
            <FloatingWindowUploader
                uploadedImageFiles={uploadedImageFiles}
                uploadedImageProjectId={uploadedImageProjectId}
            />

            <Box sx={{display: 'flex', width: "100%"}}>

                <NavBarWrapper setIsInfoBarOpen={setIsInfoBarOpen}/>

                <Box sx={classes.childrenWrapper}>
                    <>
                        <Box sx={{
                            backgroundColor: "#f2f2f3",
                            display: 'flex',
                            alignItems: 'center',
                            border: "2px solid",
                            borderColor: "#dcdbdb",
                            position: 'relative',
                            top: `${appBarHeight}px`,
                            height: '40px',
                            flexShrink: 0,
                            marginBottom: "10px",
                            width: '100%'
                        }}>
                            <BreadcrumbsWrapper/>
                        </Box>
                        {children}
                    </>

                </Box>

            </Box>


        </>
    );
}

export default Layout;
