import {useEffect} from "react";
import {useParams} from "react-router-dom";

import {useDispatch} from "react-redux";
import {AppDispatch} from "@/redux/store";
import navBarStateSlice from "@/redux/reducers/navBarState";
//This custom hook is used only on the Parent screen of the app
//for setting the global state: navBarState to the current URL accessed by the user
//to use this state to conditionally render the current component by using react-router V6 Outlet component
const useParentRoutingConstructor = () => {
    const {projectId, filterId, stageId} = useParams();

    const dispatch: AppDispatch = useDispatch();
    const {setNavProjectId, setNavFilterId, setNavStageId} =
        navBarStateSlice.actions;

    useEffect(() => {
        //Note that for any number (either 123 or '123') isNaN will be false
        if (isNaN(Number(projectId) ?? "NAN") === false)
            dispatch(setNavProjectId(Number(projectId)));
        else dispatch(setNavProjectId(undefined));
        if (isNaN(Number(filterId) ?? "NAN") === false)
            dispatch(setNavFilterId(Number(filterId)));
        else dispatch(setNavFilterId(undefined));
        if (isNaN(Number(stageId) ?? "NAN") === false)
            dispatch(setNavStageId(Number(stageId)));
        else dispatch(setNavStageId(undefined));
    }, [
        projectId,
        filterId,
        stageId,
        setNavProjectId,
        setNavFilterId,
        setNavStageId,
        dispatch,
    ]);
};

export default useParentRoutingConstructor;
