import {ExtractedInfoType} from "@/srcTypes/hooksTypes";
// @ts-ignore
import * as XLSX from "xlsx";

export default function useExportExcelSheet() {
    return (extractedInfoData: ExtractedInfoType[]) => {
        const workSheet = XLSX.utils.json_to_sheet(extractedInfoData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Extracted Info");
        //Binary string
        XLSX.write(workBook, {bookType: "xlsx", type: "binary"});
        //Download
        XLSX.writeFile(workBook, "Extracted Info.xlsx");
    };
}
