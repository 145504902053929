import React from "react";
import {Box, Grid} from "@mui/material";

import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";

import ChooseOutput from "./components/ChooseOutput";
import OutputModal from "./components/OutputModal";
import ChipWrapper from "@/components/ChipWrapper";
import {useTheme} from "@mui/material/styles";

export const OUTPUT_MODAL_NUMBER = {
    WordOutputModal: 0,
    TableOutputModal: 1,
    TextOutputModal: 2,
    NonOutputModal: 3,
    excelOutputModal: 4,
};


const Outputs = () => {
    const theme = useTheme()
    const classes = {
        pageContent: {
            backgroundColor: "#f1f4f9",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
        },
        boxWrapper: {
            height: "56vh",
            marginTop: "5px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
            justifyContent: "space-around",
        },

        MobilePageContent: {
            padding: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignContent: "center",
            [theme.breakpoints.up("sm")]: {
                width: 450,
            },
            [theme.breakpoints.only("xs")]: {
                width: 300,
            },
        },
        MobileBoxWrapper: {
            width: "90vw",
            margin: "15px 0px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
            justifyContent: "space-around",
        },

        DownloadButton: {
            margin: theme.spacing(1, 0),
        },
        Link: {
            textDecorationLine: "none",
        },
    }

    const {isMatchingBreakingPoint} = useSelector((state: AppState) => state);

    const [chosedOutputType, setChosedOutputType] = React.useState(
        OUTPUT_MODAL_NUMBER.NonOutputModal
    );

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                alignItems="center"
                sx={{}}
            >
                <Box
                    sx={
                        isMatchingBreakingPoint
                            ? classes.boxWrapper
                            : classes.MobileBoxWrapper
                    }
                    flexGrow={1}
                >
                    <Box
                        sx={
                            isMatchingBreakingPoint
                                ? classes.pageContent
                                : classes.MobilePageContent
                        }
                    >
                        <Grid container spacing={4} direction="column">
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <ChipWrapper label="Output Types"/>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <ChooseOutput
                                    matches={isMatchingBreakingPoint}
                                    setChosedOutputType={setChosedOutputType}
                                    handleOpen={handleOpen}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            <OutputModal
                open={open}
                chosedOutputType={chosedOutputType}
                handleClose={handleClose}
                matches={isMatchingBreakingPoint}
            />
        </>
    );
};

export default Outputs;

//result.docx:
// "https://drive.google.com/uc?id=1gzqxHcCxMAi--CqdqnCnOMmVlKwRrXjh"
