import {DocumentViewer} from "react-documents";
import {Box, Paper, TextField} from "@mui/material";
import ReactImageMagnify from "react-image-magnify";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import {OUTPUT_MODAL_NUMBER} from "../Outputs";
import TableOutput from "@/components/TableOutput";
import ModalWrapper from "@/components/ModalWrapper";
import {getTextFromSelectedDoc} from "@/utils/getTextFromSelectedDoc";

const classes = {
    TwoSidesWrapper: {width: "100%", height: "80vh", display: "flex"},
    ImageContainer: {
        padding: 1,
        width: "100%",
        height: "100%",
        borderRadius: "15px",
        backgroundColor: "secondary",
        mt: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        alignContent: "center",
        flexDirection: "column",
    },
    ImageSide: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        marginRight: "10px",
        marginLeft: "10px",
        width: "50%",
        height: "100%",
    },
    OutputSide: {
        flex: "auto",
        width: "50%",
        height: "100% ",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
    },
}

type Props = {
    open: boolean;
    chosedOutputType: number;
    handleClose: () => void;
    matches: boolean;
};
const ModalOutput = ({
                         open,
                         chosedOutputType,
                         handleClose,
                         matches,
                     }: Props) => {

    const {docsState} = useSelector((state: AppState) => state);

    return (
        <>
            <ModalWrapper open={open} handleClose={handleClose} isFullWidth={true}>
                <Box sx={classes.TwoSidesWrapper}>
                    {matches ? (
                        <Box sx={classes.ImageSide}>
                            <Paper sx={classes.ImageContainer}>
                                <ReactImageMagnify
                                    enlargedImagePosition="over"
                                    style={{width: "100%", height: "100%"}}
                                    imageStyle={{
                                        maxHeight: "80vh",
                                        maxWidth: "100%",
                                        minWidth: "40vw",
                                    }}
                                    {...{
                                        smallImage: {
                                            alt: "Input Image",
                                            isFluidWidth: true,
                                            src: docsState.selectedDoc?.image ?? "",
                                        },

                                        largeImage: {
                                            src: docsState.selectedDoc?.image ?? "",
                                            width: 1200,
                                            height: 1800,
                                        },
                                    }}
                                />
                            </Paper>
                        </Box>
                    ) : (
                        ""
                    )}
                    <Box sx={classes.OutputSide}>
                        {chosedOutputType === OUTPUT_MODAL_NUMBER.WordOutputModal ? (
                            <DocumentViewer
                                queryParams="hl=Nl"
                                url={
                                    // "https://drive.google.com/uc?id=1gzqxHcCxMAi--CqdqnCnOMmVlKwRrXjh"
                                    docsState.selectedDoc?.word_file ?? ""
                                }
                                viewer={"office"}
                                overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                            />
                        ) : chosedOutputType === OUTPUT_MODAL_NUMBER.excelOutputModal ? (
                            <DocumentViewer
                                queryParams="hl=Nl"
                                url={
                                    // "https://drive.google.com/uc?id=1gzqxHcCxMAi--CqdqnCnOMmVlKwRrXjh"
                                    docsState.selectedDoc?.sheet_file ?? ""
                                }
                                viewer={"office"}
                                overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                            />
                        ) : chosedOutputType === OUTPUT_MODAL_NUMBER.TableOutputModal ? (
                            <TableOutput
                                extractedInfo={docsState.selectedDoc?.extracted_info ?? {}}
                            />
                        ) : (
                            <TextField
                                inputProps={
                                    {style: {textAlign: "start"}}
                                    // isRTL
                                    //   ? { style: { textAlign: "end" } }
                                    //   : { style: { textAlign: "start" } }
                                }
                                variant="outlined"
                                placeholder="No text detected!"
                                rows={20}
                                maxRows={20}
                                value={getTextFromSelectedDoc(docsState.selectedDoc?.raw_json)}
                                multiline
                                fullWidth
                            />
                        )}
                    </Box>
                </Box>
            </ModalWrapper>
        </>
    );
};

export default ModalOutput;

//result.docx:
// "https://drive.google.com/uc?id=1gzqxHcCxMAi--CqdqnCnOMmVlKwRrXjh"
