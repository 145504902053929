import {useMutation} from "react-query";
import {API} from "@/utils/api";
import {uploadProgressType} from "@/screens/PlateRecognition/PlateRecognition";
import {useState} from "react";

const usePlateMutation = (uploadProgress: uploadProgressType,
                          setUploadProgress: React.Dispatch<React.SetStateAction<uploadProgressType>>) => {

    type propType = { fileName: string, plateData: FormData }
    return useMutation(({fileName, plateData}: propType) => {
            return API.plateRecognition(plateData,
                {
                    onUploadProgress: (ev) => {
                        setUploadProgress({...uploadProgress, [fileName]: Math.ceil((ev.loaded / ev.total) * 100)})
                    },
                })
        }
        ,
        {}
    )
        ;
};

export default usePlateMutation;
