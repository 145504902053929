import StageInfo from "./components/StageInfo";
import StageCriteria from "./components/StageCriteria";
import StageValidation from "./components/StageValidation";
import FunctionList from "./components/stageFunctionality/FunctionList";

type Props = {
    expanded: false | string;
    setExpanded: (expanded: false | string) => void;
    setSubmitFlag: ({
                        submitType,
                        previewType,
                    }: {
        submitType: string;
        previewType: string;
    }) => void;
};

export default function StageAccordion({expanded,setExpanded,setSubmitFlag}: Props) {

    return (
        <>
            <StageInfo expanded={expanded} setExpanded={setExpanded} setSubmitFlag={setSubmitFlag}/>
            <StageCriteria expanded={expanded} setExpanded={setExpanded}/>
            <FunctionList expanded={expanded} setExpanded={setExpanded}/>
            <StageValidation expanded={expanded} setExpanded={setExpanded}/>
        </>
    );
}
