import {ImageDataType} from "@/screens/Stages/components/preview/components/ImageListModal";

//This hook is mainly used when submitting a form using formilk and
//(reshaping & postprocessing) it to formData to send it to the backend.
const useStageFormToFormData = () => {
    const convertStageFormToFormData = (
        stageData: any,
        selectedImageData?: ImageDataType
    ) => {
        let formData = new FormData();

        if (selectedImageData !== undefined)
            formData.append("paragraph", selectedImageData.documentText ?? "");

        formData.append("filter", stageData.filter.toString());
        formData.append("prev_valid", stageData.prev_valid ?? "");
        formData.append("sequence_number", `${stageData.sequence_number}`);
        formData.append("description", stageData.description);
        formData.append("data_source", stageData.data_source ?? "");

        formData.append("startby_criteria", stageData.startby_criteria ?? "");
        //for escaping backslashes: add another backslash for each one
        formData.append(
            "startby_criteria_value",
            `{"value": "${
                stageData.startby_criteria_value?.replaceAll(
                    String.fromCharCode(92),
                    String.fromCharCode(92, 92)
                ) ?? ""
            }"}`
        );
        formData.append(
            "include_criteria_value",
            stageData.include_criteria_value.toString()
        );
        formData.append(
            "return_n_matches",
            stageData.return_n_matches?.toString() ?? "0"
        );
        formData.append(
            "matching_threshold",
            ((stageData.stage_matching_threshold ?? 0.9) / 100).toString()
        );

        formData.append("match_upto_criteria", stageData.match_upto_criteria ?? "");
        //for escaping backslashes: add another backslash for each one
        formData.append(
            "match_upto_value",
            `{"value": "${
                stageData.match_upto_value?.replaceAll(
                    String.fromCharCode(92),
                    String.fromCharCode(92, 92)
                ) ?? ""
            }"}`
        );
        formData.append(
            "include_match_upto_value",
            stageData.include_match_upto_value.toString()
        );
        formData.append(
            "match_upto_threshold",
            ((stageData.stage_match_upto_threshold ?? 0.9) / 100).toString()
        );

        //on Submitting the form we check wether the stage functionality has a list of functions, and replace the apply_funciton & KW args
        //by function list values to match the backend standard as it considers the function list as one of the funcitons
        //This process could only be done at the submitting time as that is the only time we make sure that the user won't add another function to the list
        if (stageData.is_func_list) {
            formData.append("apply_function", "function_list");
            formData.append("func_kwargs", stageData.func_list);
        } else {
            formData.append("apply_function", stageData.apply_function);
            formData.append(
                "func_kwargs",
                stageData.apply_function?.length > 0
                    ? stageData.func_kwargs ?? "{}"
                    : "{}"
            );
        }
        formData.append("validation_rule", stageData.validation_rule ?? "");
        formData.append(
            "validation_value",
            `{"value": "${stageData.validation_value ?? ""}"}`
        );
        formData.append("validation_action", stageData.validation_action ?? "");
        formData.append("successor_link", stageData.successor_link ?? "and");

        return formData;
    };

    return [convertStageFormToFormData];
};

export default useStageFormToFormData;
