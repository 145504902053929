import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useOneProjectQuery = (isLoggedIn: boolean, projectId: number) => {
    const [oneProjectRefetchInterval, setOneProjectRefetchInterval] =
        useState(1000);
    return useQuery(
        ["oneProject", projectId],
        () => API.fetchOneProject(projectId),
        {
            refetchInterval: oneProjectRefetchInterval,
            enabled: isLoggedIn && projectId !== -1,
            onSuccess: (data) => {
                if (data) {
                    setOneProjectRefetchInterval(0);
                }
            },
        }
    );
};

export default useOneProjectQuery;
