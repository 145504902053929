import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useFaceQuery = (isLoggedIn: boolean, recordUID: string, itemRefetchInterval: number) => {
    console.log(`itemRefetchInterval: ${itemRefetchInterval}`)
    console.log(`isEnabled: ${isLoggedIn && !!recordUID}`)
    return useQuery(["Face", recordUID], () => API.fetchFaceResult(recordUID), {
        refetchInterval: itemRefetchInterval,
        retry: 1,
        enabled: isLoggedIn && !!recordUID,

        onSuccess: (data) => {

        },
        onError: (error: Error) => {

        },
    });
};

export default useFaceQuery;
