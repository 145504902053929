import {Box, IconButton, Modal, Paper} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import * as React from "react";
import {useTheme} from '@mui/material/styles';


type Props = {
    children: React.ReactNode;
    open: boolean;
    handleClose: () => void;
    disableClose?: boolean;
    isFullWidth?: boolean;
    isOverFlow?: boolean;
};
const ModalWrapper = ({
                          children,
                          open,
                          handleClose,
                          disableClose = false,
                          isFullWidth = false,
                          isOverFlow = false,
                      }: Props) => {

    const theme = useTheme()
    const classes = {
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "auto",
            minWidth: "0px"
        },
        modalPaper: {
            outline: "none",

        },
        modalPaperWithFullWidth: {
            outline: "none",
            width: "90%",

        },
        pageContent: {
            padding: theme.spacing(3),
            width: "100%",
            borderRadius: "15px",
            backgroundColor: "#f1f4f9",
            mt: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            alignContent: "center",
            flexDirection: "column",
        },
        pageContentWithOverFlow: {
            padding: theme.spacing(3),
            width: {xs:"100%", md:"90%"},
            minWidth: "80vw",
            height: "90vh",
            overflow: "auto",
            borderRadius: "15px",
            backgroundColor: "#f1f4f9",
            mt: "25px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
        },
        boxWrapper: {
            mt: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            alignContent: "center",

            width: "100%",
        },
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            sx={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <div
                style={
                    isFullWidth ? classes.modalPaperWithFullWidth : classes.modalPaper
                }
            >
                <Box sx={classes.boxWrapper}>
                    <Paper
                        sx={
                            isOverFlow
                                ? classes.pageContentWithOverFlow
                                : classes.pageContent
                        }
                    >
                        {!disableClose && (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton onClick={() => handleClose()}>
                                    <CancelIcon fontSize="large"/>
                                </IconButton>
                            </Box>
                        )}

                        {children}
                    </Paper>
                </Box>
            </div>
        </Modal>
    );
};

export default ModalWrapper;
