import {combineReducers, configureStore} from "@reduxjs/toolkit";

import userCredentials from "./reducers/userCredentials";
import projectsState from "./reducers/projectsState";
import filtersState from "./reducers/filtersState";
import stagesState from "./reducers/stagesState";
import docsState from "./reducers/docsState";
import uploadingImages from "./reducers/uploadingImages";
import isMatchingBreakingPoint from "./reducers/isMatchingBreakingPoint";
import navBarState from "./reducers/navBarState";
import infoBarState from "./reducers/infoBarState";
import appBarState from "@/redux/reducers/appBarState";

const rootReducer = combineReducers({
    userCredentials: userCredentials.reducer,
    projectsState: projectsState.reducer,
    filtersState: filtersState.reducer,
    stagesState: stagesState.reducer,
    docsState: docsState.reducer,
    uploadingImages: uploadingImages.reducer,
    isMatchingBreakingPoint: isMatchingBreakingPoint.reducer,
    navBarState: navBarState.reducer,
    infoBarState: infoBarState.reducer,
    appBarState: appBarState.reducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export default store;
