import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {appBarStateType} from "@/srcTypes/reduxTypes";

const appBarState = createSlice({
    name: "appBarState",
    initialState: {
        appBarHeight: 60,
    } as appBarStateType,
    reducers: {

        setInfoDrawerWidth: (state, action: PayloadAction<string>) => {
            return {...state, appBarWidth: action.payload};
        },
    },
});

export default appBarState;
