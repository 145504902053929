import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {StagesStateType} from "@/srcTypes/reduxTypes";
import {CreateStageResponse} from "@/srcTypes/apiTypes";

const initialState: StagesStateType = {
    stagesList: [],
    selectedStage: null,
    updatedStage: null,
    deletedStage: null,
    copiedStage: null,
};
const stagesState = createSlice({
    name: "stagesState",
    initialState,
    reducers: {
        setSelectedStage: (state, action: PayloadAction<CreateStageResponse>) => {
            return {...state, selectedStage: action.payload};
        },
        resetSelectedStage: (state) => {
            return {...state, selectedStage: null};
        },
        setUpdatedStage: (state, action: PayloadAction<CreateStageResponse>) => {
            return {...state, updatedStage: action.payload};
        },
        resetUpdatedStage: (state) => {
            return {...state, updatedStage: null};
        },

        setDeletedStage: (state, action: PayloadAction<CreateStageResponse>) => {
            return {...state, deletedStage: action.payload};
        },
        resetDeletedStage: (state) => {
            return {...state, deletedStage: null};
        },

        setCopiedStage: (
            state,
            action: PayloadAction<CreateStageResponse | null>
        ) => {
            return {...state, copiedStage: action.payload};
        },
        resetCopiedStage: (state) => {
            return {...state, copiedStage: null};
        },

        setStagesList: (state, action: PayloadAction<CreateStageResponse[]>) => {
            return {...state, stagesList: action.payload};
        },
        resetStagesList: (state) => {
            return {...state, stagesList: []};
        },

        addStage: (state, action: PayloadAction<CreateStageResponse>) => {
            return {
                ...state,
                stagesList: [...state.stagesList, action.payload],
            };
        },

        updateStage: (state, action: PayloadAction<CreateStageResponse>) => {
            return {
                ...state,
                stagesList: state.stagesList.map((stage) =>
                    stage.id === action.payload.id ? action.payload : stage
                ),
            };
        },
        deleteStage: (state, action: PayloadAction<CreateStageResponse>) => {
            return {
                ...state,
                stagesList: state.stagesList.filter(
                    (stage) => stage.id !== action.payload.id
                ),
            };
        },
    },
});

export default stagesState;
