import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserCredentialsType} from "@/srcTypes/reduxTypes";

const userCredentials = createSlice({
    name: "userCredentials",
    initialState: {
        isLoggedIn: !!localStorage.getItem("authToken"),
        username: localStorage.getItem("username") ?? "",
    } as UserCredentialsType,
    reducers: {
        setUserCredentials: (state, action: PayloadAction<UserCredentialsType>) => {
            const {isLoggedIn, username} = action.payload;
            return {...state, isLoggedIn, username};
        },
    },
});

export default userCredentials;
