import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useDeleteDocMutation = (projectId: number) => {
    const queryClient = useQueryClient();
    return useMutation(
        (uidList: string[]) => {
            let formData = new FormData();
            formData.append("uid_list", JSON.stringify(uidList));
            return API.deleteTask(formData, {});
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["Docs", projectId]);
            },
        }
    );
};

export default useDeleteDocMutation;
