import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {UploadingImagesType} from "@/srcTypes/reduxTypes";

const uploadingImages = createSlice({
    name: "uploadingImages",
    initialState: [] as UploadingImagesType[],
    reducers: {
        addUploaingImage: (
            state: UploadingImagesType[],
            action: PayloadAction<UploadingImagesType>
        ) => {
            return [...state, {...action.payload}];
        },
        removeUploaingImage: (
            state: UploadingImagesType[],
            action: PayloadAction<string>
        ) => {
            return state.filter(
                (uploadingImage) => uploadingImage.id !== action.payload
            );
        },
        updateUploadingImage(
            state: UploadingImagesType[],
            action: PayloadAction<any>
        ) {
            return state.map((uploadImage) =>
                uploadImage.id === action.payload.id
                    ? {...uploadImage, ...action.payload}
                    : uploadImage
            );
        },
    },
});

export default uploadingImages;
