import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProjectsStateType} from "@/srcTypes/reduxTypes";
import {CreateProjectResponse, TemplateType} from "@/srcTypes/apiTypes";

const initialState: ProjectsStateType = {
    templates: [],
    projectsList: [],
    selectedProject: null,
    updatedProject: null,
};
const projectsState = createSlice({
    name: "projectsState",
    initialState,
    reducers: {
        setSelectedProject: (
            state,
            action: PayloadAction<CreateProjectResponse>
        ) => {
            return {...state, selectedProject: action.payload};
        },
        resetSelectedProject: (state) => {
            return {...state, selectedProject: null};
        },

        setUpdatedProject: (
            state,
            action: PayloadAction<CreateProjectResponse>
        ) => {
            return {...state, updatedProject: action.payload};
        },
        resetUpdatedProject: (state) => {
            return {...state, updatedProject: null};
        },

        setTemplates: (state, action: PayloadAction<TemplateType[]>) => {
            return {...state, templates: action.payload};
        },
        setProjectsList: (
            state,
            action: PayloadAction<CreateProjectResponse[]>
        ) => {
            return {...state, projectsList: action.payload};
        },
        addProject: (state, action: PayloadAction<CreateProjectResponse>) => {
            return {
                ...state,
                projectsList: [...state.projectsList, action.payload],
            };
        },
    },
});

export default projectsState;
