import {useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import MainContext from "@/contexts/MainContext";

import {Navigate, Route, Routes} from "react-router-dom";

import {useDispatch} from "react-redux";
import {AppDispatch} from "@/redux/store";
import isMatchingBreakingPointSlice from "@/redux/reducers/isMatchingBreakingPoint";

import Projects from "@/screens/Projects/Projects";
import Merger from "@/screens/Merger/Merger";
import GenericUploader from "@/screens/GenericUploader/GenericUploader";

import Documents from "@/screens/Documents/Documents";
import Filters from "@/screens/Filters/Filters";
import Stages from "@/screens/Stages/Stages";

import withRoot from "@/layouts/theme/withRoot";
import Layout from "@/layouts/Layout";

import {UploadableFile} from "@/srcTypes/InputFormTypes";
import {RedirectModalPropsType} from "@/srcTypes/contextTypes/MainContextTypes";
import BatchAutomation from "@/screens/BatchAutomation/BatchAutomation";
import PlateRecognition from "@/screens/PlateRecognition/PlateRecognition";
import FaceMatching from "@/screens/FaceMatching/FaceMatching";
import ScreenDetector from "@/screens/ScreenDetector/ScreenDetector";
import LivenessDetection from "@/screens/LivenessDetection/LivenessDetection";
import Dashboard from "@/screens/Dashboard/Dashboard";
import DocumentClassifier from "@/screens/DocumentClassifier/DocumentClassifier";
import PdfUploader from "@/screens/PdfUploader/PdfUploader";

function Main() {
    const dispatch: AppDispatch = useDispatch();
    const {setIsMatchingBreakingPoint} = isMatchingBreakingPointSlice.actions;

    const theme = useTheme();
    const isMatchedBreakPoint = useMediaQuery(theme.breakpoints.up("md"));

    useEffect(() => {
        dispatch(setIsMatchingBreakingPoint(isMatchedBreakPoint));
    }, [isMatchedBreakPoint]);


    //defining a global state for uploadedImageFiles to use it in the floating windown inside the Layout
    //and update it when uploading Docs
    //couldn't add it to redux due to some bugs in file management.
    const [uploadedImageFiles, setUploadedImageFiles] = useState<UploadableFile[] | undefined>(undefined);
    const [uploadedImageProjectId, setUploadedImageProjectId] =
        useState<number>(-1);

    //isOpenInfoBar is a global state for rendering inforBar and handling its resizable width
    const [isInfoBarOpen, setIsInfoBarOpen] = useState(false);

    //redirectModalProps is a global state for rendering the redirecting modal in any child component
    //to redirect to any other component in the app
    const initRedirectModalProps = {
        openRedirectingModal: false,
        redirectMessage: "",
        targetName: "",
        targetPath: "",
    };
    const [redirectModalProps, setRedirectModalProps] =
        useState<RedirectModalPropsType>(initRedirectModalProps);

    //Simple bruteforce solution for accessing a url path of a children component: without
    return (
        <MainContext.Provider
            value={{
                redirectModalProps,
                setRedirectModalProps,
                isInfoBarOpen,
                setIsInfoBarOpen,
            }}
        >
            <Layout
                uploadedImageFiles={uploadedImageFiles}
                uploadedImageProjectId={uploadedImageProjectId}
            >
                <Routes>
                    <Route path="projects" element={<Projects/>}>
                        <Route
                            path=":projectId/documents"
                            element={
                                <Documents
                                    setUploadedImageFiles={setUploadedImageFiles}
                                    setUploadedImageProjectId={setUploadedImageProjectId}
                                />
                            }
                        />
                        <Route path=":projectId/filters" element={<Filters/>}>
                            <Route path=":filterId/stages" element={<Stages/>}/>
                        </Route>
                    </Route>
                    <Route path="merger" element={<Merger/>}/>
                    <Route path="automation" element={<BatchAutomation/>}/>
                    <Route path="plate_recognition" element={<PlateRecognition/>}/>
                    <Route path="face-matching" element={<FaceMatching/>}/>
                    <Route path="screen-detector" element={<ScreenDetector/>}/>
                    <Route path="document-classifier" element={<DocumentClassifier/>}/>
                    <Route path="liveness-detection" element={<LivenessDetection/>}/>
                    <Route path="pdf-uploader" element={<PdfUploader/>}/>
                    <Route path="dashboard" element={<Dashboard/>}/>
                    <Route path="*" element={<Navigate to="/projects" replace/>}/>

                    <Route path="Generic-Uploader" element={
                        <GenericUploader setUploadedImageFiles={setUploadedImageFiles}
                                         setUploadedImageProjectId={setUploadedImageProjectId}/>
                    }
                    />
                </Routes>
            </Layout>
        </MainContext.Provider>
    );
}

export default withRoot(Main);
