import React from 'react';
import MaterialReactTable from "material-react-table";
import {RowSelectionState} from "@tanstack/react-table";

function TableWrapper({
                          columns,
                          data,
                          actionMenu,
                          enableRowSelection,
                          toolbarCustomActions,
                          enableRowActions,
                          enableTopToolbar,
                          onRowSelectionChange,
                          rowSelection,

                          tableInstanceRef,


                      }:
                          {
                              columns: any, data: any, actionMenu?: any, enableRowSelection?: boolean, toolbarCustomActions?: any,
                              enableRowActions?: boolean, enableTopToolbar?: boolean,
                              onRowSelectionChange?: any, rowSelection?: RowSelectionState,
                              tableInstanceRef?: any
                          }) {

    return (

        <MaterialReactTable

            enableRowActions={enableRowActions}
            enableTopToolbar={enableTopToolbar}
            // muiTableContainerProps={{sx:{width: "100%"}}}
            displayColumnDefOptions={{
                'mrt-row-actions': {
                    muiTableHeadCellProps: {
                        align: 'center'
                    }
                }
            }}
            positionActionsColumn="last"
            renderRowActions={({row}) => actionMenu ? actionMenu(row) : ''}
            enableRowSelection={enableRowSelection}
            columns={columns}
            data={data}
            enableColumnOrdering
            enableGlobalFilter
            enableColumnFilterModes
            renderTopToolbarCustomActions={({table}) => (toolbarCustomActions ? toolbarCustomActions(table) : undefined)}
            onRowSelectionChange={onRowSelectionChange}
            state={rowSelection ? {rowSelection} : undefined}
            tableInstanceRef={tableInstanceRef}
        />


    );
}

export default TableWrapper;