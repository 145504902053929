import {useState} from "react";
import {useQuery} from "react-query";
import {API} from "@/utils/api";

const useOneFilterQuery = (isLoggedIn: boolean, filterId: number) => {
    const [oneFilterRefetchInterval, setOneFilterRefetchInterval] =
        useState(1000);
    return useQuery(["oneFilter", filterId], () => API.fetchOneFilter(filterId), {
        refetchInterval: oneFilterRefetchInterval,
        enabled: isLoggedIn && filterId !== -1,

        onSuccess: (data) => {
            if (data) {
                setOneFilterRefetchInterval(0);
            }
        },
    });
};

export default useOneFilterQuery;
