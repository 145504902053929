import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { useFormikContext } from "formik";

type Props = {
  children: string;
  status?: string;
  otherProps?: { [x: string]: any };
};
const ButtonWrapper = ({ children, status = "", ...otherProps }: Props) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      disabled={status === "loading"}
      onClick={handleSubmit}
      {...otherProps}
    >
      {status === "loading" ? <CircularProgress size={24} /> : children}
    </Button>
  );
};

export default ButtonWrapper;
