import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      app_name: "Doxter",
      logout: "Logout",
      login: "Login",
      projects: "Projects",
      documents: "Documents",
      filters: "Filters",
      stages: "Stages",
      about: "About",
      new_project: "Create New Project",
      new_filter: "Create New Filter",
    },
  },
  ar: {
    translation: {
      app_name: "دوكستر",
      logout: "تسجيل الخروج",
      login: "تسجيل الدخول",
      projects: "المشروعات",
      documents: "ملفاتي",
      filters: "الفلاتر",
      stages: "مراحل الفلتر",
      about: "حول",
      new_project: "اضف مشروع جديد",
      new_filter: "اضف فلتر جديد",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18next;
