import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import filtersStateSlice from "@/redux/reducers/filtersState";
import navBarStateSlice from "@/redux/reducers/navBarState";

import {CreateFilterResponse} from "@/srcTypes/apiTypes";
import useDeleteFilterMutation from "@/hooks/api/Filters/useDeleteFilterMutation";
import UpdateFilter from "./UpdateFilter";
import {MRT_ColumnDef, MRT_Row} from "material-react-table";
import {Box, IconButton, Typography} from "@mui/material";
import TableWrapper from "@/components/TableWrapper";
import FeedIcon from "@mui/icons-material/Feed";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterContextMenu from "@/screens/Filters/components/FilterContextMenu";


const classes = {

    docNameCell: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center"
    }
}

type Props = {
    filtersData: CreateFilterResponse[];
    setOpenInfoBar: (openInfoBar: boolean) => void;
};

type TableDataType = {
    id: number,
    name: string
}
export default function FiltersList({filtersData, setOpenInfoBar}: Props) {
    const navigate = useNavigate();
    const [openUpdateFilterModal, setOpenUpdateFilterModal] = useState(false);

    const {filtersState} = useSelector((state: AppState) => state);
    const {selectedFilter} = filtersState;

    const dispatch: AppDispatch = useDispatch();
    const {setSelectedFilter, setUpdatedFilter} = filtersStateSlice.actions;
    const {setNavFilterId} = navBarStateSlice.actions;

    const {mutateAsync: deleteFilterMutateAsync} = useDeleteFilterMutation();
    const {projectId} = useParams();



    function overlayTableRow(id: number, value: string) {
        const onClickHandler = () => {
            const filter = filtersData?.find(
                (filter) => filter.id === id
            );
            if (filter) {
                dispatch(setSelectedFilter(filter));
                //setNavProjectId: used for nested routing in react-router-dom
                dispatch(setNavFilterId(filter?.id ?? undefined));
                navigate(`${filter.id}/stages`);

                setOpenInfoBar(false);
            }
        }

        return (
            <Box sx={classes.docNameCell} onClick={onClickHandler}>
                <FilterContextMenu projectId={projectId} filterId={id}>
                    <Typography>{value}</Typography>
                </FilterContextMenu>

            </Box>
        )
    }

    const tableData = filtersData?.map((filter, filterIndex) => ({
        id: filter.id,
        name: filter.name,
    }))
    const columns: MRT_ColumnDef<TableDataType>[] = [
        {
            accessorKey: 'id', //simple recommended way to define a column
            header: 'ID',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
            Cell: ({cell, row}) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>())
        },
        {

            accessorKey: 'name', //simple recommended way to define a column
            header: 'Name',
            muiTableBodyCellProps: {align: 'left', sx: {'&': {width: '100% '}}},
            muiTableHeadCellProps: {align: 'left', sx: {'&': {width: '100% '}}},

            Cell: ({
                       cell,
                       row
                   }) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>())
        },

    ]

    function actionMenu(row: MRT_Row<TableDataType>) {
        return (
            <Box sx={{display: 'flex', justifyContent: "right"}}>
                <IconButton onClick={() => {
                    const filter = filtersData?.find(
                        (filter) => filter.id === row.original.id
                    );
                    if (filter) {
                        dispatch(setUpdatedFilter(filter));
                        setOpenUpdateFilterModal(true);
                    }
                }
                }>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    const filter = filtersData?.find(
                        (filter) => filter.id === row.original.id
                    );
                    if (filter) {
                        dispatch(setSelectedFilter(filter));
                        setOpenInfoBar(true);
                    }
                }
                }>
                    <FeedIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    let result = window.confirm(`Are you sure you want to delete "${row.original.name}" project`)
                    if (result) {
                        deleteFilterMutateAsync(row.original.id);
                    }

                }}>
                    <DeleteIcon/>
                </IconButton>
            </Box>
        )
    }


    return (
        <>
            <div style={{maxWidth: "100%"}}>
                <TableWrapper columns={columns} data={tableData} actionMenu={actionMenu} enableRowActions={true}/>
            </div>

            <UpdateFilter
                openUpdateFilterModal={openUpdateFilterModal}
                setOpenUpdateFilterModal={setOpenUpdateFilterModal}
            />
        </>
    );
}
