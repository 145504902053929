import {useEffect, useState} from "react";
import {Box, Divider, Table, TableBody, TableCell, TableContainer, TableRow,} from "@mui/material";
import {DocumentViewer} from "react-documents";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import docsStateSlice from "@/redux/reducers/docsState";

import {useParams} from "react-router-dom";
import useOneProjectQuery from "@/hooks/api/Projects/useOneProjectQuery";

import TableOutput from "@/components/TableOutput";
import ChipWrapper from "@/components/ChipWrapper";
import TabsWrapper, {TabPanel} from "@/components/TabsWrapper";

import {getDocName} from "@/utils/docHelpers";


const classes = {
    OutputSide: {
        flex: "auto",
        width: "100%",
        height: "400px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        alignContent: "center",
    },
    HorizontalModalImage: {
        width: "100%",
        height: "60%",
        maxWidth: "65vw",
        maxHeight: "70vh",
    },
    VerticalModalImage: {
        width: "100%",
        height: "60%",
        maxWidth: "65vw",
        maxHeight: "75vh",
    },
    ImgStyle: {
        width: "100%",
        height: "75%",
        maxWidth: "60vw",
        maxHeight: "70vh",
    },
}

export default function SingleDocInfoBar({
                                             setOpenInfoBar,
                                         }: {
    setOpenInfoBar: (openInfoBar: boolean) => void;
}) {


    //decoupling children components from the parent
    const {projectId} = useParams();

    const {docsState, userCredentials} = useSelector(
        (state: AppState) => state
    );
    const {selectedDoc} = docsState;
    const {isLoggedIn} = userCredentials;

    const dispatch: AppDispatch = useDispatch();
    const {resetSelectedDoc} = docsStateSlice.actions;

    const [chosedOutputType, setChosedOutputType] = useState(0);

    const [imageInfo, setImageInfo] = useState({
        height: 0,
        width: 0,
    });

    const loadImage = (imageUrl: string) => {

        if (imageUrl) {


            const img = new Image();
            // fetch(imageUrl).then(r => r.blob()).then(f => setImageInfo(data => ({
            //     ...data,
            //     size: f.size,
            // })));
            img.src = imageUrl;
            img.onload = () => {

                setImageInfo({
                    height: img.height,
                    width: img.width,
                });

            };
            img.onerror = (err) => {
                console.log(err);
                // console.error(err);
            };
        }

    };
    useEffect(() => {
        //close infoBar on no state
        if (selectedDoc === null) {
            setOpenInfoBar(false);
            dispatch(resetSelectedDoc);
        }
        loadImage(selectedDoc?.image ?? "");
    }, [selectedDoc, setOpenInfoBar, dispatch, resetSelectedDoc]);

    const {data: selectedProjectData} = useOneProjectQuery(
        isLoggedIn,
        Number(projectId) ?? -1
    );

    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                sx={{width: "100%", mb: "10px"}}
            >
                <ChipWrapper label={getDocName(selectedDoc?.image ?? "")}/>
            </Box>
            <Divider/>
            {/* //tabs Header should be generic (u should sent its name and its icon from the rendering component to the generic one) */}
            <TabsWrapper value={chosedOutputType} setValue={setChosedOutputType}>
                <Box sx={classes.OutputSide}>
                    <TabPanel value={chosedOutputType} index={0}>
                        <TableContainer>
                            <Table aria-label="Project's table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{"Width"}</TableCell>
                                        <TableCell>{`${imageInfo?.width}px`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{"Height"}</TableCell>
                                        <TableCell>{`${imageInfo?.height}px`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{"Fraud Score"}</TableCell>
                                        <TableCell>{selectedDoc?.screen_detector_score}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{"Notes"}</TableCell>
                                        <TableCell>{selectedDoc?.notes}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                    <TabPanel value={chosedOutputType} index={1}>
                        <DocumentViewer
                            queryParams="hl=Nl"
                            url={
                                selectedDoc?.word_file ??
                                "https://drive.google.com/uc?id=1gzqxHcCxMAi--CqdqnCnOMmVlKwRrXjh"
                            }
                            viewer={"office"}
                            overrideLocalhost="https://react-doc-viewer.firebaseapp.com/"
                        />
                    </TabPanel>

                    <TabPanel value={chosedOutputType} index={2}>
                        <TableOutput extractedInfo={selectedDoc?.extracted_info ?? {}}/>
                    </TabPanel>
                </Box>
            </TabsWrapper>
            <Box
                sx={
                    (selectedProjectData?.name?.indexOf("ID") ?? -1) !== -1
                        ? classes.HorizontalModalImage
                        : classes.VerticalModalImage
                }
            >
                <img
                    src={selectedDoc?.image ?? ""}
                    alt={""}
                    style={classes.ImgStyle}
                />
            </Box>
        </>
    );
}
