import {useMutation} from "react-query";
import {API} from "@/utils/api";

const useLivenessMutation = (setUploadProgress: (progress: number) => void) => {

    return useMutation((data: FormData) => {
        return API.uploadLiveness(data, {
            onUploadProgress: (ev) => {
                setUploadProgress(Math.ceil((ev.loaded / ev.total) * 100))
            },
        })
    }, {});
};

export default useLivenessMutation;