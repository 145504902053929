import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, Button, Chip, Grid, MenuItem, Paper, TextField} from "@mui/material";
import {useDropzone,} from "react-dropzone";
import useAutomationMutation from "@/hooks/api/Automation/useAutomationMutation";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import Dropper from "@/screens/BatchAutomation/components/Dropper";
import {useSelector} from "react-redux";
import {AppState} from "@/redux/store";
import useProjectsQuery from "@/hooks/api/Projects/useProjectsQuery";
import ImageListWrapper from "@/components/ImageListWrapper";
import SnackbarWrapper from "@/components/SnackBarWrapper";

const classes = {
    boxWrapper: {
        mt: '50px',
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        width: "100%",
        height: "100%",
    },
    pageContent: {
        margin: 2,
        padding: 2,
        display: "flex",
        width: "80%",
        borderRadius: "15px",
        backgroundColor: "#f1f4f9",
        overflowWrap: "break-word",
        overflow: 'auto'
    },

    uploadArea: {
        display: "flex",
        justifyContent: "right",
        width: "100%"
    }

}

export type uploadProgressType = { [key: string]: number }
export type imageListType = { 'file': File, 'thumbnail': string }[]
type chipDataType = { key: number, label: string | undefined | null, project: number | undefined | null }
type valueToLabelMappingType = {
    [key: number]: string | undefined
}
type selectedProjectType = { id: number, name: string | undefined | null }
type formDataType = {
    file: File,
    image_url: string,
    projects_map: string,
    use_document_detector: string,
    use_rotation_estimator: string
}

export default function BatchAutomation() {
    const {userCredentials} = useSelector((state: AppState) => state);
    const {isLoggedIn} = userCredentials;
    const {data: projectsData} = useProjectsQuery(isLoggedIn);


    const [uploadProgress, setUploadProgress] = useState<uploadProgressType>({});

    const [docClassifierOption, setDocClassifierOption] = useState<number>(4);
    const [selectedProject, setSelectedProject] = useState<selectedProjectType>();
    const [chipDataArray, setChipDataArray] = useState<chipDataType[]>([]);

    const {mutateAsync: automationMutateAsync} = useAutomationMutation(uploadProgress, setUploadProgress);
    const valueToLabelMapping: valueToLabelMappingType = {
        0: "Car License Back Side",
        1: "Car License Front Side",
        2: "National ID Back Side",
        3: "National ID Front Side",
        4: "General Document",
    }

    function showSnackBar(status: string, message: string) {
        setSnackBar({status, message})
        setTimeout(() => (setSnackBar({status: '', message: ''})), 3000)
    }

    const {acceptedFiles, isDragActive, getRootProps, getInputProps} = useDropzone({
        multiple: true,
        accept: ["image/*"],
        maxSize: 10 * 1024 * 1024, // 10MB

        onDropAccepted: (files: File[]) => {

        },
        onDropRejected: (fileRejections) => {
            if (fileRejections[0].file.size > 10 * 1024 * 1024) {
                showSnackBar("error", "File size must be less than or equal to 10MB")
            }


        }
    });


    const [imageList, setImageList] = useState<imageListType>([]);
    const [uploadButtonState, setUploadButtonState] = useState<boolean>(false);

    useEffect(() => {
        if (chipDataArray.length && imageList.length) {
            setUploadButtonState(false)
        } else {
            setUploadButtonState(true)
        }
    }, [chipDataArray, imageList]);

    useEffect(() => {
        if (acceptedFiles) {

            acceptedFiles.forEach((file, index) => {
                const reader = new FileReader()
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    const binaryStr = reader?.result?.toString()
                    if (binaryStr) {
                        setImageList((prevState) => {
                            return [...prevState, {'file': file, 'thumbnail': binaryStr}]
                        })
                    }

                }
                reader.readAsDataURL(file)


            })

        }


    }, [acceptedFiles]);


    const handleMapButtonClick = () => {
        if (selectedProject?.id) {
            setChipDataArray((chips) => [...chips.filter((chip) => chip.key !== docClassifierOption),
                {
                    key: docClassifierOption,
                    project: selectedProject?.id,
                    label: `${valueToLabelMapping[docClassifierOption]} → ${selectedProject?.name}`
                }])
        }


    }
    const handleDelete = (chipToDelete: chipDataType) => () => {
        setChipDataArray((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };
    const handleSelectProjectChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedProject({
            id: Number(e.target.value),
            name: projectsData?.find(o => o.id === Number(e.target.value))?.name
        })
    }
    const renderChipArray = () => {
        return chipDataArray.map((item) => {
            return <Chip

                {...item}
                style={{margin: "3px"}}
                variant="outlined"
                onDelete={handleDelete(item)}
            />

        })
    }
    const parseMappingData = () => {
        return JSON.stringify(Object.assign({}, ...chipDataArray.map((data) => {
            return {[data?.key]: data?.project}
        })))
    }


    const uploadFormData = ({file, image_url, projects_map, use_document_detector, use_rotation_estimator}:
                                formDataType) => {

        const formData = new FormData()
        formData.append("image", file, file.name)
        formData.append("image_url", image_url)
        formData.append("projects_map", projects_map)
        formData.append("use_document_detector", use_document_detector)
        formData.append("use_rotation_estimator", use_rotation_estimator)
        automationMutateAsync({fileName: file.name, automationData: formData});

    }

    const [snackBar, setSnackBar] = useState({status: '', message: ''});
    return (
        <>
            <Box sx={classes.boxWrapper}>
                <Paper sx={classes.pageContent}>
                    <Grid style={{margin: "5px"}} container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                style={{marginBottom: "15px"}}
                                select={true}
                                label="Select Document Type"
                                name="select-document-type"
                                fullWidth={true}
                                variant="outlined"
                                value={docClassifierOption ?? ''}
                                onChange={(e) => setDocClassifierOption(Number(e.target.value))}
                            >
                                {Object.keys(valueToLabelMapping).map((value: string, idx: number) => {
                                    return <MenuItem
                                        key={idx}
                                        value={Number(value)}>{valueToLabelMapping[Number(value)]}</MenuItem>
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                select={true}
                                label="Select Project"
                                name="select-project"
                                fullWidth={true}
                                variant="outlined"
                                value={selectedProject?.id || ''}
                                onChange={handleSelectProjectChange}
                            >
                                {projectsData ?
                                    projectsData?.map((project, pIdx) => {
                                        return <MenuItem
                                            key={pIdx}
                                            value={project.id}>{project.name}</MenuItem>
                                    })
                                    :
                                    <MenuItem>None</MenuItem>
                                }
                            </TextField>
                        </Grid>
                        <Grid style={{display: "flex", justifyContent: "center"}} item xs={12}>
                            <Button variant="outlined" color="primary" onClick={handleMapButtonClick}>
                                <ArrowDownwardIcon/>
                            </Button>
                        </Grid>
                        <Grid style={{margin: "5px"}} item xs={12}>
                            <Paper style={{border: "solid 2px", minHeight: "20px"}}>
                                {renderChipArray()}
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Dropper
                                getRootProps={getRootProps}
                                getInputProps={getInputProps}
                                isDragActive={isDragActive}/>
                        </Grid>
                        <Grid item xs={12}>
                            {imageList &&
                                <ImageListWrapper
                                    uploadProgress={uploadProgress}
                                    imageList={imageList}
                                    setImageList={setImageList}
                                />
                            }

                        </Grid>
                        <Grid item xs={12}>
                            <Box style={classes.uploadArea}>
                                <Button disabled={uploadButtonState} color="primary" variant="contained"
                                        onClick={() => {
                                            imageList.forEach((imageData) => {
                                                uploadFormData({
                                                    file: imageData.file,
                                                    image_url: "",
                                                    projects_map: parseMappingData(),
                                                    use_document_detector: 'true',
                                                    use_rotation_estimator: 'true'
                                                })
                                            })


                                        }}>Upload</Button>
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <SnackbarWrapper status={snackBar.status} message={snackBar.message}/>
                        </Grid>
                    </Grid>

                </Paper>

            </Box>


        </>
    );
};

