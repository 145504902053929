import React from "react";
import {Box, Slider, Typography} from "@mui/material";
import {useField, useFormikContext} from "formik";

const classes = {
    root: {
        width: 300,
    },
}

type ConfigFormControl = {
    error: boolean;
};

type Props = {
    name: string;
    label: string;
    min: number;
    max: number;
    defaultValue: number;
    step: number;

    handleChange?: (newValue: number | number[]) => void | undefined;
};
const SliderWrapper = ({
                           name,
                           label,
                           min,
                           max,
                           defaultValue,
                           step,
                           handleChange = undefined,
                           ...otherProps
                       }: Props) => {


    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(name);

    const formikOnChangeCallback = (event: any, newValue: number | number[]) => {
        setFieldValue(name, newValue);
    };

    const configSlider = {
        ...field,
        onChange: (event: any, newValue: number | number[]) => {
            formikOnChangeCallback(event, newValue);
            if (handleChange !== undefined) handleChange(newValue);
        },
    };

    const configFormControl: ConfigFormControl = {error: false};
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
    }
    return (
        <Box sx={classes.root}>
            <Typography id="discrete-slider-small-steps" gutterBottom>
                {label}
            </Typography>
            <Slider
                defaultValue={defaultValue}
                step={step}
                min={min}
                max={max}
                valueLabelDisplay="auto"
                marks
                {...configSlider}
            />
        </Box>
    );
};

export default SliderWrapper;
