import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {CircularProgress, LinearProgress} from "@mui/material";

import useImageResultsQuery from "@/hooks/api/Documents/useImageResultsQuery";

const classes = {
    linearLoader: {
        height: "10px",
        width: "70px",
    },
}


type Props = {
    resultId: string;
    uploadingImageId: string;
    projectId: number;
};
const ConvertingStage = ({
                             resultId = "NON",
                             uploadingImageId = "",
                             projectId,
                         }: Props) => {


    //decoupling children components from the parent

    const {data: imageResultsData} = useImageResultsQuery(
        uploadingImageId,
        resultId,
        Number(projectId) ?? -1
    );
    return (
        <>
            {/* //checking if data hasn't been converted yet using the raw_json data item */}
            {imageResultsData?.completed ? (
                <CheckCircleIcon fontSize="medium"/>
            ) : resultId === "NON" || resultId === undefined ? (
                <CircularProgress size={25}/>
            ) : (
                <LinearProgress
                    variant="determinate"
                    value={
                        imageResultsData?.completed ? 100 :
                        imageResultsData?.text_detector_duration !== -1 && imageResultsData?.ocr_duration !== -1
                            ? 20
                            : imageResultsData?.ocr_duration !== -1
                                ? 100
                                : 70
                    }
                    sx={classes.linearLoader}
                />
            )}
        </>
    );
};

export default ConvertingStage;
