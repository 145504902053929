import React from "react";
import {Box, Paper} from "@mui/material";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";

import useCreateProjectMutation from "@/hooks/api/Projects/useCreateProjectMutation";
import useUpdateProjectMutation from "@/hooks/api/Projects/useUpdateProjectMutation";

import SnackBarWrapper from "@/components/SnackBarWrapper";
import ModalWrapper from "@/components/ModalWrapper";
import ChipWrapper from "@/components/ChipWrapper";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import projectsStateSlice from "@/redux/reducers/projectsState";

import {CreateProjectRequest} from "@/srcTypes/apiTypes";
import TemplatesCardList from "./components/TemplatesCardList";
import CreateProjectForm from "./components/CreateProjectForm";
import {useTheme} from "@mui/material/styles";

const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string(),
    use_document_detector: Yup.boolean(),
    language: Yup.string().required("Required"),
});

const createFormData = ({
                            name,
                            description,
                            template,
                            use_document_detector,
                            use_table_detector,
                            use_rotation_estimator,
                            use_screen_detector,
                            use_document_classifier,
                            language,
                            document_layout,
                        }: CreateProjectRequest) => {
    let formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("use_document_detector", use_document_detector.toString());
    formData.append("use_table_detector", use_table_detector.toString());
    formData.append("language", language);
    formData.append("document_layout", document_layout);
    formData.append("template", template.toString());
    formData.append("auto_update_template", "true");
    formData.append("use_rotation_estimator", use_rotation_estimator.toString());
    formData.append("use_screen_detector", use_screen_detector.toString());
    formData.append("use_document_classifier", use_document_classifier.toString());
    return formData;
};


type Props = {
    openCreateProjectModal: boolean;
    setOpenCreateProjectModal: (openCreateProjectModal: boolean) => void;
    isLoggedIn: boolean;
};

export default function CreateProjectModal({
                                               openCreateProjectModal,
                                               setOpenCreateProjectModal,
                                               isLoggedIn,
                                           }: Props) {
    const theme = useTheme()
    const classes = {
        boxWrapper: {
            width: "75vw",
            height: "70vh",
            overflowY: "auto",
        },

        pageContainer: {
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },

        formContent: {
            padding: theme.spacing(2),
            width: "90%",
            borderRadius: "15px",
            backgroundColor: "white",
            mt: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            alignContent: "center",
            flexDirection: "column",

            margin: theme.spacing(2, 0),

            [theme.breakpoints.down("sm")]: {
                width: "90%",
            },
        },
    }

    const navigate = useNavigate();

    const {projectsState} = useSelector((state: AppState) => state);
    const {updatedProject} = projectsState;

    const dispatch: AppDispatch = useDispatch();
    const {resetUpdatedProject} = projectsStateSlice.actions;

    const [selectedTemplateId, setSelectedTemplateId] = React.useState(-1);

    //check if there is an updatedProject to set a default template:
    React.useEffect(() => {
        setSelectedTemplateId(updatedProject?.template ?? -1);
    }, [updatedProject]);

    const handleCloseCreateProjectModal = () => {
        setOpenCreateProjectModal(false);
        //reset updatedProject on finishing the process
        dispatch(resetUpdatedProject());
    };

    const {
        status: createProjectMutationStatus,
        mutateAsync: createProjectMutateAsync,
    } = useCreateProjectMutation();

    const {
        status: updateProjectMutationStatus,
        mutateAsync: updateProjectMutateAsync,
    } = useUpdateProjectMutation(updatedProject?.id ?? 0);

    const handleSubmit = async (formData: FormData) => {
        //check whether the form is for creating or updating a new project
        try {
            if (updatedProject === null) {
                const res = await createProjectMutateAsync(formData);
                navigate(`${res.id}/documents`);
            } else await updateProjectMutateAsync(formData);
            handleCloseCreateProjectModal();
        } catch (error) {
            console.log(error, "Error!");
        }
    };

    const INITIAL_FORM_STATE = updatedProject
        ? {
            ...updatedProject,
        }
        : {
            name: "",
            description: "",
            template: 0,
            use_document_detector: true,
            use_table_detector: false,
            use_rotation_estimator: false,
            use_screen_detector: false,
            use_document_classifier: false,
            language: "mix",
            document_layout: "RTL",
        };

    return (
        <>
            <ModalWrapper
                open={openCreateProjectModal}
                handleClose={handleCloseCreateProjectModal}
                disableClose={false}
            >
                <Box sx={classes.boxWrapper}>
                    <Box sx={classes.pageContainer}>
                        <ChipWrapper label="Step 1: Choose Template"/>
                        <TemplatesCardList
                            isLoggedIn={isLoggedIn}
                            setSelectedTemplateId={setSelectedTemplateId}
                            selectedTemplateId={selectedTemplateId}
                        />

                        <ChipWrapper label="Step 2: Project Info"/>
                        <Paper sx={classes.formContent}>
                            <Formik
                                initialValues={{
                                    ...INITIAL_FORM_STATE,
                                }}
                                validationSchema={FORM_VALIDATION}
                                onSubmit={(values) => {

                                    handleSubmit(
                                        createFormData({
                                            ...values,
                                            template: selectedTemplateId,
                                        })
                                    );
                                }}
                            >
                                <Form>
                                    <CreateProjectForm
                                        createProjectMutationStatus={
                                            updatedProject === null
                                                ? createProjectMutationStatus
                                                : updateProjectMutationStatus
                                        }
                                    />
                                </Form>
                            </Formik>
                        </Paper>
                    </Box>
                </Box>

                <SnackBarWrapper
                    status={
                        updatedProject === null
                            ? createProjectMutationStatus
                            : updateProjectMutationStatus
                    }
                    message={
                        updatedProject === null
                            ? createProjectMutationStatus === "success"
                                ? "Project created successfully"
                                : "Failed to create project"
                            : updateProjectMutationStatus === "success"
                                ? "Project created successfully"
                                : "Failed to create project"
                    }
                />
            </ModalWrapper>
        </>
    );
}
