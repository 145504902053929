import React, { useEffect } from "react";

import {
  Box,
  Divider,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { AppState, AppDispatch } from "@/redux/store";
import projectsStateSlice from "@/redux/reducers/projectsState";

import InfoBar from "@/components/InfoBar";
import ChipWrapper from "@/components/ChipWrapper";
import useTemplatesQuery from "@/hooks/api/Projects/useTemplatesQuery";

type props = {
  openInfoBar: boolean;
  setOpenInfoBar: (openInfoBar: boolean) => void;
  selectedProject: {
    [x: string]: string | any;
  };
};
export default function ProjectsInfoBar({
  openInfoBar,
  setOpenInfoBar,
  selectedProject,
}: props) {
  const getTemplateName = (templateId: number) =>
    templates?.find((item) => item.id === templateId)?.name ?? "";

  const { userCredentials } = useSelector((state: AppState) => state);
  const { isLoggedIn } = userCredentials;

  const dispatch: AppDispatch = useDispatch();
  const { resetSelectedProject } = projectsStateSlice.actions;

  const { data: templates } = useTemplatesQuery(isLoggedIn);

  useEffect(() => {
    //close infoBar on no state
    if (selectedProject === null) setOpenInfoBar(false);
  }, [selectedProject, setOpenInfoBar]);


  return (
    <InfoBar
      openInfoBar={openInfoBar}
      handleInfoBarClose={() => {
        setOpenInfoBar(false);
        dispatch(resetSelectedProject());
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        sx={{ width: "100%", mb: "10px" }}
      >
        <ChipWrapper label={selectedProject?.name ?? ""} />
      </Box>

      <Divider />
      <TableContainer>
        <Table aria-label="Project's table">
          <TableBody>

            {selectedProject !== null &&
            (Object.keys(selectedProject).length ?? 0) > 0 ? (

              Object.keys(selectedProject).map((key: string) =>
              <TableRow>
                <TableCell>{key.replaceAll("_", " ")}</TableCell>
                <TableCell>
                  {key === "template"
                    ? getTemplateName(selectedProject[key])
                    : selectedProject[key].toString()}
                </TableCell>
              </TableRow>

              )
            ) : (
              <TableRow>
                <TableCell>No Details available</TableCell>
                <TableCell>No Details available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </InfoBar>
  );
}
