import React, {useEffect, useState} from "react";
import {Box, Button, Grid, IconButton,} from "@mui/material";
import CheckboxWrapper from "@/components/formComponents/CheckboxWrapper";


import AccordionWrapper from "../AccordionWrapper";
import StageFunctionality from "./StageFunctionality";

import {useField, useFormikContext} from "formik";

import {FunctionMetaDataType} from "@/srcTypes/hooksTypes";

import useStageFunctionList from "@/hooks/Stages/useStageFunctionList";
import {MRT_ColumnDef, MRT_Row} from "material-react-table";
import TableWrapper from "@/components/TableWrapper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const classes = {
    FunctionListMetaDataTableContainer: {
        width: "100%",
    },
}
type TableDataType = { func_order: number, func_name: string, params: string }
type Props = { expanded: false | string; setExpanded: (expanded: false | string) => void; };

type MetaDataProps = {

    funcListValue: FunctionMetaDataType[];
    setFuncListValue: (funcListValue: FunctionMetaDataType[]) => void;
    deleteFuncFromFuncList: (
        funcListValue: FunctionMetaDataType[],
        oldFuncOrder: number
    ) => FunctionMetaDataType[];
    setFnArgsValues: (applyFunctionValue: any) => void;
}

function RenderFunctionListMetaDataTable({funcListValue, setFuncListValue,deleteFuncFromFuncList,setFnArgsValues,}: MetaDataProps) {

    const [applyFunction] = useField("apply_function");
    const [applyFunctionOrder] = useField("apply_function_order");

    const {setFieldValue} = useFormikContext();
    const columns: MRT_ColumnDef<TableDataType>[] = [
        {
            accessorKey: 'func_order', //simple recommended way to define a column
            header: 'Function Order',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
        },
        {

            accessorKey: 'func_name', //simple recommended way to define a column
            header: 'Function Name',
            muiTableBodyCellProps: {align: 'left', sx: {'&': {width: '100% '}}},
            muiTableHeadCellProps: {align: 'left', sx: {'&': {width: '100% '}}},

        },
        {
            accessorKey: 'params', //simple recommended way to define a column
            header: 'Parameters',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
        },

    ]
    const tableData = funcListValue?.map((func, funcIndex) => ({
        func_order: funcIndex,
        func_name: func.func_name,
        params: JSON.stringify(func.params),
    }))

    function actionMenu(rowData: MRT_Row<TableDataType>) {
        return (
            <Box sx={{display: 'flex', justifyContent: "right"}}>

                <IconButton onClick={() => {
                    setFieldValue(applyFunction.name, rowData.original.func_name);
                    setFieldValue(applyFunctionOrder.name, rowData.original.func_order);

                    //Updating func kwargs fields' values:
                    const paramsEntries = Object.entries(JSON.parse(rowData.original.params));
                    paramsEntries.forEach(([key, value]) => {
                        if (key !== "matching_threshold") setFieldValue(key, value);
                        else setFieldValue(key, `${Number(value) * 100}`);
                    });
                    //updating kwargsValues field to use it to preserve the state of changing kwargs values
                    setFnArgsValues(JSON.parse(rowData.original.params));
                }}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    // let result = window.confirm(`Are you sure you want to delete "${rowData.original.name}" project`)
                    new Promise<void>((resolve, reject) => {
                        setFuncListValue(
                            deleteFuncFromFuncList(funcListValue, rowData.original.func_order)
                        );
                        resolve();
                    })
                }}>
                    <DeleteIcon/>
                </IconButton>
            </Box>
        )
    }

    return (
        <Box sx={classes.FunctionListMetaDataTableContainer}>
            <TableWrapper columns={columns}
                          data={tableData}
                          actionMenu={actionMenu}
                          enableRowActions={true}

            />

        </Box>
    );
};

export default function FunctionList({expanded, setExpanded}: Props) {
    const [fnArgs] = useField("func_kwargs");
    const [applyFunction] = useField("apply_function");
    const [applyFunctionOrder] = useField("apply_function_order");

    const [funcList] = useField("func_list");
    const [isFuncList] = useField("is_func_list");

    const [funcListValue, setFuncListValue] = useState<FunctionMetaDataType[]>(
        funcList.value !== "{}" ? JSON.parse(funcList.value)["func_list"] : []
    );
    const [isFuncListValue, setIsFuncListValue] = useState(isFuncList.value);
    const {setFieldValue} = useFormikContext();

    //Initial value of the fuArgs would be either an empty object (on Creating a new Stage)
    //Or the kwargs that's passed to formik from the updatedStage state (on Updating a stage)
    //this fnArgs will got updated when invoking : updateFnArgs callback when using the form
    const [fnArgsValues, setFnArgsValues] = useState(JSON.parse(fnArgs.value === "" ? "{}" : fnArgs.value));
    const [applyFunctionValue, setApplyFunctionValue] = useState(applyFunction.value);

    useEffect(() => {
        setApplyFunctionValue(applyFunction.value);
    }, [applyFunction]);

    const clearStageFunctionFields = () => {
        //clear Function Name
        setFieldValue(applyFunction.name, "");
        setApplyFunctionValue("");
        //clear Function KW args
        setFieldValue(fnArgs.name, "{}");
        setFnArgsValues({});

        if (applyFunctionOrder.value !== -1)
            setFieldValue(applyFunctionOrder.name, -1);
    };

    const {
        addFuncToFuncList,
        updateFuncInFuncList,
        deleteFuncFromFuncList,
        resetFuncList,
        setIsFuncList,
    } = useStageFunctionList();


    return (
        <AccordionWrapper
            title={"Stage Functionality"}
            expanded={expanded}
            setExpanded={setExpanded}
        >
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <CheckboxWrapper
                        name="is_func_list"
                        legend=""
                        label="Apply multiple functions"
                        handleChange={(event) => {
                            setIsFuncListValue(
                                setIsFuncList(event.target.value !== "true")
                            );
                            //Clear function list and the name of the function on isFuncListValue is false:
                            if (event.target.value === "true") {
                                setFieldValue(applyFunction.name, "");
                                setFuncListValue(resetFuncList(funcListValue));
                            }
                        }}
                    />
                </Grid>
                {isFuncListValue && funcListValue.length > 0 ? (
                    <Grid item xs={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setFuncListValue(resetFuncList(funcListValue));
                            }}
                        >
                            Clear All functions
                        </Button>
                    </Grid>
                ) : (
                    ""
                )}

                {funcListValue.length > 0 ? (
                    <Grid item xs={12}>
                        <RenderFunctionListMetaDataTable
                            funcListValue={funcListValue}
                            setFuncListValue={setFuncListValue}
                            deleteFuncFromFuncList={deleteFuncFromFuncList}
                            setFnArgsValues={setFnArgsValues}
                        />
                    </Grid>
                ) : (
                    ""
                )}

                <StageFunctionality
                    setApplyFunctionValue={setApplyFunctionValue}
                    fnArgsValues={fnArgsValues}
                    setFnArgsValues={setFnArgsValues}
                />
                {isFuncListValue ? (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                //Push the function into the funcListValue
                                //Check whether the user wants to add or edit a function in the function list
                                if (applyFunctionOrder.value === -1)
                                    setFuncListValue(
                                        addFuncToFuncList(funcListValue, {
                                            func_name: applyFunctionValue,
                                            params: fnArgsValues,
                                        })
                                    );
                                else
                                    setFuncListValue(
                                        updateFuncInFuncList(
                                            funcListValue,
                                            {
                                                func_name: applyFunctionValue,
                                                params: fnArgsValues,
                                            },
                                            applyFunctionOrder.value
                                        )
                                    );

                                //Clear the state
                                clearStageFunctionFields();
                            }}
                        >
                            {/* Check if the user wants to edit a certain function from the function list */}
                            {applyFunctionOrder.value === -1
                                ? "Add current function"
                                : "Edit Function"}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                //Clear the state
                                clearStageFunctionFields();
                            }}
                        >
                            {applyFunctionOrder.value === -1
                                ? "Clear current function"
                                : "Exit edit mode"}
                        </Button>
                    </Grid>
                ) : (
                    ""
                )}
            </Grid>
        </AccordionWrapper>
    );
}
