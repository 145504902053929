import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";

const useDeleteProjectMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((projectId: number) => API.deleteProject(projectId, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("Projects");
        },
    });
};

export default useDeleteProjectMutation;
