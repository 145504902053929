import DateAndTimePickerWrapper from "@/components/formComponents/DateAndTimePickerWrapper";
import {Grid} from "@mui/material";

const FilterByDate = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <DateAndTimePickerWrapper name="date_from" label="Start Date"/>
                </Grid>
                <Grid item xs={6}>
                    <DateAndTimePickerWrapper name="date_to" label="End Date"/>
                </Grid>
            </Grid>
        </>
    );
};

export default FilterByDate;
