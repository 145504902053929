import ModalWrapper from "@/components/ModalWrapper";
import Outputs from "@/screens/Outputs/Outputs";

import { useDispatch } from "react-redux";
import { AppDispatch } from "@/redux/store";
import docsStateSlice from "@/redux/reducers/docsState";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function DocsOutputs({ open, setOpen }: Props) {
  const dispatch: AppDispatch = useDispatch();
  const { resetSelectedDoc } = docsStateSlice.actions;

  const handleCloseDocsOutputModal = () => {
    dispatch(resetSelectedDoc());
    setOpen(false);
  };

  return (
    <>
      <ModalWrapper
        open={open}
        handleClose={handleCloseDocsOutputModal}
        disableClose={false}
      >
        <Outputs />
      </ModalWrapper>
    </>
  );
}
