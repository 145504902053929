import useDeleteStagesMutation from "@/hooks/api/Stages/useDeleteStageMutation";

import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, AppState} from "@/redux/store";
import stagesStateSlice from "@/redux/reducers/stagesState";

import {CreateStageResponse} from "@/srcTypes/apiTypes";

import StageContextMenu from "./StageContextMenu";

import {useParams} from "react-router-dom";
import {Box, IconButton, Typography} from "@mui/material";
import React from "react";
import {MRT_ColumnDef, MRT_Row} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import FeedIcon from "@mui/icons-material/Feed";
import DeleteIcon from "@mui/icons-material/Delete";
import DuplicateIcon from "@mui/icons-material/ContentCopy";
import TableWrapper from "@/components/TableWrapper";

const classes = {

    cellWidth: {
        width: '100% !important'
    },
    docNameCell: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center"
    }
}

type TableDataType = {
    id: number,
    seqNo: number,
    name: string
}

type Props = {
    stagesData: CreateStageResponse[];
    setOpenInfoBar: (openInfoBar: boolean) => void;
    setOpenPreviewStageModal: (openPreviewStageModal: boolean) => void;
    setIsDuplicate: (isDuplicate: boolean) => void;
    newSeqNo: number;
};
export default function StagesList({
                                       stagesData,
                                       setOpenInfoBar,
                                       setOpenPreviewStageModal,
                                       setIsDuplicate,
                                       newSeqNo,
                                   }: Props) {
    const {filterId} = useParams();
    const {stagesState} = useSelector((state: AppState) => state);
    const {selectedStage} = stagesState;

    const dispatch: AppDispatch = useDispatch();
    const {setSelectedStage, setUpdatedStage} = stagesStateSlice.actions;

    const {mutateAsync: deleteStageMutateAsync} = useDeleteStagesMutation();

    function overlayTableRow(id: number, value: string) {
        const onClickHandler = () => {
            const stage = stagesData?.find(
                (stage) => stage.id === id
            );
            if (stage) {
                dispatch(setUpdatedStage(stage));
                setOpenPreviewStageModal(true);
            }
        }
        const stage = stagesData?.find((stage) => stage.id === id);
        return (
            <Box sx={classes.docNameCell} onClick={onClickHandler}>
                <StageContextMenu copiedStage={stage} newSeqNo={newSeqNo} filterId={Number(filterId ?? -1)}>
                    <Typography>{value}</Typography>
                </StageContextMenu>
            </Box>
        )
    }

    const tableData = stagesData?.map((stage, stageIndex) => ({
        id: stage.id,
        seqNo: stage.sequence_number,
        name: stage.description,
    }))
    const columns: MRT_ColumnDef<TableDataType>[] = [
        {
            accessorKey: 'id', //simple recommended way to define a column
            header: 'ID',
            muiTableBodyCellProps: {align: 'left'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
            Cell: ({cell, row}) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>())
        },
        {
            accessorKey: 'seqNo', //simple recommended way to define a column
            header: 'Sequence Number',
            muiTableBodyCellProps: {align: 'center'},
            muiTableHeadCellProps: {align: 'left'},
            size: 25,
            Cell: ({cell, row}) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>())
        },
        {

            accessorKey: 'name', //simple recommended way to define a column
            header: 'Name',
            muiTableBodyCellProps: {align: 'left', sx: {'&': {width: '100% '}}},
            muiTableHeadCellProps: {align: 'left', sx: {'&': {width: '100% '}}},

            Cell: ({
                       cell,
                       row
                   }) => overlayTableRow(row.original.id ?? -1, cell.getValue<string>())
        },

    ]

    function actionMenu(row: MRT_Row<TableDataType>) {
        return (
            <Box sx={{display: 'flex', justifyContent: "right"}}>
                <IconButton onClick={() => {
                    const stage = stagesData?.find(
                        (stage) => stage.id === row.original.id
                    );
                    if (stage) {
                        dispatch(setUpdatedStage(stage));
                        setOpenPreviewStageModal(true);
                    }
                }
                }>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    const stage = stagesData?.find(
                        (stage) => stage.id === row.original.id
                    );
                    if (stage) {
                        dispatch(setSelectedStage(stage));
                        setOpenInfoBar(true);
                    }
                }
                }>
                    <FeedIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    const stage = stagesData?.find(
                        (stage) => stage.id === row.original.id
                    );
                    if (stage) {
                        setIsDuplicate(true);
                        dispatch(setUpdatedStage(stage));
                        setOpenPreviewStageModal(true);
                    }
                }
                }>
                    <DuplicateIcon/>
                </IconButton>
                <IconButton onClick={() => {
                    let result = window.confirm(`Are you sure you want to delete "${row.original.name}" project`)
                    if (result) {
                        deleteStageMutateAsync(row.original.id);
                    }

                }}>
                    <DeleteIcon/>
                </IconButton>
            </Box>
        )
    }

    return (
        <>
            <div style={{maxWidth: "100%"}}>
                <TableWrapper columns={columns} data={tableData} actionMenu={actionMenu} enableRowActions={true}/>
            </div>
        </>
    );
}
