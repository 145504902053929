import React, {SyntheticEvent, useEffect} from "react";
import Snackbar, {SnackbarCloseReason} from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";


export default function SnackbarWrapper({
                                            status,
                                            message,
                                            duration = 3200,
                                        }: {
    status: string;
    message: string;
    duration?: number;
}) {
    const theme = useTheme()
    const classes = {
        root: {
            width: "100%",
            "& > * + *": {
                marginTop: theme.spacing(2),
            },
        },
        AlertStyle: {
            backgroundColor: "#fb8c00",
        },
    }
    
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        setOpen(status === "success" || status === "error" || status === "info");
    }, [status]);


    const closeSnackbar = (event: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    return (
        <Box sx={classes.root}>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal:'center'}} open={open} autoHideDuration={duration} onClose={closeSnackbar}>
                {status === "success" || status === "error" || status === "info" ? (
                    <Alert variant="filled" onClose={closeSnackbar} severity={status}>
                        {message}
                    </Alert>
                ) : undefined}
            </Snackbar>
        </Box>
    );
}
