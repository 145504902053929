import React from "react";
import Grid from "@mui/material/Grid";
import TextfieldWrapper from "@/components/formComponents/TextfieldWrapper";
import SelectWrapper from "@/components/formComponents/SelectWrapper";

import AccordionWrapper from "./AccordionWrapper";

import { useFormikContext } from "formik";

type Props = {
  expanded: false | string;
  setExpanded: (expanded: false | string) => void;
  setSubmitFlag: ({
    submitType,
    previewType,
  }: {
    submitType: string;
    previewType: string;
  }) => void;
};
export default function StageInfo({
  expanded,
  setExpanded,
  setSubmitFlag,
}: Props) {
  const { submitForm } = useFormikContext();

  return (
    <React.Fragment>
      <AccordionWrapper
        title={"Stage Info"}
        expanded={expanded}
        setExpanded={setExpanded}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextfieldWrapper
              name="sequence_number"
              label="Sequence Number"
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextfieldWrapper name="description" label="Stage Description" />
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectWrapper
              name="data_source"
              label="Data Source"
              options={{
                raw_data: "Raw Data",
                prev_input: "Previous Stage Input",
                prev_output: "Previous Stage Output",
              }}
              handleChange={(event) => {
                setSubmitFlag({
                  submitType: "preview",
                  previewType: "input",
                });
                submitForm();
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectWrapper
              name="prev_valid"
              label="Previous Validation"
              options={{
                "": "------------",
                all_true: "All Previous Stage Validations Are True",
                any_true: "Any of the Previous Stage Validations Is True",
                all_false: "All Previous Stage Validations Are False",
                any_false: "Any of the Previous Stage Validations Is False",
              }}
            />
          </Grid>
        </Grid>
      </AccordionWrapper>
    </React.Fragment>
  );
}
