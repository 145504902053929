import {useMutation, useQueryClient} from "react-query";
import {API} from "@/utils/api";
import {MergeDataType} from "@/srcTypes/apiTypes";

const useMergeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((mergeData: MergeDataType) => API.merge(mergeData, {}), {
        onSuccess: () => {
            queryClient.invalidateQueries("MergeData");
        },
    });
};

export default useMergeMutation;
